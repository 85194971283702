import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import React, { lazy, Suspense } from "react";
import {
    TouchableOpacity,

    Dimensions,
    Image,
    Platform,
    Text,
    View,
    StyleSheet,
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button } from "react-native-elements";
import {
    Menu,
    MenuOption,
    MenuOptions,
    MenuTrigger,
} from "react-native-popup-menu";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setAdminStatus } from "../actionCreator/AdminAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setSHARELINKMODALACTION } from "../actionCreator/ShareLinkModalAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import {
    setLoginStatus,
    saveUserLoginDaitails,
} from "../actionCreator/UserAction";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import ConstantFontFamily from "../constants/FontFamily";
import { Badge, Icon } from "react-native-elements";
// import { setUserApproachAction } from "../actionCreator/UserApproachAction";
import { setScreenLoadingModalAction } from "../actionCreator/ScreenLoadingModalAction";
import jwt_decode from "jwt-decode";
import { UserLoginMutation, UserFollowingMutation, ClikFollowingMutation, TopicFollowingMutation, ExternalFeedFollowingMutation } from "../graphqlSchema/UserMutation";
import AppHelper from "../constants/AppHelper";
import { getHomefeedList } from "../actionCreator/HomeFeedAction";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
import { getTrendingExternalFeeds } from "../actionCreator/TrendingExternalFeedsAction";
import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
import { getTrendingUsers } from "../actionCreator/TrendingUsersAction";
import applloClient from "../client";
import {
    GetNumUnreadNotificationsMutation,
    MarkNotificationsAsReadMutation,
} from "../graphqlSchema/Notification";
import { GetAccountNotificationsMutation } from "../graphqlSchema/Notification";
import { ClikPost } from "../reducers/action/Completed";
import {setTabView,searchOpenBarStatus} from '../reducers/AdminTrueFalseReducer';
import SearchSuggestion from "./SearchSuggestion";



// const SearchInputWeb = lazy(() =>
//     import("../components/SearchInputWeb")
// );

import SearchInputWeb from "../components/SearchInputWeb";

class HeaderRightdashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UnreadNotifications: 0,
            showSearchIcon: true,
        };
        this.nameInput = React.createRef();
    }
    gotoprofile = () => {
        this.props.userId({
            username:
                this.props.profileData &&
                this.props.profileData.user.username,
            type: "feed",
        });
        this.props.navigation.navigate("profile", {
            username:
                this.props.profileData &&
                this.props.profileData.user.username,
            type: "feed",
            postId: " ",
        });
    };

    logout = async () => {
        await localStorage.clear();
        const auth = getAuth();
        await signOut(auth);
        await this.props.changeLoginStatus(0);
        await this.props.changeAdminStatus(false);
        if (
            (await this.props.navigation.getCurrentRoute().routeName) ==
            "notification" ||
            (await this.props.navigation.getCurrentRoute().routeName) == "settings" ||
            (await this.props.navigation.getCurrentRoute().routeName) == "info" ||
            (await this.props.navigation.getCurrentRoute().routeName) == "feedId"
        ) {
            this.props.navigation.navigate("home"), window.location.reload();
        }
        await this.props.resetLoginUserDetails();
        await this.props.resetUserProfileDetails();

        if (Platform.OS == "web") {
            this.extensionLogout();
        }
        var req = indexedDB.deleteDatabase("firebaseLocalStorageDb");
    };

    extensionLogout = () => {
        try {
            window.parent.postMessage({ type: "wecklid_logout" }, "*");
        } catch (err) {
            console.log("Extension Logout Error ", err);
        }
    };

    loginHandle = () => {
        this.props.leftPanelModalFunc(false);
        this.props.setLoginButtonText("Login")
        this.props.setLoginModalStatus(true);
        this.props.ClikPost(0);
    };

    inviteSignHandle = async () => {
        this.props.ClikPost(0);
        await this.props.leftPanelModalFunc(false);
        await this.props.setInviteUserDetail({
            clikName: "",
            inviteKey: "",
            userName: "",
        });
        await this.props.setUsernameModalStatus(true);
    };

    componentDidMount = async () => {
        if (this.props.getUserApproach == "login" || AsyncStorage.getItem('userLoginId') == null) {
            await this.props.setUserApproachAction({ type: "" });
            // this.userLogin();
        }
        if (this.props.getUserApproach == "signUp") {
            this.props.setSignUpModalStatus(true);
        }
        if (this.props.loginStatus == 1) {
            this.getUnreadNotifications();
        }

        if (this.state.UnreadNotifications > 0) {
            AsyncStorage.setItem(
                "notificationMessageNumber",
                this.state.UnreadNotifications
            );
        }
        // console.log(this.props.loginStatus, 'this.props.loginStatus');
        // this.props.userId({
        //   username:
        //     this.props.profileData &&
        //     this.props.profileData.user.username,
        //   type: "feed",
        // });
    };



    onModalClose = async () => {
        await this.props.setLoginButtonText("Logged In!");
        await this.props.setGoogleLogin(true);
        setTimeout(() => {
            this.props.setLoginModalStatus(false);
            this.props.setLoginButtonText("Login");
            this.props.setGoogleLogin(false);
        }, 2000);
    };

    getUnreadNotifications = () => {
        applloClient
            .query({
                query: GetNumUnreadNotificationsMutation,
                variables: {
                    id: "Account:CurrentUser",
                    first: 100,
                },
                fetchPolicy: "no-cache",
            })
            .then(async (res) => {
                // this.setState(
                //   {
                //     UnreadNotifications: res.data.node.num_unread_notifications,
                //   },
                //   () => {
                this.props.showUnReadNotification(res.data.node.num_unread_notifications);
                // }
                // );
                let timer = setTimeout(() => {
                    if (this.props.loginStatus == 1) {
                        this.getUnreadNotifications();
                    }
                }, 60000);
            });
    };

    setMarkAsRead = () => {
        applloClient
            .query({
                query: MarkNotificationsAsReadMutation,
                variables: {
                    id: "Account:CurrentUser"
                },
                fetchPolicy: "no-cache",
            })
            .then(async (res) => {
                this.setState({
                    UnreadNotifications: 0,
                });
                this.getUnreadNotifications();
            });
    };

    userLogin = async () => {
        let __self = this;
        await this.props.setLoginButtonText("Logging in...");
        await this.props.setGoogleLogin(true);
        const auth = getAuth()
        await onAuthStateChanged(auth, async (res) => {
            if (res) {
                AsyncStorage.setItem("googleEmail", res.email);
                __self.props.setScreenLoadingModalAction(true);
                return await res
                    .getIdToken(true)
                    .then(async function (idToken) {

                        AsyncStorage.setItem('userIdTokenFirebase', idToken)
                        AsyncStorage.setItem(
                            "admin",
                            jwt_decode(idToken)?.claims?.admin ? true : false
                        );
                        await __self.props.changeAdminStatus(
                            jwt_decode(idToken)?.claims?.admin ? jwt_decode(idToken)?.claims?.admin : false
                        );

                        if (Platform.OS == "web") {
                            __self.setUserNameInExtension =
                                __self.setLoginTokenInExtension(idToken);
                        }
                        return idToken;
                    })
                    .then(async (res) => {
                        if (res) {
                            await applloClient
                                .query({
                                    query: UserLoginMutation,
                                    variables: {
                                        id: "Account:" + "CurrentUser"
                                    },
                                    fetchPolicy: "no-cache"
                                }).then(async res => {
                                    if (res) {
                                        let userfollow = await applloClient
                                            .query({
                                                query: UserFollowingMutation,
                                                variables: {
                                                    id: "User:CurrentUser",
                                                    first: 20
                                                },
                                                fetchPolicy: "no-cache"
                                            }).then(e => {
                                                return e.data.node.users_following.edges
                                            })

                                        let topicfollow = await applloClient
                                            .query({
                                                query: TopicFollowingMutation,
                                                variables: {
                                                    id: "User:CurrentUser",
                                                    first: 20
                                                },
                                                fetchPolicy: "no-cache"
                                            }).then(e => {
                                                return e.data.node.topics_following.edges
                                            })
                                        let clikfollow = await applloClient
                                            .query({
                                                query: ClikFollowingMutation,
                                                variables: {
                                                    id: "User:CurrentUser",
                                                    first: 20
                                                },
                                                fetchPolicy: "no-cache"
                                            }).then(e => {
                                                return e.data.node.cliks_following.edges
                                            })
                                        let externalfollow = await applloClient
                                            .query({
                                                query: ExternalFeedFollowingMutation,
                                                variables: {
                                                    id: "User:CurrentUser",
                                                    first: 20
                                                },
                                                fetchPolicy: "no-cache"
                                            }).then(e => {
                                                return e.data.node.external_feeds_following.edges
                                            })
                                        let userData = {
                                            data: res.data.node,
                                            externalfollow: externalfollow,
                                            clikfollow: clikfollow,
                                            topicfollow: topicfollow,
                                            userfollow: userfollow

                                        }
                                        await this.props.saveLoginUser(userData);
                                        await this.props.changeLoginStatus(1);

                                        await __self.props.getHomefeed({
                                            currentPage: AppHelper.PAGE_LIMIT
                                        });
                                        await __self.props.getTrendingUsers({
                                            currentPage: AppHelper.PAGE_LIMIT
                                        });
                                        await __self.props.getTrendingClicks({
                                            currentPage: AppHelper.PAGE_LIMIT
                                        });
                                        await __self.props.getTrendingTopics({
                                            currentPage: AppHelper.PAGE_LIMIT
                                        });
                                        await __self.props.getTrendingExternalFeeds({
                                            currentPage: AppHelper.PAGE_LIMIT
                                        });
                                        await AsyncStorage.setItem(
                                            "userLoginId",
                                            res.data.node.user.id
                                        );
                                        await AsyncStorage.setItem(
                                            "MyUserUserId",
                                            res.data.node.user.id
                                        );
                                        await AsyncStorage.setItem(
                                            "userIdTokenWeclikd",
                                            __self.state.access_key
                                        );
                                        await AsyncStorage.setItem(
                                            "UserId",
                                            res.data.node.user.id
                                        );
                                        await AsyncStorage.setItem(
                                            "UserName",
                                            res.data.node.user.username
                                        );
                                        await __self.onModalClose();
                                        if (Platform.OS == "web") {
                                            await __self.setUserNameInExtension(
                                                res.data.node.user.username
                                            );
                                        }
                                        await __self.props.setScreenLoadingModalAction(false);

                                    } else {
                                        __self.props.setScreenLoadingModalAction(false);
                                        await __self.props.setUsernameModalStatus(true);

                                        __self.props.setLoginButtonText("Login");
                                        __self.props.setGoogleLogin(true);
                                        __self.props.setLoginModalStatus(false);

                                    }
                                })
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        __self.props.setScreenLoadingModalAction(false);
                        alert("Invalid email or password");
                        this.props.setLoginButtonText("Login");
                        this.props.setGoogleLogin(false);
                        return false;
                    });
            }
        });
    };

    setLoginTokenInExtension = (idToken) => (UserName) => {
        try {
            window.parent.postMessage(
                { type: "wecklid_login", userIdTokenFirebase: idToken, UserName },
                "*"
            );
        } catch (e) {
            console.log("extension login Error ", e);
        }
    };



    getAccountNotifications = () => {
        applloClient
            .query({
                query: GetAccountNotificationsMutation,
                variables: {
                    first: 100,
                    after: null,
                },
                fetchPolicy: "no-cache",
            })
            .then(async (res) => { });
    };

    selectTab = (tabName) => {
        console.log(tabName, '111111111')
        this.props.setTabView(tabName)
    }

    selectAnalytics = (tabName) => {
        this.props.setAnalytics(tabName)
    }
    render() {

        console.log(this.props.screenName,'this.props.screenName');
        return (
            <View
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    width: '92%',
                    // justifyContent:"space-between"
                }}
            >
                {this.props.screenName == "home" &&
                    Dimensions.get("window").width <= 1200 &&  (
                        <View style={{ width: '90%' }}>

                            {this.props.getsearchBarStatus==true ?<View><SearchInputWeb
                                    navigation={this.props.navigation}
                                    refs={(ref) => {
                                        this.input = ref;
                                    }}
                                    displayType={"web"}
                                    press={(status) => {
                                        this.setState({ showSearchIcon: status });
                                    }}
                                    nameInput={this.nameInput}
                                />
                                <View style={{width:"100%",position:"absolute",top:20,zIndex:999}}>
                                <SearchSuggestion />
                                </View>
                                </View>
                                :null}


                                
                            
                        </View>
                    )}


                <View
                    style={{
                        alignItems: "center",
                        height: 40,
                        flexDirection: "row",
                    }}
                >
                    {/* {this.props.screenName == "clikScreen" || this.props.screenName == "topicScreen" || this.props.screenName == "profileScreen" || this.props.screenName == "feedScreen" ? */}
                    {this.props.getsearchBarStatus==false &&<Icon
                            name='search'
                            type="font-awesome"
                            iconStyle={{ marginRight: 10 }}
                            color="#fff"
                            onPress={() => {
                                // this.props.setSearchBarStatus(true);
                                this.props.searchOpenBarStatus(true);
                                // this.setState({ showIocnSet: !this.state.showIocnSet });
                            }}
                        /> }
                        
                        {/* // : null} */}
                    {
                    // this.props.getsearchBarStatus == false &&
                        Dimensions.get("window").width <= 1200 && (
                            this.props.screenName != "analytics" 
                            ?
                                <Menu>
                                    <MenuTrigger >
                                        <Image
                                            source={require('../assets/image/pyramid-chart.png')}
                                            style={{
                                                width: 25, height: 25, marginLeft: 10, transform: [{ rotate: "180deg" }]
                                            }} />
                                    </MenuTrigger>

                                    <MenuOptions
                                        optionsContainerStyle={{
                                            borderRadius: 5,
                                            borderWidth: 1,
                                            borderColor: "#c5c5c5",
                                            shadowColor: "transparent",
                                            // left:50
                                        }}

                                        customStyles={{
                                            optionsContainer: {
                                                position: 'absolute',
                                                marginTop: 50,

                                            },
                                            optionWrapper: { padding: 5 },
                                        }}
                                    >
                                        <MenuOption onSelect={() => this.selectTab("Trending")}>
                                            <Text style={{
                                                textAlign: "center",
                                                color: "#000",
                                                fontFamily: ConstantFontFamily.defaultFont,
                                            }}>Trending</Text>
                                        </MenuOption>
                                        <MenuOption onSelect={() => this.selectTab("New")}>
                                            <Text style={{
                                                textAlign: "center",
                                                color: "#000",
                                                fontFamily: ConstantFontFamily.defaultFont,
                                            }}>New</Text>
                                        </MenuOption>
                                        <MenuOption onSelect={() => this.selectTab("Bookmarks")}>
                                            <Text style={{
                                                textAlign: "center",
                                                color: "#000",
                                                fontFamily: ConstantFontFamily.defaultFont,
                                            }}>Bookmarks</Text>
                                        </MenuOption>
                                    </MenuOptions>
                                </Menu>
                                :
                                <Menu>
                                    <MenuTrigger >
                                        <Image
                                            source={require('../assets/image/pyramid-chart.png')}
                                            style={{
                                                width: 25, height: 25, marginLeft: 10, transform: [{ rotate: "180deg" }]
                                            }} />
                                    </MenuTrigger>

                                    <MenuOptions
                                        optionsContainerStyle={{
                                            borderRadius: 5,
                                            borderWidth: 1,
                                            borderColor: "#c5c5c5",
                                            shadowColor: "transparent",
                                            // left:50
                                        }}

                                        customStyles={{
                                            optionsContainer: {
                                                position: 'absolute',
                                                marginTop: 50,

                                            },
                                            optionWrapper: { padding: 5 },
                                        }}
                                    >
                                        <MenuOption onSelect={() => this.selectAnalytics("Summary")}>
                                            <Text style={{
                                                textAlign: "center",
                                                color: "#000",
                                                fontFamily: ConstantFontFamily.defaultFont,
                                            }}>Summary</Text>
                                        </MenuOption>
                                        <MenuOption onSelect={() => this.selectAnalytics("Graphs")}>
                                            <Text style={{
                                                textAlign: "center",
                                                color: "#000",
                                                fontFamily: ConstantFontFamily.defaultFont,
                                            }}>Graphs</Text>
                                        </MenuOption>
                                        <MenuOption onSelect={() => this.selectAnalytics("Top Comments")}>
                                            <Text style={{
                                                textAlign: "center",
                                                color: "#000",
                                                fontFamily: ConstantFontFamily.defaultFont,
                                            }}>Top Comments</Text>
                                        </MenuOption>
                                        <MenuOption onSelect={() => this.selectAnalytics("Stripe")}>
                                            <Text style={{
                                                textAlign: "center",
                                                color: "#000",
                                                fontFamily: ConstantFontFamily.defaultFont,
                                            }}>Stripe</Text>
                                        </MenuOption>
                                    </MenuOptions>
                                </Menu>
                        )}


                </View>
            </View>
        );
    }
}

const mapStateToProps = (state) => ({
    loginStatus: state.UserReducer.loginStatus,
    profileData: state.LoginUserDetailsReducer.userLoginDetails,
    getScreenLoadingStatus: state.ScreenLoadingReducer.modalStatus,
    // getUserApproach: state.UserApproachReducer.setUserApproach,
    getsearchBarStatus: state.AdminTrueFalseReducer.searchBarOpenStatus,
    // NewHomeFeed: state.HomeFeedReducer.NewHomeFeedList,
    userDetails: state.UserProfileDetailsReducer.getCurrentUserProfileDetails,
    screenName: state.screenNameReducer.screen,
});


const mapDispatchToProps = (dispatch) => ({
    userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
    searchOpenBarStatus: (payload) => dispatch(searchOpenBarStatus(payload)),

    setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
    setSignUpModalStatus: (payload) => dispatch(setSIGNUPMODALACTION(payload)),
    setUsernameModalStatus: (payload) =>
        dispatch(setUSERNAMEMODALACTION(payload)),
    setShareLinkModalStatus: (payload) =>
        dispatch(setSHARELINKMODALACTION(payload)),
    changeLoginStatus: (payload) => dispatch(setLoginStatus(payload)),
    changeAdminStatus: (payload) => dispatch(setAdminStatus(payload)),
    // setUserApproachAction: (payload) => dispatch(setUserApproachAction(payload)),
    setScreenLoadingModalAction: (payload) =>
        dispatch(setScreenLoadingModalAction(payload)),
    saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
    getHomefeed: (payload) => dispatch(getHomefeedList(payload)),
    getTrendingUsers: (payload) => dispatch(getTrendingUsers(payload)),
    getTrendingTopics: (payload) => dispatch(getTrendingTopics(payload)),
    getTrendingClicks: (payload) => dispatch(getTrendingClicks(payload)),
    getTrendingExternalFeeds: (payload) =>
        dispatch(getTrendingExternalFeeds(payload)),
    // setSearchBarStatus: (payload) =>
    //     dispatch({ type: "SEARCH_BAR_STATUS", payload }),
    resetLoginUserDetails: (payload) =>
        dispatch({ type: "LOGIN_USER_DETAILS_RESET", payload }),
    resetUserProfileDetails: (payload) =>
        dispatch({ type: "USER_PROFILE_DETAILS_RESET", payload }),


    setLoginButtonText: (payload) =>
        dispatch({ type: "SET_LOGIN_BUTTON_TEXT", payload }),
    setInviteUserDetail: (payload) =>
        dispatch({ type: "SET_INVITE_USER_DETAIL", payload }),
    setGoogleLogin: (payload) => dispatch({ type: "SET_GOOGLE_LOGIN", payload }),
    leftPanelModalFunc: (payload) =>
        dispatch({ type: "LEFT_PANEL_OPEN", payload }),
    // searchOpenBarStatus: (payload) =>
    //     dispatch({ type: "SEARCHBAR_STATUS", payload }),
    showUnReadNotification: (payload) =>
        dispatch({ type: "NOTIFICATION", payload }),
    ClikPost: (payload) => dispatch(ClikPost(payload)),
    setTabView: (payload) => dispatch(setTabView(payload) ),
    setAnalytics: (payload) => dispatch({ type: "SET_ANALYTICS_TAB_VIEW", payload }),

});


export default compose(connect(mapStateToProps, mapDispatchToProps))(
    HeaderRightdashboard
);

const styles = StyleSheet.create({
    actionButtonIcon: {
        backgroundColor: "#000",
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 45,
    },
});
