import { ElementsConsumer } from "@stripe/react-stripe-js";
// import Modal from "modal-enhanced-react-native-web";
import React from "react";
// import { graphql } from "react-apollo";
import {
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View, ScrollView,Modal
} from "react-native";
// import Overlay from "react-native-modal-overlay";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import appolloClient from "../client";
// import PaymentModal from "../components/PaymentModal";
// import CancelSubscriptionModal from "../components/CancelSubscriptionModal";
// import SubcriptionModal from "../components/SubcriptionModal";
import ConstantFontFamily from "../constants/FontFamily";
import {
  ChangeSubscriptionMutation,
  UserLoginMutation,
  UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation
} from "../graphqlSchema/UserMutation";
// import NavigationService from "../library/NavigationService";
import ButtonStyle from "../constants/ButtonStyle";
import { Button } from "react-native-elements";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePlan: 0,
      conformModal: "",
      showSubcriptionModal: false,
      loadingBasic: false,
      cancelSubcription: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    let v =
      props.profileData &&
        props.profileData.subscription == "BASIC"
        ? 0
        : 1;
    if (v != state.activePlan) {
      return {
        activePlan: v
      };
    }
    return null;
  }

  ChangeSubscription = async type => {

    if (this.props.profileData.data.subscription == "GOLD") {
      this.setState({
        cancelSubcription: true
      });
      return;
    }
    if (type == "BASIC") {
      this.setState({
        loadingBasic: true
      });
      appolloClient
        .query({
          query: ChangeSubscriptionMutation,
          variables: {
            "subscription": type,
            "payment_id": null,
            "pricing": null
          },
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          if (res) {
            await appolloClient
              .query({
                query: UserLoginMutation,
                variables: {
                  id: "Account:" + "CurrentUser"
                },
                fetchPolicy: "no-cache"
              }).then(async res => {

                let userfollow = await appolloClient
                  .query({
                    query: UserFollowingMutation,
                    variables: {
                      id: "User:CurrentUser",
                      first: 20
                    },
                    fetchPolicy: "no-cache"
                  }).then(e => {
                    return e.data.node.users_following.edges
                  })

                let topicfollow = await appolloClient
                  .query({
                    query: TopicFollowingMutation,
                    variables: {
                      id: "User:CurrentUser",
                      first: 20
                    },
                    fetchPolicy: "no-cache"
                  }).then(e => {
                    return e.data.node.topics_following.edges
                  })
                let clikfollow = await appolloClient
                  .query({
                    query: ClikFollowingMutation,
                    variables: {
                      id: "User:CurrentUser",
                      first: 20
                    },
                    fetchPolicy: "no-cache"
                  }).then(e => {
                    return e.data.node.cliks_following.edges
                  })
                let externalfollow = await appolloClient
                  .query({
                    query: ExternalFeedFollowingMutation,
                    variables: {
                      id: "User:CurrentUser",
                      first: 20
                    },
                    fetchPolicy: "no-cache"
                  }).then(e => {
                    return e.data.node.external_feeds_following.edges
                  })
                let userData = {
                  data: res.data.node,
                  externalfollow: externalfollow,
                  clikfollow: clikfollow,
                  topicfollow: topicfollow,
                  userfollow: userfollow

                }
                await this.props.saveLoginUser(userData);
              })
            // let resDataLogin = await this.props.Login();
            // await this.props.saveLoginUser(resDataLogin.data.login);
            this.setState({
              activePlan: 0,
              showSubcriptionModal: false,
              loadingBasic: false
            });
          }
        })
        .catch(e => {
          console.log(e);
          this.setState({
            loadingBasic: false
          });
        });
    } else {
      this.setState({
        activePlan: 0,
        showSubcriptionModal: true
      });
    }
  };

  onClose = () => {
    this.setState({
      showSubcriptionModal: false,
      cancelSubcription: false
    });
  };

  render() {
    const {
      showSubcriptionModal,
      activePlan,
      conformModal,
      cancelSubcription
    } = this.state;
    console.log(this.props.profileData, 'this.props.profileDatan')
    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          width: "100%",
          backgroundColor: '#fff',
          height:'95%',
          marginVertical: Dimensions.get("window").width >= 750 ? 10 : 0
        }}
      >
        {/* <View
          style={{
            flexDirection:
              Dimensions.get("window").width > 750 && Platform.OS == "web"
                ? "row"
                : "column",
            justifyContent: "space-around"
          }}
        >
          <View
            style={{
              width:
                Dimensions.get("window").width > 750 && Platform.OS == "web"
                  ? "50%"
                  : "100%",
              paddingHorizontal: 10,
              marginBottom: 10
            }}
          >
            <View
              style={[ButtonStyle.ZeroBorderStyle, {
                borderRadius: 6,               
              }]}
            >
              <View
                style={{
                  flex: 1
                }}
              >
                <View
                  style={{
                    justifyContent: "flex-start"
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      backgroundColor: "#f2f3f5",
                      color: "#000",
                      padding: 10,
                      borderTopLeftRadius: 6,
                      borderTopRightRadius: 6,
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 18,
                      fontWeight: "bold"
                    }}
                  >
                    Basic
                  </Text>
                </View>

                <View
                  style={{
                    minHeight: 200,
                    backgroundColor: "#e5e9ea",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Text
                    style={{
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                      marginVertical: 10
                    }}
                  >
                    Join Public Cliks.

                  </Text>
                  <Text
                    style={{
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                      marginVertical: 10
                    }}
                  >
                    View Only Five Discussions.

                  </Text>
                  <Text
                    style={{
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                      marginVertical: 10
                    }}
                  >
                    Follow Only Ten Feeds.

                  </Text>
                  <Text
                    style={{
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                      marginVertical: 10
                    }}
                  >
                    Search Within One Month Content.

                  </Text>
                  <Text
                    style={{
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                      marginVertical: 10
                    }}
                  >Earn Money from Likes.</Text>
                </View>

                <View
                  style={{
                    justifyContent: "flex-start"
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      backgroundColor: "#f2f3f5",
                      color: "#000",
                      padding: 10,
                      borderBottomLeftRadius: 6,
                      borderBottomRightRadius: 6,
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 18,
                      fontWeight: "bold"
                    }}
                  >
                    $0/month
                  </Text>
                </View>

                {showSubcriptionModal == true ? (
                  Platform.OS !== "web" ? (
                    <Overlay
                      animationType="zoomIn"
                      visible={showSubcriptionModal}
                      onClose={this.onClose}
                      closeOnTouchOutside
                      children={
                        <SubcriptionModal
                          type={conformModal}
                          onClose={this.onClose}
                          {...this.props}
                        />
                      }
                      childrenWrapperStyle={{
                        padding: 0,
                        margin: 0
                      }}
                    />
                  ) : (
                    <Modal
                      isVisible={showSubcriptionModal}
                      onBackdropPress={this.onClose}
                      style={{
                        marginHorizontal:
                          Dimensions.get("window").width > 750 ? "30%" : 10,
                        padding: 0
                      }}
                    >
                      <ElementsConsumer>
                        {({ stripe, elements }) => (
                          <PaymentModal
                            onClose={this.onClose}
                            {...this.props}
                            type={conformModal}
                            stripe={stripe}
                            elements={elements}
                            navigation={this.props.navigation}
                          />
                        )}
                      </ElementsConsumer>
                    </Modal>
                  )
                ) : null}

                {cancelSubcription == true && Platform.OS == "web" && (
                  <Modal
                    isVisible={cancelSubcription}
                    onBackdropPress={this.onClose}
                    style={{
                      marginHorizontal:
                        Dimensions.get("window").width > 750 ? "30%" : 10,
                      padding: 0
                    }}
                  >
                    <CancelSubscriptionModal
                      onClose={this.onClose}
                      {...this.props}
                    />
                  </Modal>
                )}
              </View>
            </View>

            <View
              style={{
                justifyContent: "center",
                marginVertical: 10
              }}
            >
              {this.state.loadingBasic == false &&
                this.props.profileData &&
                this.props.profileData.data.subscription ==
                "BASIC" ? (
                <Button
                  onPress={() => this.ChangeSubscription("BASIC")}
                  color="#fff"
                  title={
                    this.props.profileData &&
                      this.props.profileData.data.subscription == "BASIC"
                      ? "Current"
                      : "Downgrade"
                  }
                  titleStyle={ButtonStyle.titleStyle}
                  buttonStyle={[ButtonStyle.backgroundStyle, { paddingHorizontal: Dimensions.get("window").width <= 450 && 10, borderRadius: 6 }]}
                  containerStyle={[ButtonStyle.containerStyle, { width: '100%', marginLeft: 0, }]}
                  disabled={
                    this.props.profileData &&
                      this.props.profileData.data.subscription == "BASIC"
                      ? true
                      : false
                  }
                />
              ) : <Button
                onPress={() => this.ChangeSubscription("BASIC")}
                color="#d0b154"
                title={'Downgrade'}
                titleStyle={ButtonStyle.titleStyle}
                buttonStyle={[ButtonStyle.backgroundStyle, { paddingHorizontal: Dimensions.get("window").width <= 450 && 10, borderRadius: 6 }]}
                containerStyle={[ButtonStyle.containerStyle, { width: '100%', marginLeft: 0, }]}
              />}
            </View>
          </View>

          <View
            style={{
              width:
                Dimensions.get("window").width > 750 && Platform.OS == "web"
                  ? "50%"
                  : "100%",
              paddingHorizontal: 5,
              marginBottom: 10
            }}
          >
            <View
              style={[ButtonStyle.ZeroBorderStyle, {
                borderRadius: 6,
                borderWidth: 1,
                borderColor: '#d0b154'
              }]
              }
            >
              <View
                style={{
                  flex: 1
                }}
              >
                <View
                  style={{
                    justifyContent: "flex-start"
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      backgroundColor: "#dec473",
                      color: "#fff",
                      padding: 10,
                      borderTopLeftRadius: 6,
                      borderTopRightRadius: 6,
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 18,
                      fontWeight: "bold"
                    }}
                  >
                    Gold
                  </Text>
                </View>

                <View
                  style={{
                    minHeight: 200,
                    backgroundColor: "#c6a749",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Text
                    style={{
                      color: "#fff",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                      marginVertical: 10
                    }}
                  >
                    Create Private Cliks.

                  </Text>
                  <Text
                    style={{
                      color: "#fff",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                      marginVertical: 10
                    }}
                  >
                    Edit or delete posts within 5 minutes.

                  </Text>
                  <Text
                    style={{
                      color: "#fff",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                      marginVertical: 10
                    }}
                  >
                    Index and Personalize Unlimited feeds.

                  </Text>
                  <Text
                    style={{
                      color: "#fff",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                      marginVertical: 10
                    }}
                  >
                    Comments are Searchable.

                  </Text>
                  <Text style={{
                    color: "#fff",
                    fontFamily: ConstantFontFamily.defaultFont,
                    fontSize: 16,
                    fontWeight: "bold",
                    textAlign: "center",
                    marginVertical: 10
                  }}>Earn 2x From Likes.</Text>
                </View>

                <View
                  style={{
                    justifyContent: "flex-start"
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      backgroundColor: "#dec473",
                      color: "#fff",
                      padding: 10,
                      borderBottomLeftRadius: 6,
                      borderBottomRightRadius: 6,
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 18,
                      fontWeight: "bold"
                    }}
                  >
                    $5/month
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                justifyContent: "center",
                marginVertical: 10
              }}
            >
              
              {this.props.profileData.data.subscription ==
                "BASIC" ? <Button
                onPress={() => this.ChangeSubscription("GOLD")}
                color="#d0b154"
                title={this.props.profileData &&
                  this.props.profileData.data.subscription ==
                  "GOLD"
                  ? "Current"
                  : "Upgrade"
                }
                titleStyle={[ButtonStyle.titleStyle, { color: "#d0b154" }]}
                buttonStyle={[ButtonStyle.backgroundStyle, { paddingHorizontal: Dimensions.get("window").width <= 450 && 10, borderRadius: 6, borderColor: '#d0b154' }]}
                containerStyle={[ButtonStyle.containerStyle, { width: '100%', marginLeft: 0, }]}
              /> : <Button
                onPress={() => this.ChangeSubscription("GOLD")}
                color="#d0b154"
                title={this.props.profileData &&
                  this.props.profileData.data.subscription ==
                  "GOLD"
                  ? "Current"
                  : "Upgrade"
                }
                titleStyle={[ButtonStyle.titleStyle, { color: "#d0b154" }]}
                buttonStyle={[ButtonStyle.backgroundStyle, { paddingHorizontal: Dimensions.get("window").width <= 450 && 10, borderRadius: 6, borderColor: '#d0b154' }]}
                containerStyle={[ButtonStyle.containerStyle, { width: '100%', marginLeft: 0, }]}
              />}

            </View>
          </View>
        </View> */}
        <Text
          style={{
            color: "#000",
            fontFamily: ConstantFontFamily.defaultFont,
            fontSize: 16,
            fontWeight: "bold",
            textAlign: "center",
            marginVertical: 10
          }}
        >
          See{" "}
          <Text
            onPress={() => {
              this.props.navigation.navigate("faq");
            }}
            style={{ textDecorationLine: "underline", fontFamily: ConstantFontFamily.defaultFont }}
          >
            FAQ
          </Text>{" "}
          page for more info
        </Text>
      </ScrollView>
    );
  }
}

const mapStateToProps = state => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails
});

const mapDispatchToProps = dispatch => ({
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload))
});


export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Subscription
);

const styles = StyleSheet.create({
  planContentText: {
    color: "#000",
    fontFamily: ConstantFontFamily.defaultFont,
    fontSize: 16,
    fontWeight: "bold",
    marginVertical: 10,
    textAlign:
      Dimensions.get("window").width > 750 && Platform.OS == "web"
        ? "left"
        : null
  }
});
