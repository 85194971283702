
import { Component } from 'react';
import {
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    Platform
} from 'react-native';
import ButtonStyle from "../constants/ButtonStyle";
import { Icon } from "react-native-elements";
import ConstantFontFamily from "../constants/FontFamily";
import { connect } from "react-redux";
import { compose } from "react-recompose";
// import { openToolkit, toolkitOff, showDiscussion, showbox, getCommentTitle, userHighlight, getRepliedId, postCommentStatus } from '../reducers/action/Completed';
import { showbox } from "../reducers/ShowCommentReducer";
import TooltipBox from './TooltipBox';
import { getRepliedId } from '../reducers/AdminTrueFalseReducer';
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setLikeContent } from "../actionCreator/LikeContentAction";
import _ from "lodash";
import { OuterClick } from 'react-outer-click';
import ConstantColor from '../constants/Colors';
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";



class CommonTooltipBottomComment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showTooltip: false,
            blueLikeCount: 0,
            yellowLikeCount: 0,
            silverLikeCount: 0,
            redLikeCount: 0,
            showRedHeart: true
        }
        this.props1 = this.props.props.isfeed == true ? this.props.props : this.props

    }

    increaseLikeCount = (id, likeColor) => {
        if (likeColor == 'DIAMOND') {
            this.setState({ blueLikeCount: this.state.blueLikeCount + 1 }, () => {
            })
        } else if (likeColor == 'GOLD') {
            this.setState({ yellowLikeCount: this.state.yellowLikeCount + 1 })
        } else if (likeColor == 'SILVER') {
            this.setState({ silverLikeCount: this.state.silverLikeCount + 1 })
        } else if (likeColor == 'RED') {
            this.setState({ redLikeCount: this.state.redLikeCount + 1 })
        }
        this.props.LikeContent({
            content_id: this.props.type == "comment" ? id : id,
            like_type: likeColor
        });
        this.setState({ showTooltip: false, showRedHeart: false })
    }

    render() {


        const props = this.props1
        return (
            <>
                {this.props.type != 'relatedpost' ?
                    <View style={{ flexDirection: 'row', alignItems: this.props.margin != 30 && this.props.type == "comment" ? 'center' : null, justifyContent: 'flex-start' }}>
                        <>
                            {this.props.type == 'comment' ?
                                props?.item?.num_likes?.num_gold_likes > 0 || this.props?.item?.num_likes?.num_gold_likes > 0 || this.state.yellowLikeCount > 0 ?

                                    <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: this.props.margin != 30 && 'center' }]}>

                                        <Icon
                                            color={'#ffce44'}
                                            name={"heart"}
                                            type="font-awesome"
                                            size={12}
                                            iconStyle={{ alignSelf: "center" }}
                                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                                        />
                                        <Text
                                            style={{
                                                paddingLeft: 4,
                                                fontSize: 13,
                                                fontFamily: ConstantFontFamily.defaultFont,
                                                color: ConstantColor.blueColor
                                            }}
                                        >
                                            {this.props?.item?.num_likes ? this.props?.item?.num_likes?.num_gold_likes + this.state.yellowLikeCount : props?.item?.num_likes?.num_gold_likes + this.state.yellowLikeCount}

                                        </Text>
                                    </View> : null :
                                props?.item?.item?.node?.num_likes?.num_gold_likes > 0 || this.props.props?.item?.node?.num_likes?.num_gold_likes > 0 || this.state.yellowLikeCount > 0 ?

                                    <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>

                                        <Icon
                                            color={'#ffce44'}
                                            name={"heart"}
                                            type="font-awesome"
                                            size={12}
                                            iconStyle={{ alignSelf: "center" }}
                                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                                        />
                                        <Text
                                            style={{
                                                paddingLeft: 4,
                                                fontSize: 13,
                                                fontFamily: ConstantFontFamily.defaultFont,
                                                color: ConstantColor.blueColor
                                            }}
                                        >
                                            {this.props?.props?.item?.node?.num_likes ? this.props?.props?.item?.node?.num_likes?.num_gold_likes + this.state.yellowLikeCount : props?.item?.item?.node?.num_likes?.num_gold_likes + this.state.yellowLikeCount}

                                        </Text>
                                    </View> : null}
                        </>
                        <>
                            {this.props.type == 'comment' ?
                                props?.item?.num_likes?.num_diamond_likes > 0 || this.props?.item?.num_likes?.num_diamond_likes > 0 || this.state.blueLikeCount > 0 ?
                                    <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>

                                        <Icon
                                            color={ConstantColor.blueColor}
                                            name={"heart"}
                                            type="font-awesome"
                                            size={12}
                                            iconStyle={{ alignSelf: "center" }}
                                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                                        />
                                        <Text
                                            style={{

                                                paddingLeft: 4,
                                                fontSize: 13,
                                                fontFamily: ConstantFontFamily.defaultFont,
                                                color: ConstantColor.blueColor
                                            }}
                                        >
                                            {this.props?.item?.num_likes ? this.props?.item?.num_likes?.num_diamond_likes + this.state.blueLikeCount : props?.item?.num_likes?.num_diamond_likes + this.state.blueLikeCount}

                                        </Text>
                                    </View> : null
                                : props?.item?.item?.node?.num_likes?.num_diamond_likes > 0 || this.props.props?.item?.node?.num_likes?.num_diamond_likes > 0 || this.state.blueLikeCount > 0 ?
                                    <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>

                                        <Icon
                                            color={ConstantColor.blueColor}
                                            name={"heart"}
                                            type="font-awesome"
                                            size={12}
                                            iconStyle={{ alignSelf: "center" }}
                                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                                        />
                                        <Text
                                            style={{

                                                paddingLeft: 4,
                                                fontSize: 13,
                                                fontFamily: ConstantFontFamily.defaultFont,
                                                color: ConstantColor.blueColor
                                            }}
                                        >
                                            {this.props?.props?.item?.node?.num_likes ? this.props?.props?.item?.node?.num_likes?.num_diamond_likes + this.state.blueLikeCount : props?.item?.item?.node?.num_likes?.num_diamond_likes + this.state.blueLikeCount}

                                        </Text>
                                    </View> : null}
                        </>
                        <>
                            {this.props.type == 'comment' ?

                                props?.item?.num_likes?.num_silver_likes > 0 || this.props?.item?.num_likes?.num_silver_likes > 0 || this.state.silverLikeCount > 0 ?
                                    <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>
                                        <Icon
                                            color={'#b0b0b0'}
                                            name={"heart"}
                                            type="font-awesome"
                                            size={12}
                                            iconStyle={{ alignSelf: "center" }}
                                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                                        />
                                        <Text
                                            style={{
                                                // width: 15,
                                                paddingLeft: 4,
                                                fontSize: 14,
                                                fontFamily: ConstantFontFamily.defaultFont,
                                                color: ConstantColor.blueColor
                                            }}
                                        >{this.props?.item?.num_likes ? this.props?.item?.num_likes?.num_silver_likes + this.state.silverLikeCount : props?.item?.num_likes?.num_silver_likes + this.state.silverLikeCount}
                                        </Text>
                                    </View> : null
                                :
                                props?.item?.item?.node?.num_likes?.num_silver_likes > 0 || this.props?.props?.item?.node?.num_likes?.num_silver_likes > 0 || this.state.silverLikeCount > 0 ?
                                    <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>
                                        <Icon
                                            color={'#b0b0b0'}
                                            name={"heart"}
                                            type="font-awesome"
                                            size={12}
                                            iconStyle={{ alignSelf: "center" }}
                                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                                        />
                                        <Text
                                            style={{
                                                // width: 15,
                                                paddingLeft: 4,
                                                fontSize: 14,
                                                fontFamily: ConstantFontFamily.defaultFont,
                                                color: ConstantColor.blueColor
                                            }}
                                        >{this.props?.props?.item?.node?.num_likes ? this.props?.props?.item?.node?.num_likes?.num_silver_likes + this.state.silverLikeCount : props?.item?.item?.node?.num_likes?.num_silver_likes + this.state.silverLikeCount}
                                        </Text>
                                    </View> : null}
                        </>



                        <TouchableOpacity
                            style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}
                            onPress={() => {
                                if (this.props.type == "comment") {
                                    if (this.props.item?.num_likes?.num_red_likes == 0 && this.state.redLikeCount == 0) {
                                        this.increaseLikeCount(this.props?.item?.id, 'RED')
                                    }

                                } else {
                                    if (this.state.redLikeCount == 0) {
                                        this.increaseLikeCount(this.props?.item?.node?.id, 'RED')
                                    }

                                }
                            }

                            }
                        >
                            <Icon
                                color={"#de5246"}
                                name={"heart"}
                                type="font-awesome"
                                size={12}

                                containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                            />

                            {props?.item?.item?.node?.num_likes?.num_red_likes > 0 || this.props.item?.num_likes?.num_red_likes > 0 || this.state.redLikeCount > 0 ?
                                <Text
                                    style={{

                                        paddingLeft: 4,
                                        fontSize: 14,
                                        fontFamily: ConstantFontFamily.defaultFont,
                                        color: ConstantColor.blueColor
                                    }}
                                >
                                    {
                                        this.props?.item?.num_likes ? this.props?.item?.num_likes?.num_red_likes + this.state.redLikeCount : props?.item?.item?.node?.num_likes?.num_red_likes + this.state.redLikeCount}
                                </Text> : null}
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}
                            onPress={async () => {

                                // this.props.focusCreateComment(true)
                                // this.props.handleDoubleTap()


                                if (this.props.type == "comment") {

                                    if (this.props.loginStatus == 1) {

                                        // await this.props.postCommentStatus(false)
                                        await this.props.getRepliedId(this.props.item.id)

                                        // await this.props.focusCreateComment(true)
                                        // await this.props.setUserr(this.props.item?.author?.username)
                                        if (this.props.lastcomment == 'no') {
                                            await this.props.showbox({ show: true, id: this.props.item.id, showmoreId: '' })
                                        } else {
                                            await this.props.showbox({ show: true, id: this.props.item.id, showmoreId: this.props.parentIdd.id })
                                        }


                                    } else {

                                        await props.setLoginModalStatus(true)


                                    }
                                } else {
                                    // this.props.postCommentStatus(true)
                                    // this.props.showbox({ show: true, id: this.props.item.node.id })

                                }
                            }
                            }
                        >
                            <Icon
                                color={ConstantColor.blueColor}
                                name={"comment"}
                                type="font-awesome"
                                size={12}

                                containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                            />
                            {props?.item?.item?.node?.num_comments > 0 || (this.props.postCommentId == props.item?.item?.node?.id && this.props.totalComment > 0) ?
                                <Text
                                    style={{

                                        paddingLeft: 4,
                                        fontSize: 14,
                                        fontFamily: ConstantFontFamily.defaultFont,
                                        color: ConstantColor.blueColor
                                    }}
                                >
                                    {this.props?.props?.item?.node?.num_comments ? this.props?.props?.item?.node?.num_comments : 0}
                                </Text>
                                : null}
                        </TouchableOpacity>

                        <View style={{ position: "relative", alignItems: "center", flexDirection: 'row' }}>
                            {(this.state.showTooltip && (this.props.openId == props?.item?.item?.node?.id || this.props.openId == this.props?.props?.item?.node?.id) || (this.props.type != "post" && this.props.openId == this.props.item.id)) ? <OuterClick
                                onOuterClick={() => {
                                    this.setState({ showTooltip: false })
                                    this.props.toolkitOff(true)
                                }}
                                style={{ flexDirection: "row", width: "100%", alignItems: "center" }}
                            ><TooltipBox increaseLikeCount={this.increaseLikeCount} type={this.props.type} commentId={this.props.type != "post" ? this.props?.item?.id : null} postId={this.props.type == "post" ? this.props?.item?.node?.id ? this.props?.item?.node?.id : this.props?.props?.item?.node?.id : null} profileData={this.props.profileData} /></OuterClick> : null}

                            {this.props.addIcon == props?.item?.item?.node?.id || this.props.addIcon == this.props?.props?.item?.node?.id || this.props.nestedId == this.props?.item?.id ? <Icon
                                size={20}
                                iconStyle={{ fontWeight: '100', minWidth: 50 }}
                                name="add"
                                type="ion-icon"
                                color={"#899198"}
                                onPress={() => {
                                    if (this.props.openId == props?.item?.item?.node?.id) {
                                        this.setState({ showTooltip: !this.state.showTooltip })

                                    } else if (this.props.openId == this.props?.props?.item?.node?.id) {

                                        this.setState({ showTooltip: !this.state.showTooltip })

                                    } else {
                                        this.setState({ showTooltip: true })

                                    }

                                }}
                            /> : <View style={{ width: 50 }}></View>}

                        </View>

                    </View>
                    : null}
            </>
        )
    }
}
const mapStateToProps = (state) => ({

    // openId: state.openTookitReducer.openId,
    // totalComment: state.TotalCommentReducer.totalComment,
    // nestedComment: state.NestedCommentReducer.nestedComment,
    // PostId: state.PostCommentDetailsReducer.get("PostId"),
    // postCommentId: state.PostCommentIdReducer.id,
    // profileData: state.LoginUserDetailsReducer.userLoginDetails,
    // nestedId: state.showMoreNestedReducer.nestedId,
    // addIcon: state.addIconReducer.id,
    loginStatus: state.UserReducer.loginStatus,





});
const mapDispatchToProps = dispatch => ({
    // userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
    // sharePostId: (payload) => dispatch({ type: "SHAREDPOSTID", payload }),
    setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
    // openToolkit: (payload) => dispatch(openToolkit(payload)),
    // focusCreateComment: (payload) =>
    //     dispatch({ type: "FOCUS_CREATE_COMMENT", payload }),
    LikeContent: payload => dispatch(setLikeContent(payload)),
    // toolkitOff: (payload) => dispatch(toolkitOff(payload)),
    // setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),
    // showDiscussion12: (payload) => dispatch(showDiscussion(payload)),
    showbox: (payload) => dispatch(showbox(payload)),
    // getCommentTitle: (payload) => dispatch(getCommentTitle(payload)),
    // setUserr: (payload) => dispatch(userHighlight(payload)),
    getRepliedId: (payload) => dispatch(getRepliedId(payload)),
    // postCommentStatus: (payload) => dispatch(postCommentStatus(payload))


});
export default compose(connect(mapStateToProps, mapDispatchToProps))(CommonTooltipBottomComment)
