
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  ImageBackground,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  StyleSheet,
  // AsyncStorage,
  Platform
} from "react-native";
import { Icon, withBadge } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { saveUserLoginDaitails, setLoginStatus } from "../actionCreator/UserAction";
import applloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import {
  UserFollowMutation,
  UserUnfollowMutation
} from "../graphqlSchema/FollowandUnFollowMutation";
import { UserLoginMutation, UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation } from "../graphqlSchema/UserMutation";
import { UserEditVariables } from "../graphqlSchema/UserVariables";
import { UserEditMutation } from "../graphqlSchema/UserMutation";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import { getTrendingUsers } from "../actionCreator/TrendingUsersAction";
import AppHelper from "../constants/AppHelper";
import { Hoverable } from "react-native-web-hooks";
import UserStar from "../components/UserStar";
import ButtonStyle from "../constants/ButtonStyle";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp
} from "react-native-responsive-screen";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger
} from "react-native-popup-menu";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { setAdminStatus } from "../actionCreator/AdminAction";
import ConstantColors from "../constants/Colors";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { userIconUpload, showReportUserModal } from '../reducers/AdminTrueFalseReducer';
import ProifileCardStyle from "./Style/ProifileCardStyle";
// import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";



const UserProfileCard = props => {
  let [Height, setHeight] = useState(0);
  const user = props.item;
  const [followBtnActive, setfollowBtnActive] = useState(0);
  const [currentUserId, setcurrentUserId] = useState("");

  const [Username, setUsername] = useState(false);
  const [FullName, setFullName] = useState(false);
  const [Desc, setDesc] = useState(false);

  const [ErrorMessage, setErrorMessage] = useState('')
  let userFollowers = user;
  let [username, setusername] = useState(user.username);
  let [fullname, setfullname] = useState(user.full_name);
  let [description, setdescription] = useState(
    user.description
  );
  let [profilepic, setprofilepic] = useState(
    user.profile_pic
  );

  const [value, setvalue] = useState(50);
  const [brightnessvalue, setbrightnessvalue] = useState(0.5);
  const [showSlider, setshowSlider] = useState(false);
  const [showEditProfile, setshowEditProfile] = useState(false);
  let [MenuHover, setMenuHover] = useState(false);
  let [uploading, setuploading] = useState(false);
  let [updatebannerpic, setupdatebannerpic] = useState("");
  let [updateprofilepic, setupdateprofilepic] = useState("");

  useEffect(() => {
    const auth = getAuth()
    if (props.loginStatus == 1 && auth.currentUser) {
      setcurrentUserId(props.profileData?.data?.id?.replace("Account:", "User:"));

      const itemId = user.username;

      const index = props.getUserFollowUserList.findIndex(
        i => i.node?.user?.username?.toLowerCase() == itemId &&
          itemId
            .replace("%3A", ":")
            .toLowerCase()
            .replace("user:", "")

      );
      if (index != -1) {
        if (
          props.getUserFollowUserList[index].node.follow_type ==
          "FAVORITE"
        ) {
          setfollowBtnActive(2);
          props.icon("#FADB4A");
        } else if (
          props.getUserFollowUserList[index].node.follow_type ==
          "FOLLOW"
        ) {
          setfollowBtnActive(1);
          props.icon("#E1E1E1");
        }
      } else {
        setfollowBtnActive(0);
        props.icon("#fff");
      }
    }
  });

  function changeBrightness(value) {
    setvalue(value);
    setbrightnessvalue(value / 100);
  }

  async function _askPermission(type, failureMessage) {
    const { status, permissions } = await askAsync(type);
    if (status === "denied") {
      alert(failureMessage);
    }
  }

  async function _pickBannerImage() {
    await _askPermission(
      CAMERA_ROLL,
      "We need the camera-roll permission to read pictures from your phone..."
    );
    let pickerResult = await launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [4, 3],
      base64: true
    });
    _handleBannerImagePicked(pickerResult);
  }


  async function _handleBannerImagePicked(pickerResult) {
    let uploadResponse, uploadResult;
    try {
      setuploading(true);
      if (!pickerResult.cancelled) {
        uploadResponse = await uploadBannerImageAsync(pickerResult.uri);
        uploadResult = await uploadResponse.json();
        setbannerpic(pickerResult.uri);
        setupdatebannerpic(uploadResult.id);
      }
    } catch (e) {
      alert("Upload failed, sorry :(" + e + ")");
    } finally {
      setuploading(false);
    }
  }

  async function _pickProfileImage() {
    await _askPermission(
      CAMERA_ROLL,
      "We need the camera-roll permission to read pictures from your phone..."
    );
    let pickerResult = await launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [4, 3],
      base64: true
    });
    _handleProfileImagePicked(pickerResult);
  }

  async function _handleProfileImagePicked(pickerResult) {
    let uploadResponse, uploadResult;
    try {
      setuploading(true);
      if (!pickerResult.cancelled) {
        uploadResponse = await uploadProfileImageAsync(pickerResult.uri, true);
        uploadResult = await uploadResponse.json();
        setprofilepic(pickerResult.uri);
        setupdateprofilepic(uploadResult.id);
      }
    } catch (e) {
      alert("Upload failed, sorry :(" + e + ")");
    } finally {
      setuploading(false);
    }
  }

  function setUpdateProfile() {

    const itemId = user.id;

    // if (itemId == "NO-ID") {
    //   props.navigation.navigate("home");
    // }
    UserEditVariables.variables.username = username;
    UserEditVariables.variables.full_name = fullname;
    UserEditVariables.variables.description = description;
    // UserEditVariables.variables.banner_pic = updatebannerpic
    // ? updatebannerpic
    // : null;
    UserEditVariables.variables.profile_pic = updateprofilepic
      ? updateprofilepic.toString()
      : null;
    if (
      UserEditVariables.variables.username !=
      user.username ||
      UserEditVariables.variables.full_name !=
      user.full_name ||
      UserEditVariables.variables.description !=
      user.description
    ) {
      updateProfileApi(UserEditVariables, itemId);
    } else if (
      (UserEditVariables.variables.username ==
        user.username &&
        UserEditVariables.variables.full_name ==
        user.full_name &&
        UserEditVariables.variables.description ==
        user.description &&
        UserEditVariables.variables.profile_pic != null)

    ) {
      updateProfileApi(UserEditVariables, itemId);
    } else {
      props.closeEditProfile();
      setshowSlider(false);
    }
  }

  function updateProfileApi(UserEditVariables, itemId) {
    console.log(UserEditVariables, 'UserEditVariables')
    applloClient
      .query({
        query: UserEditMutation,
        ...UserEditVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        if (res.data.user_update.status.status == 'INVALID_ARGUMENT') {
          setErrorMessage(res.data.user_update.status.user_msg)
        } else {
          props.userId({
            username: UserEditVariables.variables.username,
            type: "feed"
          });

          let resDataLogin = await applloClient
            .query({
              query: UserLoginMutation,
              variables: {
                id: "Account:" + "CurrentUser"
              },
              fetchPolicy: "no-cache"
            }).then(res => {
              return res
            })

          let userfollow = await applloClient
            .query({
              query: UserFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.users_following.edges
            })

          let topicfollow = await applloClient
            .query({
              query: TopicFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.topics_following.edges
            })
          let clikfollow = await applloClient
            .query({
              query: ClikFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.cliks_following.edges
            })
          let externalfollow = await applloClient
            .query({
              query: ExternalFeedFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.external_feeds_following.edges
            })

          let userData = {
            data: resDataLogin.data.node,
            externalfollow: externalfollow,
            clikfollow: clikfollow,
            topicfollow: topicfollow,
            userfollow: userfollow

          }
          await props.saveLoginUser(userData);
          await props.getTrendingUsers({
            currentPage: AppHelper.PAGE_LIMIT
          });
          props.closeEditProfile();
          setshowSlider(false);
          await props.userProfileUpload(true)
        }

      })
      .catch(e => console.log(e));
  }

  function setUpdateData() {
    setshowEditProfile(true);
    setfullname(user.full_name);
    setusername(user.username);
    setdescription(user.description);
    setprofilepic(
      user.profile_pic
        ? user.profile_pic
        : null
    );

  }

  const logout = async () => {
    if (Platform.OS == 'web') {
      localStorage.clear();
      extensionLogout();

    }
    AsyncStorage.removeItem("userIdTokenFirebase");
    AsyncStorage.getAllKeys()
      .then(keys => AsyncStorage.multiRemove(keys))
      .then(() => console.log('success'));
    const auth = getAuth()
    await signOut(auth);

    await props.changeLoginStatus(0);
    await props.changeAdminStatus(false);

    await props.navigation.navigate("home");
    // }
    await props.resetLoginUserDetails();
    await props.resetUserProfileDetails();




  };


  function extensionLogout() {
    try {
      window.parent.postMessage({ type: "wecklid_logout" }, "*");
    } catch (err) {
      console.log("Extension Logout Error ", err);
    }
  };
  const updateStar = async () => {
    await props.userProfileUpload(true)
  }
  // console.log(props.showEditProfile, 'props.showEditProfile')

  const reportUser = (userId) => {
    console.log(userId,'userId')
    props.showReportUserModal(true, userId)
  }
  return (
    <>
      {props.showEditProfile == false ?

        <TouchableOpacity style={[ProifileCardStyle.mainCardContainer,
        Dimensions.get('window').width > 750 ? null : ButtonStyle.profileShadowStyle,
        ]}
          onPress={() => props.openPopUp()}
          disabled={props.loginStatus == 1 && Platform.OS != 'web' ? false : true}
        >

          <View style={{ flexDirection: 'row', width: '100%', marginVertical: 15, }}>

            <View style={{ marginLeft: 15, }}>
              <Image
                source={user.profile_pic ?
                  { uri: user.profile_pic.toString() } : require("../assets/image/default-image.png")
                }
                style={{
                  height: Dimensions.get("window").width <= 750 ? 60 : 80,
                  width: Dimensions.get("window").width <= 750 ? 60 : 80,
                  padding: 0,
                  margin: 0,
                  borderRadius: 40,
                  borderWidth: 1,
                  borderColor: "#fff",
                }}
              />

            </View>
            <View style={{
              flexDirection: 'row', marginLeft: 15, width: (Dimensions.get('window').width <= 750 && user.profile_pic != null) ? '65%' :
                (Dimensions.get('window').width <= 750 && user.profile_pic == null) ? '90%' : Dimensions.get('window').width > 1600 ? '90%' : '80%', alignItems: 'center'
            }}>
              <View style={{ width: '85%', }}>
                <View
                  style={[ButtonStyle.clikNameBackgroundStyle, {
                    backgroundColor: '#fff',
                    marginTop: 0,
                    marginLeft: 0,
                    paddingBottom: 10,
                    paddingTop: 0,
                    paddingHorizontal: 0,

                  }]}
                >
                  <Text
                    style={[
                      ButtonStyle.clikNameTitleStyle,
                      {
                        width: "100%",
                        color: "#000",

                      },
                    ]}
                  >
                    @{user.username}
                  </Text>


                </View>
                <View
                  onLayout={event => {
                    let { x, y, width, height } = event.nativeEvent.layout;
                    setHeight(height);
                  }}
                  style={{
                    // flexWrap: 'wrap',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <Text
                    style={{
                      // marginBottom: 20,
                      color: "#000",
                      fontSize: 14,
                      fontFamily: ConstantFontFamily.defaultFont,
                      textAlign: 'left',

                      flexShrink: 1,
                    }}
                  // numberOfLines={1}
                  >
                    {user.description}

                  </Text>
                </View>
              </View>

              <View style={{ alignSelf: 'flex-start', width: '15%', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {props.loginStatus == 1 && Dimensions.get('window').width <= 750 && <TouchableOpacity

                  onPress={() => props.setOpen()}

                >

                  <Image
                    source={require("../assets/image/menu.png")}
                    style={{
                      height: 16,
                      width: 16,
                      marginTop: 5,
                      marginRight: Dimensions.get('window').width <= 750 ? 10 : 10,
                      transform: [{ rotate: "90deg" }]
                    }}
                  />
                </TouchableOpacity>}

                {props.loginStatus == 1 && Dimensions.get('window').width > 750 &&
                  <TouchableOpacity
                    onMouseEnter={() => setMenuHover(true)}
                    onMouseLeave={() => setMenuHover(false)}

                  >
                    <Menu>
                      <MenuTrigger>
                        <Image
                          source={require("../assets/image/menu.png")}
                          style={{
                            height: 16,
                            width: 16,
                            marginTop: 5,
                            marginRight: 15,
                            transform: [{ rotate: "90deg" }]
                          }}
                        />
                      </MenuTrigger>
                      {props.loginStatus == 1 && user.id == currentUserId ?
                        <MenuOptions
                          optionsContainerStyle={{
                            borderRadius: 6,
                            borderWidth: 1,
                            borderColor: "#e1e1e1",
                            shadowColor: "transparent",
                          }}
                          customStyles={{
                            optionsContainer: {
                              minHeight: 50,
                              width: 150,
                              marginTop: Platform.OS != 'web' ? -20 : 20,

                            }
                          }}
                        >
                          <MenuOption
                            onSelect={() => {
                              return props.navigation.navigate("settings");
                            }}
                          >
                            <Hoverable>
                              {isHovered => (
                                <Text
                                  style={{
                                    textAlign: "center",
                                    color: isHovered == true ? ConstantColors.blueColor : "#000",
                                    fontFamily: ConstantFontFamily.defaultFont
                                  }}
                                >
                                  Settings
                                </Text>
                              )}
                            </Hoverable>
                          </MenuOption>

                          <MenuOption onSelect={() => props.navigation.navigate("analytics")}>
                            <Hoverable>
                              {isHovered => (
                                <Text
                                  style={{
                                    textAlign: "center",
                                    color: isHovered == true ? ConstantColors.blueColor : "#000",
                                    fontFamily: ConstantFontFamily.defaultFont
                                  }}
                                >
                                  Analytics
                                </Text>
                              )}
                            </Hoverable>
                          </MenuOption>
                          <MenuOption
                            onSelect={() => props.setUpdateData()}
                          >
                            <Hoverable>
                              {isHovered => (
                                <Text
                                  style={{
                                    textAlign: "center",
                                    color: isHovered == true ? ConstantColors.blueColor : "#000",
                                    fontFamily:
                                      ConstantFontFamily.defaultFont
                                  }}
                                >
                                  Edit Profile
                                </Text>
                              )}
                            </Hoverable>
                          </MenuOption>
                          <MenuOption
                            onSelect={() => logout()}
                          >
                            <Hoverable>
                              {isHovered => (
                                <Text
                                  style={{
                                    textAlign: "center",
                                    color: isHovered == true ? ConstantColors.blueColor : "#000",
                                    fontFamily:
                                      ConstantFontFamily.defaultFont
                                  }}
                                >
                                  Sign Out
                                </Text>
                              )}
                            </Hoverable>
                          </MenuOption>
                        </MenuOptions>
                        :
                        props.loginStatus == 1 && user.id != currentUserId ?
                          <MenuOptions
                            optionsContainerStyle={{
                              borderRadius: 6,
                              borderWidth: 1,
                              borderColor: "#e1e1e1",
                              shadowColor: "transparent"
                            }}
                            customStyles={{
                              optionsContainer: {
                                minHeight: 25,
                                width: 150,
                                marginTop: Platform.OS != 'web' ? -20 : 20,

                              }
                            }}
                          >
                            <MenuOption onSelect={() => reportUser(user.id)}>
                              <Hoverable>
                                {isHovered => (
                                  <Text
                                    style={{
                                      textAlign: "center",
                                      color: isHovered == true ? ConstantColors.blueColor : "#000",
                                      fontFamily: ConstantFontFamily.defaultFont
                                    }}
                                  >
                                    Report
                                  </Text>
                                )}
                              </Hoverable>
                            </MenuOption>

                            {props.isAdmin &&
                              <MenuOption >
                                <Hoverable>
                                  {isHovered => (
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        color: isHovered == true ? ConstantColors.blueColor : "#000",
                                        fontFamily: ConstantFontFamily.defaultFont
                                      }}
                                    >
                                      Delete
                                    </Text>
                                  )}
                                </Hoverable>
                              </MenuOption>}
                          </MenuOptions>
                          :
                          props.loginStatus == 0 && (

                            <MenuOptions
                              optionsContainerStyle={{
                                borderRadius: 6,
                                borderWidth: 1,
                                borderColor: "#e1e1e1",
                                shadowColor: "transparent"
                              }}
                              customStyles={{
                                optionsContainer: {
                                  minHeight: 50,
                                  width: 150,
                                  marginTop: Platform.OS != 'web' ? -20 : 20,
                                  marginLeft: Dimensions.get('window').width < 1350 ? -25 : -140,
                                }
                              }}>
                              <MenuOption
                                onSelect={() => {
                                  return props.navigation.navigate("settings");
                                }}
                              >
                                <Hoverable>
                                  {isHovered => (
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        color: isHovered == true ? ConstantColors.blueColor : "#000",
                                        fontFamily: ConstantFontFamily.defaultFont
                                      }}
                                    >
                                      Settings
                                    </Text>
                                  )}
                                </Hoverable>
                              </MenuOption>

                              <MenuOption onSelect={() => props.navigation.navigate("analytics")}>
                                <Hoverable>
                                  {isHovered => (
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        color: isHovered == true ? ConstantColors.blueColor : "#000",
                                        fontFamily: ConstantFontFamily.defaultFont
                                      }}
                                    >
                                      Analytics
                                    </Text>
                                  )}
                                </Hoverable>
                              </MenuOption>
                            </MenuOptions>
                          )
                      }

                    </Menu>
                  </TouchableOpacity>
                }
                {user?.id != currentUserId ?
                  < UserStar
                    UserName={props.item.id && props.item.id?.toLowerCase()}
                    UserId={props.item.id}
                    ContainerStyle={{ justifyContent: "center" }}
                    ImageStyle={{
                      height: 20,
                      width: 20,
                      alignSelf: "center",
                      marginLeft: 15

                    }}
                    updateStar={updateStar}
                  />
                  : null
                }
              </View>
            </View>

          </View>

          {/* </View> */}

        </TouchableOpacity>
        :
        <>
          <View style={{ width: Dimensions.get('window').width <= 750 ? '90%' : '95%', marginHorizontal: 20 }}>
            <View style={{
              flexDirection: 'row', justifyContent: 'space-between',
              paddingTop: 20,

            }}>
              <View style={{ flexDirection: 'row', width: '50%', marginRight: 10 }}>

                {profilepic ? (

                  <Image
                    source={{
                      uri: profilepic?.toString()
                    }}
                    style={{
                      height: 100,
                      width: 100,
                      padding: 0,
                      margin: 0,
                      borderRadius: 50,
                      borderWidth: 1,
                      borderColor: "#fff"
                    }}
                  />
                ) : (

                  null
                )}

                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: 'center'
                  }}
                  reverse
                  name="camera"
                  type="font-awesome"
                  size={16}
                  containerStyle={{
                    alignSelf: "center",
                    position: "absolute",
                    paddingHorizontal: 25,
                    paddingBottom: 10
                  }}
                  onPress={() => _pickProfileImage()}
                />

                <View
                  style={{
                    flexDirection: "column",
                    paddingHorizontal: 10,
                    width: '100%'
                  }}
                >
                  <TextInput
                    value={fullname}
                    placeholder="Full Name (Optional)"
                    underlineColorAndroid="transparent"
                    style={[FullName ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                    {
                      width: "100%",
                      height: 40,
                      color: "#000",
                      fontSize: 16,
                      fontWeight: "bold",

                      fontFamily: ConstantFontFamily.defaultFont,
                      paddingLeft: 10

                    }]}
                    onChangeText={value => setfullname(value)}
                    onFocus={() => setFullName(true)}
                    onBlur={() => setFullName(false)}
                  />
                  <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 10,
                    width: '100%'
                  }}>
                    <Text style={{
                      color: "#000",
                      fontSize: 18,
                      fontWeight: "bold",
                      marginRight: '2%',
                      width: Dimensions.get('window').width <= 750 ? '20%' : '10%',
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}> @</Text>
                    <TextInput
                      value={username}
                      underlineColorAndroid="transparent"
                      style={[Username ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle, {
                        width: Dimensions.get('window').width <= 750 ? "80%" : '90%',

                        height: 40,
                        color: "#000",
                        fontSize: 12,
                        fontWeight: "bold",

                        fontFamily: ConstantFontFamily.defaultFont,
                        paddingLeft: 10
                      }]}
                      onChangeText={value => setusername(value)}
                      onFocus={() => setUsername(true)}
                      onBlur={() => setUsername(false)}
                    />

                  </View>
                  <Text style={{
                    color: "#f00",
                    fontSize: 14,
                    fontWeight: "bold",
                    marginLeft: '2%',
                    width: '20%',
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}> {ErrorMessage}</Text>
                </View>

              </View>
              <View style={{ flexDirection: 'row', width: Dimensions.get('window').width <= 750 ? '18%' : '45%', justifyContent: 'flex-end' }}>
                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#000",

                    marginRight: 10
                  }}

                  name="save"
                  type="ion-icons"
                  size={30}
                  onPress={() => {
                    setUpdateProfile();
                  }}
                />
                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#000",

                  }}

                  name="close"
                  type="ion-icons"
                  size={30}
                  onPress={() => props.closeEditProfile()}
                />
              </View>
            </View>
            <TextInput
              multiline={true}
              numberOfLines={2}
              value={description}
              style={[Desc ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle, {

                marginVertical: 10,
                color: "#000",
                fontSize: 13,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont,
                paddingTop: 10,
                paddingHorizontal: 10,
                width: '100%',
                height: 65
              }]}
              onChangeText={value => setdescription(value)}
              onFocus={() => setDesc(true)}
              onBlur={() => setDesc(false)}
            />
          </View>

          {showSlider && (
            <View style={{ alignItems: "stretch", justifyContent: "center" }}>

              <View style={{ alignItems: "center", justifyContent: "center" }}>
                <Text style={{ color: "#000", fontFamily: ConstantFontFamily.defaultFont }}>
                  Slide to change the brightness
                </Text>
              </View>
            </View>
          )}

        </>



      }
    </>
  );
};

const mapStateToProps = state => ({
  getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
    ? state.LoginUserDetailsReducer.userFollowUserList
    : [],
  loginStatus: state.UserReducer.loginStatus,
  // isAdmin: state.AdminTrueFalseReducer.get("isAdmin"),
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  // windowSize: state.windowResizeReducer.windowResize,

});

const mapDispatchToProps = dispatch => ({
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  userId: payload => dispatch(getCurrentUserProfileDetails(payload)),
  getTrendingUsers: payload => dispatch(getTrendingUsers(payload)),
  // changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  changeAdminStatus: payload => dispatch(setAdminStatus(payload)),
  // resetLoginUserDetails: payload =>
  //   dispatch({ type: "LOGIN_USER_DETAILS_RESET", payload }),
  // resetUserProfileDetails: payload =>
  //   dispatch({ type: "USER_PROFILE_DETAILS_RESET", payload }),

  userProfileUpload: (payload) =>
    dispatch(userIconUpload(payload)),
  showReportUserModal: (reportUserModal, userId) => dispatch(showReportUserModal({ type: "reportUser", payload: { reportUserModal, userId } }))

});



export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UserProfileCard
);
const styles = StyleSheet.create({
  iconsStyle: {
    backgroundColor: '#000',
    borderRadius: 20,
    height: 35,
    width: 35,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 10
  }
})