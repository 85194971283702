// import { openBrowserAsync } from "expo-web-browser";
import React, { lazy, Component, Suspense } from "react";

// import { graphql } from "react-apollo";
import {
  ActivityIndicator,
  Animated,
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  // AsyncStorage
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, Icon, Tooltip } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
// import { setPostDetails } from "../actionCreator/PostDetailsAction";
import appolloClient from "../client";
import Colors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import { PostCreateMutation } from "../graphqlSchema/PostMutation";
import ButtonStyle from "../constants/ButtonStyle";
import { postLink } from "../reducers/LinkPostReducer";
// import NavigationService from "../library/NavigationService";
// import { feedFluctuation, screen } from "../reducers/action/Completed";
import { PostCreateVariables } from "../graphqlSchema/PostVariables";
// import HeaderRightDashboard from "../components/HeaderRightDashboard";
// import { color } from "react-native-elements/dist/helpers";
// import { retry } from "../library/Helper";

// const HeaderRight = lazy(() => import("../components/HeaderRight"))

// const ShareToComponent = lazy(() => import("../components/ShareToComponent"))
// const LeftPanel = lazy(() =>
//   retry(() => import("../components/LeftPanel"))
// );

import ShareToComponent from "../components/ShareToComponent";
import LeftPanel from "../components/LeftPanel";

const SearchParam = Platform.OS == 'web' && Dimensions.get('window').width > 750 ? new URLSearchParams(window.location.search) : null

class CreatePostScreen extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.Pagescrollview = null;
    this.state = {
      profilePic: "",
      title: this.props.link.title,
      uploadMutipleImagePost: [],
      changeBackPicEnable: {
        uri: this.props.link.image,
      },
      showsVerticalScrollIndicatorView: false,
      currentScreentWidth: 0,
      summary: this.props.link.description,
      selectedTopics: [],
      titleHover: false,
      summaryHover: false,
      focusTitle: false,
      focusSummary: false,
      topic: "",
      selectedCliks: [],
      url: this.props.link.url,
      // : null,
      loading: false,
      topicData: [],
      userData: [],
      clikData: []

    };
    this.textFocus = React.createRef()
    this.changeBannerImage = "";
    this.baseState = this.state;

  }

  componentDidMount = async () => {
    // this.props.searchOpenBarStatus(false);
    // this.props.screen('createPost')
    this._isMounted = true;
  };

  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.link.description !== this.props.link.description
    ) {
      this.setState({
        summary: this.props.link.description,
        topic: "",
      });
    }

    if (prevProps.link.title !== this.props.link.title) {
      this.setState({
        title: this.props.link.title,
        topic: "",
      });
    }

    if (prevProps.link.url !== this.props.link.url) {
      this.setState({
        url: this.props.link.url,
        topic: "",
      });
    }

    if (prevProps.link.image !== this.props.link.image) {
      this.setState({
        changeBackPicEnable: { uri: this.props.link.image },
        topic: "",
      });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    this.setState(this.baseState);
  }



  onSave = async () => {
    let __self = this;
    this.setState({
      loading: true,
    });


    new Promise(async (resole, reject) => {
      let uploadImageTotal = [];
      if (this.state.uploadMutipleImagePost.length > 0) {
        this.state.uploadMutipleImagePost.forEach(async (element, index) => {
          uploadImageTotal.push(element.id);
        });
      }
      PostCreateVariables.variables.title = this.state.title;
      PostCreateVariables.variables.summary = this.state.summary;
      PostCreateVariables.variables.link = this.state.url;
      PostCreateVariables.variables.thumbnail_pic_url = this.state
        .changeBackPicEnable.id
        ? ""
        : this.props.link.image;
      if (this.state.changeBackPicEnable) {
        PostCreateVariables.variables.thumbnail_pic = this.state
          .changeBackPicEnable.id
          ? this.state.changeBackPicEnable.id
          : "";
      }
      if (PostCreateVariables.variables.pictures.length == 0) {
        PostCreateVariables.variables.pictures = uploadImageTotal;
      }
      if (this.state.selectedCliks.length > 0) {
        let newUpdateArray = [];
        this.state.selectedCliks.forEach(async (element, index) => {
          await newUpdateArray.push(element.name);
        });
        PostCreateVariables.variables.cliks = newUpdateArray;
      }
      if (this.state.selectedTopics.length > 0) {
        let newUpdateTopicArray = [];
        this.state.selectedTopics.forEach(async (element, index) => {
          await newUpdateTopicArray.push(element.name);
        });
        PostCreateVariables.variables.topics = newUpdateTopicArray;
      }
      this.changeBannerImage = null;
      this.setState(this.baseState);


      let usersData = []

      let cliks = []
      let topics = []

      this.state.topicData.map(e => {
        let topic = "Topic:" + e.name
        topics.push(topic)
      })
      this.state.clikData.map(e => {
        let clik = "Clik:" + e.name
        cliks.push(clik)
      })

      this.state.userData.forEach(e => {

        let name = "User:" + e.name
        usersData.push(name)
      })
      await appolloClient
        .query({
          query: PostCreateMutation,
          variables: {
            title: this.state.title,
            summary: this.state.summary,
            link: this.state?.url?.length > 0 ? this.state?.url : null,
            cliks: cliks.length > 0 ? cliks : null,
            topics: topics.length > 0 ? topics : null,
            users: usersData.length > 0 ? usersData : null
          },
          fetchPolicy: "no-cache"
        }).then(async (r) => {
          if (r.data.post_create.post.status == "ALREADY_EXISTS") {
            let idd = r.data.post_create.post.id.replace("Post:", "")
            this.props.navigation.navigate("home");
            // this.props.feedFluctuation(false)

          } else {
            // this.props.feedFluctuation(false)
            let item = {
              node: r.data.post_create.post
            }
            // NavigationService.navigate("home");
            let dataitem = item;
            await AsyncStorage.setItem("dataItem", JSON.stringify(dataitem));
            this.setState({
              loading: false,
            });
            // await __self.props.setPostDetails({
            //   id: r.data.post_create.post.id,
            //   title: r.data.post_create.post.title,
            //   navigate: true,
            // });
            await __self.props.setPostCommentDetails({
              id: r.data.post_create.post.id,
              title: r.data.post_create.post.title,
            });
            this.props.navigation.navigate("relatedPost", { id: r.data.post_create.post.id.replace("Post:", "") });


          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
          });
        });

    })
  };



  openWindow = async (link) => {
    // await openBrowserAsync(link);
  };


  handleTopicSelectInput = (topic) => {

    const { topicData } = this.state
    if (topicData.length < 3) {
      let index = topicData.findIndex((i) => i.name == topic);
      if (index != -1) {
        alert("topic name already selected");
      } else {
        this.setState({
          topicData: topicData.concat([{ name: topic }])

        })


      }
    } else {
      alert("You can only choose Maximum 3 Topics to Tag");
      // setTopic('')
    }
  };

  handleClikDelete = (index) => {
    let tagsSelected = this.state.clikData
    tagsSelected.splice(index, 1)
    this.setState({ clikData: tagsSelected })
    // console.log(this.state.clikData, 'clikkkk');
  }

  handleTopicDelete = (index) => {
    let tagsSelected = this.state.topicData
    tagsSelected.splice(index, 1)
    this.setState({ topicData: tagsSelected })
  }

  handleUserDelete = (index) => {
    let tagsSelected = this.state.userData
    tagsSelected.splice(index, 1)
    this.setState({ userData: tagsSelected })
  }



  handleClikSelectInput = (clik) => {
    const { clikData } = this.state
    if (clikData.length < 3) {
      // let index = clikData.findIndex((i) => i.name == clik);
      // if (index != -1) {
      //   alert("clik name already selected");
      // } else {

      this.setState({
        clikData: clikData.concat([{ name: clik }])

      })

      // }
    } else {
      alert("You can only choose Maximum 3 Cliks to Tag");


    }
  };
  handleUserSelectInput = (user) => {


    const { userData } = this.state

    if (userData.length < 3) {
      let index = userData.findIndex((i) => i.name == user);
      if (index != -1) {
        alert("clik name already selected");
      } else {
        this.setState({
          userData: userData.concat([{ name: user }])
        })

      }
    }
  }





  render() {
    const textStyle = styles.usertext;
    return (
      <View
        style={[Dimensions.get('window').width > 750 ? ButtonStyle.threeColStruc : null, {
          width: Dimensions.get('window').width > 750 ? Dimensions.get('window').width : '100%',
          alignItems: Dimensions.get('window').width > 750 ? 'center' : 'normal',
        }]}
        scrollEnabled={false}

      >
        {Dimensions.get('window').width > 750 ?


          <LeftPanel
            // ref={navigatorRef => {
            //   NavigationService.setTopLevelNavigator(navigatorRef);
            // }}
            navigation={this.props.navigation}
          />
          : null}
        <View
          style={{
            // flex: 1,
            backgroundColor: Colors.whiteBackground,
            width: this.props.windowSize.width > 1200 && this.props.windowSize.width < 1600 ? 900 :
              this.props.windowSize.width > 1600 ? 1200 : (this.props.windowSize.width - 310),
            borderLeftWidth: 1,
            borderColor: Colors.lightGray
          }}
        >
          {
            Dimensions.get("window").width <= 750 && Platform.OS != "web" ? (
              <Animated.View
                style={{
                  position: Platform.OS == "web" ? "sticky" : null,
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  overflow: "hidden",
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      backgroundColor: "#000",
                      height: 50,
                    }}
                  >
                    <TouchableOpacity
                      style={ButtonStyle.headerBackStyle}
                      onPress={() => {
                        this.props.searchOpenBarStatus(false);
                        let nav =
                          this.props.navigation.dangerouslyGetParent().state;
                        if (nav.routes.length > 1) {
                          this.props.navigation.goBack();
                          return;
                        } else {
                          this.props.navigation.navigate("home");
                        }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>

                    {!this.props.getsearchBarStatus && (
                      <TouchableOpacity
                        style={[
                          ButtonStyle.headerTitleStyle,
                          { backgroundColor: "#000" },
                        ]}
                      >
                        <Text
                          style={{
                            color: "#fff",
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: 18,
                            fontFamily: ConstantFontFamily.defaultFont,
                          }}
                        >
                          Create Post
                        </Text>
                      </TouchableOpacity>
                    )}
                    {/* {
                      <View style={[
                        ButtonStyle.headerRightStyle,

                        { width: this.props.getsearchBarStatus ? "80%" : '20%' },
                      ]}>

                        <HeaderRightDashboard navigation={this.props.navigation} />
                      </View>
                    } */}
                  </View>
                </View>
              </Animated.View>
            ) : null
          }
          <ScrollView
            ref={(scrollview) => {
              this.Pagescrollview = scrollview;
            }}
            showsVerticalScrollIndicator={false}
            onLayout={(event) => {
              let { x, y, width, height } = event.nativeEvent.layout;
              if (width < 1024) {
                this.setState({
                  showsVerticalScrollIndicatorView: true,
                  currentScreentWidth: width,
                });
              } else {
                this.setState({
                  showsVerticalScrollIndicatorView: false,
                  currentScreentWidth: width,
                });
              }
            }}
            style={[
              {
                height:
                  Platform.OS !== "web"
                    ? null
                    : Dimensions.get("window").height - 80,
                backgroundColor: Colors.whiteBackground,
              },
            ]}
          >




            <View
              style={[
                Dimensions.get("window").width >= 1200
                  ? ButtonStyle.normalFeedStyleForDashboard
                  : null,
                {
                  paddingHorizontal: 20,
                  marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                  marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 2,
                  borderBottomWidth: 0
                },
              ]}
            >
              {this.state.url != "" || this.state?.url?.length > 0 && (
                <View style={{ marginBottom: 10 }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      marginTop: 10,
                      fontSize: 16,
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                  >
                    URL
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 5,
                    }}
                  >
                    <View
                      style={{
                        justifyContent: "flex-start",
                        alignContent: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <View
                        style={{
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                      >
                        {this.state.url != "" ? (
                          <Icon
                            name="link"
                            type="font-awesome"
                            color="#000"
                            size={20}
                          />
                        ) : null}
                      </View>
                      <TouchableOpacity
                        onPress={() =>
                          this.state.url ? this.openWindow(this.state.url) : null
                        }
                      >
                        <Text
                          style={{
                            alignSelf: "center",
                            color: "#000",
                            fontFamily: ConstantFontFamily.defaultFont,
                            margin: 10,
                          }}
                        >
                          {this.state.url?.length > 30
                            ? this.state?.url
                              .replace("http://", "")
                              .replace("HTTP://", "")
                              .replace("https://", "")
                              .replace("HTTPS://", "")
                              .replace("www.", "")
                              .replace("WWW.", "")
                              .replace(/(^\w+:|^)\/\//, "")
                            : this.state?.url}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              )}
              <View style={{ flexDirection: "row", width: "100%", }}>
                <View
                  style={{
                    width: "70%",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      marginTop: 10,
                      marginRight: 20,
                      fontSize: 16,
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                  >
                    Title
                  </Text>
                </View>
                <View
                  style={{
                    width: "30%",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      color:
                        this.state.title.length < 10 ||
                          this.state.title.length > 150
                          ? "#de5246"
                          : "#009B1A",
                      fontSize: 13,
                      fontFamily: ConstantFontFamily.defaultFont,
                      marginTop: 2,
                      marginRight: 3,
                    }}
                  >
                    {this.state.title.length < 10
                      ? 10 - this.state.title.length + " characters more"
                      : this.state.title.length > 10 &&
                      150 - this.state.title.length + " characters left"}
                  </Text>
                  {(this.state.title.length < 10 ||
                    this.state.title.length > 150) && (
                      <TouchableOpacity
                        onMouseEnter={() => this.setState({ titleHover: true })}
                        onMouseLeave={() => this.setState({ titleHover: false })}
                        style={{ marginBottom: 2 }}

                      >
                        <Icon
                          color={"#f80403"}
                          iconStyle={{
                            marginTop: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          name="times"
                          type="font-awesome"
                          size={16}
                        />
                      </TouchableOpacity>
                    )}
                  {this.state.titleHover == true && Platform.OS == "web" ? (
                    <Tooltip
                      backgroundColor={"#d3d3d3"}
                      withPointer={false}
                      withOverlay={false}
                      toggleOnPress={true}
                      containerStyle={{
                        left: -150,
                        top: -50,
                      }}
                      popover={<Text>Between 10 to 150 Characters</Text>}
                    />
                  ) : null}
                </View>
              </View>
              <TextInput
                testID="title1"
                value={this.state.title}
                multiline={true}
                numberOfLines={2}
                maxLength={150}
                placeholder={
                  this.props.link.withoutUrl
                    ? "Write a concise title that is not misleading nor clickbait."
                    : "Enter a title."
                }
                placeholderTextColor="#6D757F"
                style={[
                  textStyle,
                  this.state.focusTitle
                    ? ButtonStyle.selecttextAreaShadowStyle
                    : ButtonStyle.textAreaShadowStyle,
                  {
                    width: "100%",
                    marginTop: 5,
                    paddingHorizontal: 5,
                    paddingTop: 10,
                    marginBottom: 15,
                    height: Platform.OS == "ios" ? 100 : 40,
                    outline: "none",
                    textAlignVertical: "center",
                  },
                ]}
                onChangeText={(title) => {
                  this.setState({ title }),
                    this.props.postlink({
                      description: this.state.summary,
                      image: this.state.changeBackPicEnable.uri,
                      title: title,
                      url: this.state.url,
                      withoutUrl: this.props.link.withoutUrl,
                    });
                }}
                onFocus={() => this.setState({ focusTitle: true })}
                onBlur={() => this.setState({ focusTitle: false })}
              />
              <View style={{ flexDirection: "row", width: "100%", alignItems: "center" }}>
                <View
                  style={{
                    width: "70%",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      marginTop: 5,
                      marginRight: 20,
                      fontSize: 16,
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                  >
                    Summary
                  </Text>
                </View>
                <View
                  style={{
                    width: "30%",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      color:
                        this.state.summary.length < 50 ||
                          this.state.summary.length > 300
                          ? "#de5246"
                          : "#009B1A",
                      fontSize: 13,
                      fontFamily: ConstantFontFamily.defaultFont,
                      marginTop: 2,
                      marginRight: 3,
                    }}
                  >
                    {this.state.summary.length < 50
                      ? 50 - this.state.summary.length + " characters more"
                      : this.state.summary.length > 50 &&
                      300 - this.state.summary.length + " characters left"}
                  </Text>
                  {(this.state.summary.length < 50 ||
                    this.state.summary.length > 300) && (
                      <TouchableOpacity
                        onMouseEnter={() => this.setState({ summaryHover: true })}
                        onMouseLeave={() => this.setState({ summaryHover: false })}
                        style={{ marginBottom: 2 }}
                      >
                        <Icon
                          color={"#f80403"}
                          iconStyle={{
                            marginTop: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          name="times"
                          type="font-awesome"
                          size={16}
                        />
                      </TouchableOpacity>
                    )}
                  {this.state.summaryHover == true && Platform.OS == "web" ? (
                    <Tooltip
                      backgroundColor={"#d3d3d3"}
                      withPointer={false}
                      withOverlay={false}
                      toggleOnPress={true}
                      containerStyle={{
                        left: -150,
                        top: -50,
                      }}
                      popover={<Text>Between 50 to 300 Characters</Text>}
                    />
                  ) : null}
                </View>
              </View>
              <TextInput
                testID="summary1"
                value={this.state.summary}
                multiline={true}
                numberOfLines={5}
                maxLength={300}
                placeholder={
                  this.props.link.withoutUrl
                    ? "Summarize the context, and briefly describe its significance"
                    : "Enter a concise summary."
                }
                placeholderTextColor="#6D757F"
                style={[
                  textStyle,
                  this.state.focusSummary
                    ? ButtonStyle.selecttextAreaShadowStyle
                    : ButtonStyle.textAreaShadowStyle,
                  {
                    width: "100%",
                    marginTop: 5,
                    paddingHorizontal: 5,
                    marginBottom: 20,
                    paddingTop: 10,
                    height: Platform.OS == "ios" ? 100 : null,
                    outline: "none",
                  },
                ]}
                onChangeText={(summary) => {
                  this.props.postlink({
                    description: summary,
                    image: this.state.changeBackPicEnable.uri,
                    title: this.state.title,
                    url: this.state.url,
                    withoutUrl: this.props.link.withoutUrl,
                  });
                  this.setState({ summary });
                }}
                onFocus={() => this.setState({ focusSummary: true })}
                onBlur={() => this.setState({ focusSummary: false })}
              />


              <View style={{ width: "100%" }}>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      borderRadius: Dimensions.get("window").width >= 750 ? 5 : 0,
                    }}
                  >


                    <ShareToComponent type="createPost" textFocus={this.textFocus}
                      handleUserSelectInput={this.handleUserSelectInput}
                      handleClikDelete={this.handleClikDelete}
                      handleClikSelectInput={this.handleClikSelectInput}
                      handleTopicSelectInput={this.handleTopicSelectInput}
                      handleTopicDelete={this.handleTopicDelete}
                      handleUserDelete={this.handleUserDelete}
                    />


                  </View>
                </View>
              </View>

              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {this.state.loading == true && (
                  <ActivityIndicator animating size="large" color="#000" />
                )}
                <Button
                  testID="SUBMIT1"
                  title="SUBMIT"
                  titleStyle={ButtonStyle.allButtonTextStyle}
                  buttonStyle={[ButtonStyle.allButtonbackgroundStyle, {
                    borderColor: this.state.title.length < 10 ||
                      this.state.title.length > 150 ||
                      this.state.summary.length < 50 ||
                      this.state.summary.length > 300 ? '#d3d3d3' : Colors.blueColor
                  }]}
                  containerStyle={ButtonStyle.containerStyle}
                  disabled={
                    this.state.title.length < 10 ||
                      this.state.title.length > 150 ||
                      this.state.summary.length < 50 ||
                      this.state.summary.length > 300
                      ? true
                      : false
                  }
                  onPress={this.onSave}
                />
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  link: state.LinkPostReducer.link,
  getsearchBarStatus: state.AdminTrueFalseReducer.searchBarOpenStatus,
  windowSize: state.windowResizeReducer.windowResize,
});

const mapDispatchToProps = (dispatch) => ({
  postlink: (payload) => dispatch(postLink(payload)),
  // setPostDetails: (payload) => dispatch(setPostDetails(payload)),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
  // feedFluctuation: (payload) => dispatch(feedFluctuation(payload)),
  // screen: (payload) => dispatch(screen(payload))

});

// const CreatePostScreenContainerWrapper = graphql(PostCreateMutation, {
//   name: "PostCreate",
// })(CreatePostScreen);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreatePostScreen
);

const styles = StyleSheet.create({
  usertext: {
    color: "#000",
    fontSize: 14,
    fontFamily: ConstantFontFamily.defaultFont,
  },
});
