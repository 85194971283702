import React, { Component, lazy, Suspense } from "react";
import {
  Animated,
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Image
} from "react-native";
import { Icon } from "react-native-elements";
// import { TabBar } from "react-native-tab-view";
import { connect } from "react-redux";
import { compose } from "react-recompose";
// import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
// import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import { setPostDetails } from "../actionCreator/PostDetailsAction";
import appolloClient from "../client";
import ShadowSkeleton from "../components/ShadowSkeleton";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import {
  AdminPostQuery,
  PostQuery,
} from "../graphqlSchema/PostMutation";
import {
  SearchClikMutation,
  SearchFeedMutation,
  SearchTopicMutation,
  SearchUserMutation,
  SearchEveryThingMutation,
} from "../graphqlSchema/SearchMutation";
import { PostQueryVariables } from "../graphqlSchema/PostVariables";
import {
  SearchEveryVariables,
  SearchClikVariables,
  SearchFeedVariables,
  SearchTopicVariables,
  SearchUserVariables,
} from "../graphqlSchema/SearchVariables";
// import { capitalizeFirstLetter } from "../library/Helper";
import CreateCommentCard from '../components/CreateCommentCard'

import ButtonStyle from "../constants/ButtonStyle";
// import NavigationService from "../library/NavigationService";
// import ShadowSkeletonComment from "../components/ShadowSkeletonComment";
// import FeedImageDisplayUser from "../components/FeedImageDisplayUser";
// import ShadowSkeletonPostDetails from "../components/ShadowSkeletonPostDetails";

// const PostDetailsCard = lazy(() => import("../components/PostDetailsCard"))
import PostDetailsCard from "../components/PostDetailsCard"
// import HeaderRight from "../components/HeaderRight";
// import { showComment } from '../reducers/ShowCommentReducer';
import { screen } from "../reducers/ScreenNameReducer";
// const HeaderRight = lazy(() => import("../components/HeaderRight"))
import { searchOpenBarStatus } from '../reducers/AdminTrueFalseReducer';

// import CommentDetailScreen from './CommentDetailScreen';
// const CommentDetailScreen = lazy(() => import("./CommentDetailScreen"))

// const BottomScreenForDiscussion = lazy(() => import("../components/BottomScreenForDiscussion"))

// const RelatedFeed = lazy(() => import("../components/RelatedFeed"))

// const CreateCommentCard = lazy(() => import("../components/CreateCommentCard"))
import PostDetailsComment from "../components/PostDetailsComment"
// const SEOMetaData = lazy(() => import("../components/SEOMetaData"))

const initRoutes = [
  { key: "first", title: "Trending" },
  { key: "second", title: "New" },
  { key: "third", title: "Bookmarks" },
];
var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
var wheelOpt = { passive: false };
var wheelEvent =
  Platform.OS == "web" ? "onwheel" in document.createElement("div") ? "wheel" : "mousewheel" : null;

var timer = null;
const $body = Platform.OS == "web" && document.querySelector("body");
class PostDetailsScreen extends Component {
  constructor(props) {
    super(props);
    this.timer;
    this.Pagescrollview = null;
    this.state = {
      modalVisible: false,
      items: [],
      showsVerticalScrollIndicatorView: false,
      currentScreentWidth: 0,
      mainCommentShow: this.props.setComment,
      postId: "",
      routes: [...initRoutes],
      index: 0,
      term: "",
      TopicSuggestList: [],
      ClikSuggestList: [],
      UserSuggestList: [],
      FeedSuggestList: [],
      scrollY: 0,
      postDetailsViewHeight: 0,
      topDownNavTrack: {},
      gestureStatus: false,
      feedY: 0,
      isLoading: false,
      openCommentId: ""
    };
  }


  openCommentIdd = (id) => {
    this.setState({ openCommentId: id })
    // this.props.showComment(true)
    this.props.navigation.navigate("comment")
  }

  preventDefault(e) {
    if (e.cancelable) {
      e.preventDefault();
    }
  }

  preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      this.preventDefault(e);
      return false;
    }
  }

  disableScroll = () => {
    document.addEventListener(
      "DOMMouseScroll",
      this.preventDefaultForScrollKeys,
      { passive: false },
      false
    );
    document.addEventListener(wheelEvent, this.preventDefaultForScrollKeys, {
      passive: false,
    });
    document.addEventListener("touchmove", this.preventDefaultForScrollKeys, {
      passive: false,
    });
    document.addEventListener(
      "keydown",
      this.preventDefaultForScrollKeys,
      false,
      { passive: false }
    );


  };

  enableScroll = () => {
    document.removeEventListener("DOMMouseScroll", this.preventDefault, false);
    document.removeEventListener(wheelEvent, this.preventDefault, wheelOpt);
    document.removeEventListener("touchmove", this.preventDefault);
    document.removeEventListener(
      "keydown",
      this.preventDefaultForScrollKeys,
      false
    );


  };
  handalListMode = async (position, data) => {
    await this.props.setPostCommentDetails({
      id: this.state.postId.replace("%3A", ":"),
    });
  };

  setNewBorderColor = async (selectComment, isNavTo) => {
    const prevId = this.props.selectComment.prevId;
    setTimeout(async () => {
      let prevElementOfView = document.getElementById(prevId);
      let slectElementOfView = document.getElementById(selectComment);
      let elementOfTouchableOpacity = document.getElementById(
        "Comment:" + selectComment
      );
      if (slectElementOfView) {
        if (prevElementOfView) prevElementOfView.style.borderColor = "white";
        slectElementOfView.style.borderColor = "red";
        let prevPosition = prevElementOfView
          ? prevElementOfView.getBoundingClientRect().top
          : 0;
        let currentPosition = slectElementOfView.getBoundingClientRect().top;
        if (prevPosition != currentPosition) {
          slectElementOfView.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
        if (elementOfTouchableOpacity) {
          elementOfTouchableOpacity.focus();
          elementOfTouchableOpacity.style.outline = "none";
        }
      }
    }, 0);
  };
  getFlatCommentList = (items) => {
    let item, output, clone;
    output = [];
    for (item of items) {
      clone = [
        ...output,
        { ...item },
        [...this.getFlatCommentList(item.comments.edges)],
      ];
      output = clone;
    }
    return output;
  };


  handleKeyup = (e) => {
    if (e.target.tagName !== "TEXTAREA") {
      const commentList = this.getFlatCommentList(
        this.props.PostCommentDetails
      ).flat(Infinity);
      let comment = commentList.find(
        (comment) =>
          "Comment:" + this.props.selectComment.currentId == comment.node.id
      );
      if ((event.keyCode == 32 || event.keyCode == 83) && !comment) {
        this.setState({ isStartNav: true });
        this.props.setActiveId(commentList[0].node.id.replace("Comment:", ""));
        this.setNewBorderColor(commentList[0].node.id.replace("Comment:", ""));
      }
      if (
        [39, 68, 37, 65, 83, 40, 87, 38].indexOf(event.keyCode) !== -1 &&
        this.props.selectComment.currentId
      ) {
        let nextSelectId = null;
        let isNavTo = false;
        if (comment) {
          let currentActiveElement = document.getElementById(
            this.props.selectComment.currentId
          );
          if (event.keyCode == 39 || event.keyCode == 68) {
            nextSelectId = comment.right;
            if (currentActiveElement) {
              currentActiveElement.setAttribute("navTo", "right");
              currentActiveElement.click();
              isNavTo = true;
            }
          }
          if (event.keyCode == 37 || event.keyCode == 65) {
            nextSelectId = comment.left;
            if (currentActiveElement) {
              currentActiveElement.setAttribute("navTo", "left");
              currentActiveElement.click();
              isNavTo = true;
            }
          }
          if (event.keyCode == 83 || event.keyCode == 40) {
            if (
              this.state.topDownNavTrack[this.props.selectComment.currentId] &&
              this.state.topDownNavTrack[this.props.selectComment.currentId]
                .down
            )
              nextSelectId =
                this.state.topDownNavTrack[this.props.selectComment.currentId]
                  .down;
            else if (comment.down) {
              nextSelectId = comment.down;
              this.setState((prevState) => {
                return {
                  topDownNavTrack: {
                    ...prevState.topDownNavTrack,
                    [nextSelectId.replace("Comment:", "")]: {
                      top: this.props.selectComment.currentId,
                    },
                  },
                };
              });
              isNavTo = false;
            }
          }
          if (event.keyCode == 87 || event.keyCode == 38) {
            if (comment.up) {
              nextSelectId = comment.up;
              this.setState((prevState) => {
                let setKeyObj = prevState.topDownNavTrack[
                  nextSelectId.replace("Comment:", "")
                ]
                  ? {
                    ...prevState.topDownNavTrack[
                    nextSelectId.replace("Comment:", "")
                    ],
                    down: this.props.selectComment.currentId,
                  }
                  : { down: this.props.selectComment.currentId };
                return {
                  topDownNavTrack: {
                    ...prevState.topDownNavTrack,
                    [nextSelectId.replace("Comment:", "")]: setKeyObj,
                  },
                };
              });
            } else if (
              this.state.topDownNavTrack[this.props.selectComment.currentId] &&
              this.state.topDownNavTrack[this.props.selectComment.currentId].top
            )
              nextSelectId =
                this.state.topDownNavTrack[this.props.selectComment.currentId]
                  .top;
            isNavTo = false;
          }

          if (nextSelectId && nextSelectId != "null") {
            this.props.setActiveId(nextSelectId.replace("Comment:", ""));
            this.setNewBorderColor(
              nextSelectId.replace("Comment:", ""),
              isNavTo
            );
          }
        }
      }
    }
  };

  async componentDidMount() {
    // console.log(this.props.PostDetails,'this.props.PostDetails');
    // this.props.screen("postDetail")
    await this.props.searchOpenBarStatus(false);
    if (this.props.PostDetails != null) {
      this.getRespectiveSuggestions(
        this.props.PostDetails && this.props.PostDetails.node.title
      );
      this.getSuggestion(
        this.props.PostDetails && this.props.PostDetails.node.title
      );
    }

    this.props.setActiveId("");
    this.roleWiseTabUpdate();
    // this.props.openCreateComment(false);
    // const { navigation } = this.props;
    // const itemId = "Post:" + navigation.getParam("id", "NO-ID");
    // const itemtitle = "Post:" + navigation.getParam("title", "NO-ID");


    // await this.goToPostDetailsScreen(itemId.replace("%3A", ":"));
    this.timer = setTimeout(() => {
      this.setState({
        favColor: "red",
      });
    }, 1000);

  }

  setGestureState = (event) => {
    window.addEventListener("touchstart", this.touchStart, { passive: false });
    window.addEventListener("touchmove", this.preventTouch, { passive: false });
  };

  componentWillUnmount() {
    window.removeEventListener("touchstart", (e) => this.touchStart(e));
    window.removeEventListener("touchmove", (e) => this.preventTouch(e), {
      passive: false,
    });
  }

  touchStart = (e) => {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  };

  preventTouch = (e) => {
    const minValue = 5;
    this.clientX = e.touches[0].clientX;
    this.clientY = e.touches[0].clientY;
    Math.abs(this.clientX) > minValue;
  };

  roleWiseTabUpdate = () => {
    if (this.props.isAdmin && this.props.isAdminView) {
      this.setState({
        routes: [{ key: "zero", title: "Reported" }, ...initRoutes],
      });
    } else if (this.state.routes.length === 4) {
      this.setState({ routes: initRoutes });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timer);
    if (Platform.OS == "web" && Dimensions.get('window') > 750) {
      document.body.removeEventListener("keyup", this.handleKeyup);
    }
  }

  goToPostDetailsScreen = async (id) => {
    let __self = this;
    PostQueryVariables.variables.id = id;
    PostQueryVariables.variables.first = 3;
    appolloClient
      .query({
        query: this.props.isAdmin == true ? AdminPostQuery : PostQuery,
        ...PostQueryVariables,
        fetchPolicy: "cache-first",
      })
      .then(async (res) => {
        let title = "Home";
        if (__self.props.navigation.state.routeName == "profile") {
          title = "@" + res.data.post.author.username;
        } else if (__self.props.navigation.state.routeName == "cliksprofile") {
          title = "#" + res.data.post.cliks[0];
        } else {
          title = "Home";
        }
        await __self.props.setPostDetails({
          title: title,
          id: id,
          navigate: true,
        });
        await __self.props.setPostCommentDetails({
          id: id,
          title: res.data.post.title,
          loading: true,
        });
        return true;
      })
      .then(async (res) => {
        if (res) {
          await this.getTrandingCliks();
        }
      });
  };

  getTrandingCliks = async () => {
    let data = [
      {
        label: "Everyone",
        value: "",
        key: null,
      },
    ];
    if (this.props.PostDetails) {
      if (this.props?.PostDetails?.node?.cliks != null) {
        await this.props?.PostDetails?.node?.cliks.map((item, index) => {
          data.push({
            label: "#" + item,
            value: item,
            key: index,
          });
        });
      }
    }
    await this.setState({
      items: data,
    });
  };

  onClose = () => {
    this.setState({
      modalVisible: false,
    });
  };

  clickEvent = () => {
    this.setState({
      mainCommentShow: !this.state.mainCommentShow,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.getHasScrollTop == true && this.Pagescrollview) {
      this.Pagescrollview.scrollTo({ x: 0, y: 0, animated: true });
      this.props.setHASSCROLLEDACTION(false);
    }
    if (prevProps.isAdminView !== this.props.isAdminView) {
      this.roleWiseTabUpdate();
    }
  }

  onClose = () => {
    this.setState({
      mainCommentShow: !this.state.mainCommentShow,
    });
  };

  listScroll = (value) => {
    this.setState({
      feedY: value,
    });
  };

  getRespectiveSuggestions = async (value) => {
    var TotalTerm = value.split(" ");
    for (var i = 0; i < TotalTerm.length; i++) {
      if (TotalTerm[i].startsWith("#") == true || this.state.index == 2) {
        await this.setState({
          TopicSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
        await this.getClikSuggestion(TotalTerm[i].replace("#", ""));
      } else if (
        TotalTerm[i].startsWith("/") == true ||
        this.state.index == 1
      ) {
        await this.setState({
          ClikSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
        await this.getTopicSuggestion(TotalTerm[i].replace("/", ""));
      } else if (
        TotalTerm[i].startsWith("@") == true ||
        this.state.index == 3
      ) {
        await this.setState({
          TopicSuggestList: [],
          ClikSuggestList: [],
          FeedSuggestList: [],
        });
        await this.getUserSuggestion(TotalTerm[i].replace("@", ""));
      } else if (
        TotalTerm[i].startsWith("%") == true ||
        this.state.index == 4
      ) {
        await this.setState({
          TopicSuggestList: [],
          ClikSuggestList: [],
          UserSuggestList: [],
        });
        await this.getFeedSuggestion(TotalTerm[i].replace("%", ""));
      } else {
        await this.setState({
          TopicSuggestList: [],
          ClikSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
      }
    }
  };

  getTopicSuggestion = (value) => {
    SearchTopicVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchTopicMutation,
        ...SearchTopicVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.topics;
        this.setState({
          TopicSuggestList: data,
          ClikSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  getClikSuggestion = (value) => {
    SearchClikVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchClikMutation,
        ...SearchClikVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.cliks;
        this.setState({
          ClikSuggestList: data,
          TopicSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  getUserSuggestion = (value) => {
    SearchUserVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchUserMutation,
        ...SearchUserVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.users;
        this.setState({
          UserSuggestList: data,
          TopicSuggestList: [],
          ClikSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  getFeedSuggestion = (value) => {
    SearchFeedVariables.variables.prefix = value.replace("-", " ");
    appolloClient
      .query({
        query: SearchFeedMutation,
        ...SearchFeedVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.feeds;
        this.setState({
          FeedSuggestList: data,
          TopicSuggestList: [],
          ClikSuggestList: [],
          UserSuggestList: [],
        });
      });
  };


  getSuggestion = async (value) => {
    this.setState({ isLoading: true });
    let topics = [];
    let cliks = [];
    let users = [];
    let feeds = [];
    let terms = [];
    var TotalTerm = value;

    for (var i = 0; i < TotalTerm.length; i++) {
      if (TotalTerm[i].startsWith("#") == true) {
        cliks.push(TotalTerm[i].replace("#", ""));
      } else if (TotalTerm[i].startsWith("/") == true) {
        topics.push(TotalTerm[i].replace("/", ""));
      } else if (TotalTerm[i].startsWith("@") == true) {
        users.push(TotalTerm[i].replace("@", ""));
      } else if (TotalTerm[i].startsWith("%") == true) {
        feeds.push(TotalTerm[i].replace("%", "").replace("-", " "));
      } else {
        terms.push(TotalTerm[i]);
      }
    }
    SearchEveryVariables.variables.topics = topics;
    SearchEveryVariables.variables.cliks = cliks;
    SearchEveryVariables.variables.users = users;
    SearchEveryVariables.variables.feeds = feeds;
    SearchEveryVariables.variables.terms = terms;
    await appolloClient
      .query({
        query: SearchEveryThingMutation,
        ...SearchEveryVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        this.setState({
          showTips: false,
          ClikList: res.data.search.everything.cliks,
          PostList: res.data.search.everything.posts,
          TopicList: res.data.search.everything.topics,
          UserList: res.data.search.everything.users,
          FeedList: res.data.search.everything.feeds,
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  _renderItem = (item) => {
    var row = item.item;

    {
      this.state.PostList &&
        this.state.PostList.length > 0 &&
        this.state.PostList.map((dataitem, index) => (
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              backgroundColor: "red",
            }}
          >
            {Dimensions.get("window").width >= 750 && (
              <View
                style={{
                  width:
                    dataitem.reasons_shared != null &&
                      dataitem.reasons_shared.users.length > 0
                      ? "15%"
                      : "0%",
                }}
              >
                {/* {dataitem.reasons_shared && (
                  <FeedImageDisplayUser
                    height={summaryHeight}
                    item={dataitem.reasons_shared.users}
                    currentusername={
                      dataitem.author && dataitem.author.username
                    }
                  />
                )} */}
              </View>
            )}
            <TouchableOpacity
              style={{
                width:
                  dataitem.reasons_shared &&
                    dataitem.reasons_shared.users.length > 0
                    ? "85%"
                    : "100%",
                paddingLeft: 5,
              }}
            >
              {Dimensions.get("window").width >= 750 &&
                this.props.PostId &&
                this.props.PostId.replace("Post:", "") ==
                dataitem.id.replace("Post:", "") && (
                  <TextInput
                    value={
                      dataitem.summary != null
                        ? dataitem.summary.length > 300
                          ? dataitem.summary.substring(0, 297) + "..."
                          : dataitem.summary
                        : null
                    }
                    readOnly={false}
                    style={{
                      color: "#000",
                      paddingHorizontal: 5,
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 15,
                      lineHeight: 20,
                      height: summaryHeight,
                      paddingBottom: 10,
                      overflow: "hidden",
                    }}
                    scrollEnabled={false}
                    multiline={true}
                  />
                )}
            </TouchableOpacity>
          </View>
        ));
    }
  };

  toggleTooltip = (stateName) => {
    this.setState((prevState) => ({ [stateName]: !prevState[stateName] }));
  };

 

  stopScrolling = (prvntScroll) => {
    if (prvntScroll) {
      this.disableScroll();
    } else {
      this.enableScroll();
    }
  };
  _handleIndexChange = (index) => this.setState({ index });
  _renderScene = ({ route }) => {
    switch (route.key) {
      case "third":
        return (
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ marginTop: 10 }}
          >
            {this.props.showComment1 == false ?
              <Suspense fallback={null}>

                {/* <PostDetailsComment
                  item={this.props.PostCommentDetails}
                  navigation={this.props.navigation}
                  closeModalhandalListMode={this.handalListMode}
                  clickList={
                    this.props.PostCommentDetails
                      ? this.props.PostCommentDetails
                      : null
                  }
                  PostId={
                    this.props.PostDetails ? this.props?.PostDetails?.node?.id : null
                  }
                  openCommentIdd={this.openCommentIdd}
                /> */}
              </Suspense> : null}
            <View style={{ height: 100 }}></View>
            {this.props.PostCommentDetails.length == 0 &&  (
              <View
                style={{
                  flexDirection: "column",
                  height: 100,
                }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="comments"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    No discussions
                  </Text>
                </View>
              </View>
            )}
          </ScrollView>
        );
      case "second":
        return (
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ marginTop: 10 }}
          >
            {this.props.showComment1 == false ?
              <Suspense fallback={null}>

                {/* appolloClient
                  item={this.props.PostCommentDetails}
                  navigation={this.props.navigation}
                  closeModalhandalListMode={this.handalListMode}
                  clickList={
                    this.props.PostCommentDetails
                      ? this.props.PostCommentDetails
                      : null
                  }
                  PostId={
                    this.props.PostDetails ? this.props?.PostDetails?.node?.id : null
                  }
                  openCommentIdd={this.openCommentIdd}
                /> */}
              </Suspense> : null}
            <View style={{ height: 100 }}></View>
            {this.props.PostCommentDetails.length == 0 &&  (
              <View
                style={{
                  flexDirection: "column",
                  height: 100,
                }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="comments"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    No discussions
                  </Text>
                </View>
              </View>
            )}
          </ScrollView>
        );
      default:
        return (
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
              marginTop: 10,
              height: Dimensions.get("window").height - 200,
            }}
          >
            {this.props.showComment1 == false &&
              <Suspense fallback={null}>

                {/* <PostDetailsComment
                  item={this.props.PostCommentDetails}
                  navigation={this.props.navigation}
                  closeModalhandalListMode={this.handalListMode}
                  clickList={
                    this.props.PostCommentDetails
                      ? this.props.PostCommentDetails
                      : null
                  }
                  PostId={
                    this.props.PostDetails ? this.props?.PostDetails?.node?.id : null
                  }
                  openCommentIdd={this.openCommentIdd}
                /> */}

              </Suspense>}
            <View style={{ height: 100 }}></View>

            {this.props.PostCommentDetails.length == 0 &&  (
              <View
                style={{
                  flexDirection: "column",
                  height: 100,
                }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="comments"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    No discussions
                  </Text>
                </View>
              </View>
            )}
          </ScrollView>
        );
    }
  };

  onCloseCreateComment = () => {
    this.props.openCreateComment(false);
  };
  render() {
   
    return (
      <View style={styles.container}>
        {Platform.OS == "web" && (
          <Suspense fallback={null}>
            
          </Suspense>
        )}
        {this.state.term != "" && (
          <View
            style={{
              zIndex: 5,
              alignSelf: "flex-start",
              width: "31.6%",
              marginRight: 20,
              position: "absolute",
              backgroundColor: "#FEFEFA",
              top: 60,
              right: "2.1%",
              padding:
                this.state.TopicSuggestList.length > 0 ||
                  this.state.ClikSuggestList.length > 0 ||
                  this.state.UserSuggestList.length > 0 ||
                  this.state.FeedSuggestList.length > 0
                  ? 10
                  : 0,
              borderWidth:
                this.state.TopicSuggestList.length > 0 ||
                  this.state.ClikSuggestList.length > 0 ||
                  this.state.UserSuggestList.length > 0 ||
                  this.state.FeedSuggestList.length > 0
                  ? 1
                  : 0,
              borderColor: "#000",
              borderBottomLeftRadius: 6,
              borderBottomRightRadius: 6,
            }}
          >
            {this.state.TopicSuggestList.map((item, index) => {
              return (
                <View
                  key={item.name}
                  style={{
                    backgroundColor: "#FEFEFA",
                    width: "95%",
                    padding: 5,
                    justifyContent: "space-between",
                    alignSelf: "flex-start",
                    flexDirection: "row",
                    height: 30,
                  }}
                >
                  <View
                    style={{
                      padding: 5,
                      backgroundColor: item.parents ? "#e3f9d5" : "#e3f9d5",
                      borderRadius: 6,
                      alignSelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: item.parents ? "#009B1A" : "#009B1A",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontWeight: "bold",
                      }}
                    >
                      /{item.name.toLowerCase()}
                    </Text>
                  </View>
                </View>
              );
            })}

            {this.state.ClikSuggestList.map((item, index) => {
              return (
                <View
                  key={item.name}
                  style={{
                    backgroundColor: "#FEFEFA",
                    width: "95%",
                    padding: 5,
                    justifyContent: "space-between",
                    alignSelf: "flex-start",
                    flexDirection: "row",
                    height: 30,
                  }}
                >
                  <View
                    style={{
                      padding: 5,
                      backgroundColor: "#E8F5FA",
                      borderRadius: 6,
                      alignSelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: "#4169e1",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontWeight: "bold",
                      }}
                    >
                      #{item.name}
                    </Text>
                  </View>
                </View>
              );
            })}

            {this.state.UserSuggestList.map((item, index) => {
              return (
                <View
                  key={item.username}
                  style={{
                    backgroundColor: "#FEFEFA",
                    width: "95%",
                    padding: 5,
                    justifyContent: "space-between",
                    alignSelf: "flex-start",
                    flexDirection: "row",
                    height: 30,
                  }}
                >
                  <View
                    style={{
                      padding: 5,
                      backgroundColor: "#E8F5FA",
                      borderRadius: 6,
                      alignSelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: "#000",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontWeight: "bold",
                      }}
                    >
                      @{item.username}
                    </Text>
                  </View>
                </View>
              );
            })}

            {this.state.FeedSuggestList.map((item, index) => {
              return (
                <View
                  key={item.id}
                  style={{
                    backgroundColor: "#FEFEFA",
                    width: "95%",
                    padding: 5,
                    justifyContent: "space-between",
                    alignSelf: "flex-start",
                    flexDirection: "row",
                    height: 30,
                  }}
                >
                  <View style={{ flexDirection: "row", alignSelf: "center" }}>
                    <Text
                      style={{
                        alignSelf: "center",
                        textAlign: "center",
                        color: "#000",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontWeight: "bold",
                        marginLeft: 10,
                      }}
                    >
                      {item.name}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        )}
        {
          Dimensions.get("window").width <= 750 ? (
            <Animated.View
              style={{
                left: 0,
                right: 0,
                zIndex: 10,
                overflow: "hidden",
                borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,

              }}
            >
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    height: 50,
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom:
                      Dimensions.get("window").width <= 750 ? 0 : 10,
                    backgroundColor: "#000",
                    borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
                  }}
                >
                  <TouchableOpacity
                    style={[
                      ButtonStyle.headerBackStyle,
                      {
                        width: !this.props.getsearchBarStatus ? "5%" : 0,
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignSelf: "center",
                      },
                    ]}
                    onPress={() => {
                      this.props.screen('home')
                      this.props.navigation.goBack();
                      
                    }}
                  >
                    <Icon
                      color={"#fff"}
                      name="angle-left"
                      type="font-awesome"
                      size={40}
                    />
                  </TouchableOpacity>
                  {!this.props.getsearchBarStatus && (
                    <TouchableOpacity
                      style={{
                        flex: this.props.loginStatus
                          ? 1
                          : !this.props.loginStatus && Platform.OS == "web"
                            ? 1
                            : !this.props.loginStatus &&
                            Platform.OS != "web" &&
                            0,
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          color: "#fff",
                          fontWeight: "bold",
                          fontSize: 18,
                          fontFamily: ConstantFontFamily.defaultFont,
                        }}
                      >
                        Discussions
                      </Text>
                    </TouchableOpacity>
                  )}
                 
                </View>
              </View>
            </Animated.View>
          ) : null
        }
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <ScrollView
            ref={(scrollview) => {
              this.Pagescrollview = scrollview;
            }}
            showsVerticalScrollIndicator={false}
            onLayout={(event) => {
              let { x, y, width, height } = event.nativeEvent.layout;
              if (width < 1024) {
                this.setState({
                  showsVerticalScrollIndicatorView: true,
                  currentScreentWidth: width,
                });
              } else {
                this.setState({
                  showsVerticalScrollIndicatorView: false,
                  currentScreentWidth: width,
                });
              }
            }}
            style={{ flex: 1 }}
            contentContainerStyle={[
              {
                width:
                  Platform.OS == "web" &&
                    Dimensions.get("window").width >= 750 &&
                    Dimensions.get("window").width <= 1200
                    ? "100%"
                    : Platform.OS == "web" &&
                      Dimensions.get("window").width >= 1200
                      ? "100%"
                      : "100%",
                height: "100%",
              },
            ]}
            onScroll={(event) => {
              this.setState({
                scrollY: event.nativeEvent.contentOffset.y,
              });
              if (timer !== null) {
                clearTimeout(timer);
                this.setState({ gestureStatus: false });
              }
              let _self = this;
              timer = setTimeout(function () {
                _self.setState({ gestureStatus: true });
              }, 150);
            }}
            scrollEventThrottle={16}
          >
            <View
              style={{
                borderRadius: 6,
                width: "100%",
              }}
              onLayout={(event) => {
                let { x, y, width, height } = event.nativeEvent.layout;
                if (width > 0) {
                  this.setState({ postDetailsViewHeight: height });
                }
              }}
            >

             
              <PostDetailsCard
                item={this.props.PostDetails}
                closeModalhandalListMode={this.handalListMode}
                navigation={this.props.navigation}
                clickEvent={this.clickEvent}
                modalStatus={this.state.mainCommentShow}
                openCommentIdd={this.openCommentIdd}

              />
              



              {Dimensions.get("window").width >= 750 && (
                <>
                  <View
                    style={{
                      height: 80,
                      justifyContent: "center",
                      borderBottomWidth: 1,
                      borderColor: "#d7d7d7",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 16,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Related Posts
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      height: Dimensions.get("window").height - 250,
                      paddingBottom: 10,
                    }}
                  >
                    {this.state.isLoading ? (
                      <ActivityIndicator
                        size="large"
                        animating
                        color="#000"
                      />
                    ) : this.state.PostList ? (
                      <ScrollView
                        showsVerticalScrollIndicator={false}
                        style={{ marginTop: 0, paddingBottom: 100 }}
                      >
                       
                        <Text
                          style={[
                            ButtonStyle.simpleTextStyle,
                            { textAlign: "center", padding: 5 },
                          ]}
                        >
                          No more posts
                        </Text>
                      </ScrollView>
                    ) : (
                      <Text
                        style={[
                          ButtonStyle.simpleTextStyle,
                          { textAlign: "center", padding: 5 },
                        ]}
                      >
                        No Related Post
                      </Text>
                    )}
                  </View>
                </>
              )}


          

            </View>



         



            <ScrollView
              removeClippedSubview={false}
              showsVerticalScrollIndicator={false}
              style={{
                display:
                  Dimensions.get("window").width <= 750 ? "flex" : "none",
                paddingHorizontal: 0,
                paddingTop: Dimensions.get("window").width <= 750 ? 0 : 10,
                paddingBottom: 100
              }}
            >

                <View>
                  <TouchableOpacity style={[
                    {
                      backgroundColor: "#fff",
                      flexDirection: 'row',
                      borderRadius: 15,
                      borderColor: "#d3d3d3",
                      width: '95%',
                      marginLeft: '2%',
                      borderWidth: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 100

                      , justifyContent: "center", alignItems: "center",
                    }]} onPress={() => this.openCommentIdd(this.props.PostId)}>
                    <Text style={{ color: "gray", fontSize: 18, textAlign: "center", fontWeight: "bold", marginRight: 5 }}>Click here to start a new discussion topic</Text>
                    <Image source={require("../assets/image/lightning.png")} style={{ height: 30, width: 30, }} />
                  </TouchableOpacity>

                  {/* <PostDetailsComment
                    gestureStatus={this.state.gestureStatus}
                    right={false}
                    item={
                      this.props.PostCommentDetails.length > 0
                        ? this.props.PostCommentDetails
                        : []
                    }
                    navigation={this.props.navigation}
                    closeModalhandalListMode={this.handalListMode}
                    clickList={
                      this.props.PostCommentDetails
                        ? this.props.PostCommentDetails
                        : null
                    }
                    PostId={
                      this.props.PostDetails ? this.props?.PostDetails?.node?.id : null
                    }
                    stopScrolling={this.stopScrolling}
                    openCommentIdd={this.openCommentIdd}
                  /> */}

                </View>

              <View style={{ height: 100 }}></View>
             
              <View
                style={{
                  flexDirection: "column",
                  height: 100,
                }}
              >

                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="comments"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,

                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    No Discussions
                  </Text>
                </View>
              </View>
             
            </ScrollView>

          </ScrollView>
          <View
            style={[
              {
                display:
                  Dimensions.get("window").width >= 1200 && Platform.OS == "web"
                    ? "flex"
                    : "none",
                width:
                  Platform.OS == "web" &&
                    Dimensions.get("window").width >= 750 &&
                    Dimensions.get("window").width <= 1200
                    ? "50%"
                    : Platform.OS == "web" &&
                      Dimensions.get("window").width >= 1200
                      ? "50%"
                      : "100%",
                marginLeft: 10,
                marginBottom: 5,
                marginRight:
                  Dimensions.get("window").width >= 750 &&
                    Dimensions.get("window").width <= 1200
                    ? 5
                    : 15,
                display:
                  Dimensions.get("window").width >= 750 && Platform.OS == "web"
                    ? "flex"
                    : "none",
                marginTop: 0,
              },
            ]}
          >

            

          </View>
        </View>
       
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  PostDetails: state.PostDetailsReducer.PostDetails,
  getHasScrollTop: state.HasScrolledReducer.hasScrollTop,
  loginStatus: state.UserReducer.loginStatus,
  PostCommentDetails: state.PostCommentDetailsReducer.PostCommentDetails,
  isAdmin: state.AdminTrueFalseReducer.isAdmin,
  isAdminView: state.AdminTrueFalseReducer.isAdminView,
  selectComment: state.PostCommentDetailsReducer.selectComment,
  setComment: state.PostDetailsReducer.setComment,
  PostId: state.PostCommentDetailsReducer.PostId,
  getCreateCommentModalStatus: state.AdminTrueFalseReducer.openCreateCommentModal,
  // getShareLinkModalStatus: state.ShareLinkModalReducer.modalStatus,
  // getsearchBarStatus: state.AdminTrueFalseReducer.searchBarOpenStatus,
  // Loading: state.PostCommentDetailsReducer.Loading,
  // getShowCommentValue: state.AdminTrueFalseReducer.showComment,
  showComment1: state.ShowCommentReducer.showComment
});

const mapDispatchToProps = (dispatch) => ({
  screen: (payload) => dispatch(screen(payload)),
  searchOpenBarStatus: (payload) => dispatch(searchOpenBarStatus(payload)),

  // setHASSCROLLEDACTION: (payload) => dispatch(setHASSCROLLEDACTION(payload)),
  // setPostDetails: (payload) => dispatch(setPostDetails(payload)),
  // setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
  setActiveId: (payload) => dispatch({ type: "SET_ACTIVE_ID", payload }),
  // openCreateComment: (payload) =>
  //   dispatch({ type: "OPEN_CREATE_COMMENT", payload }),
  // searchOpenBarStatus: (payload) =>
  //   dispatch({ type: "SEARCHBAR_STATUS", payload }),
  // showComment: (payload) => dispatch({ type: "SHOW_COMMENT", payload }),
  // screen: (payload) => dispatch(screen(payload)),
  // showComment: (payload) => dispatch(showComment(payload)),


});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PostDetailsScreen
);

const styles = StyleSheet.create({
  header: {
    position: Platform.OS == "web" ? "fixed" : null,
    left: 0,
    right: 0,
    zIndex: 10,
    overflow: "hidden",
  },
  container: {
    backgroundColor: ConstantColors.customeBackgroundColor,
    height: "100%",
  },
});
