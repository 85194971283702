import React from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  View,

  TouchableOpacity,
  Image,
  Dimensions
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import ConstantFontFamily from "../constants/FontFamily";
// import RNPickerSelect from "react-native-picker-select";
// import ButtonStyle from "../constants/ButtonStyle";
import { ClikGenerateKeyVariables } from "../graphqlSchema/FollowandUnfollowVariables";
import applloClient from "../client";
import { ClikGenerateKeyMutation } from "../graphqlSchema/FollowandUnFollowMutation";
// import { Hoverable } from "react-native-web-hooks";
// import "firebase/auth";
import axios from 'axios';
// import FilterResults from 'react-filter-search';
import { screen } from '../reducers/action/Completed'
import * as Clipboard from 'expo-clipboard';




class InviteFriend extends React.Component {
  constructor(props) {
    super(props);
    this.inputRefs = {};
    this.state = {
      showPrivacyPolicyModal: false,
      RoleItems: [
        {
          label: "Member",
          value: "MEMBER",
          key: 0
        },
        {
          label: "Admin",
          value: "ADMIN",
          key: 1
        },
        {
          label: "Super Admin",
          value: "SUPER_ADMIN",
          key: 2
        },
      ],
      SelectRoleItems: {},
      getShowIdUrl: null,
      SelectCliksItem: "",
      itemCliksList: [],
      MutipleUserList: [],
      showError: false,
      title: "",
      showtooltip: false,
      showAddView: false,
      showCopiedText: false,
      showEmailError: false,
      MutipleClikList: [],
      showsubmitText: false,
      key: [],
      getClicks: [],
      getRole: [],
      contactData: '',
      inviteBtnText: 'Invite',
      clikBtnColor: '#fff',
      AllContacts: '',
      showSigninBtn: true,
      clikAdmin: [],

      showItems: 25,
      searchValue: ''
    };
  }

  checkUser = async (item, role, id) => {

    let getRole = role ? role : "MEMBER";
    this.state.getRole.push({ key: id, value: getRole });
    let newRole = Object.assign([], this.state.getRole);

    this.setState({
      MutipleUserList: this.state.MutipleUserList.concat([
        {
          name: this.state.title,
          clik: this.state.SelectCliksItem,
          role: this.state.SelectRoleItems[id]
        }
      ])
    });
    this.setState({
      showError: false,
      title: "",
      SelectRoleItems: Object.assign({}),
      SelectCliksItem: "",
      showAddView: false,
      showsubmitText: true,
      getShowIdUrl: id,
      getRole: newRole
    });

    this.state.getClicks.push({ key: id, value: item.value });
    let newClicks = Object.assign([], this.state.getClicks);

    ClikGenerateKeyVariables.variables.clik_id = `Clik:${item.value.toUpperCase()}`;
    ClikGenerateKeyVariables.variables.member_type = `${getRole.toUpperCase()}`;
    try {
      await applloClient
        .query({
          query: ClikGenerateKeyMutation,
          ...ClikGenerateKeyVariables,
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          let temporaryData = this.state.itemCliksList;
          temporaryData[id].inviteKey =
            res.data.clik_create_invite_key.invite_key;
          this.setState({
            status: "Success",
            itemCliksList: temporaryData,
            getClicks: newClicks
          });

          this.writeToClipboardInvite(id);
        });
    } catch (e) {
      console.log(e);
    }
  };

  writeToClipboardInvite = async id => {

    await Clipboard.setString(
      "https://" +
      window.location.href
        .replace("http://", "")
        .replace("https://", "")
        .replace("www.", "")
        .split(/[/?#]/)[0] +
      "/clik/" +
      this.newClicks(id) +
      "/invite?key=" +
      this.state.itemCliksList[id].inviteKey
    );
  };




  componentDidMount() {
    this.props.getUserFollowCliksList.map(async (clikdata, index) => {
      if (clikdata.node?.member_type == "SUPER_ADMIN" || clikdata.node?.member_type == "ADMIN" || clikdata.node?.member_type == "MEMBER") {
        await this.state.clikAdmin.push({
          label: "#" + clikdata.node.clik.name,
          value: clikdata.node.clik.id,
          key: index,
          color: "#4169e1",
        });
      }
    });

    let data = [
      {
        label: "Select Clik",
        value: "",
        key: -1
      }
    ];

    this.setState({
      itemCliksList: data
    });

    this.props.screen('createUser')

  }

  writeToClipboard = async () => {
    await Clipboard.setString(
      "https://" +
      window.location.href
        .replace("http://", "")
        .replace("https://", "")
        .replace("www.", "")
        .split(/[/?#]/)[0] +
      "/invite/" +
      this.props.profileData.data.user.username
    );
    this.setState({
      showCopiedText: true
    });
  };



  newClicks = id => {
    if (this.state.getClicks.length > 0) {
      let { getClicks } = this.state;
      let letClicks = getClicks.find(item => item.key == id);
      let value = letClicks ? letClicks.value : null;
      return value;
    }
  };


  newRole = id => {
    let { getRole } = this.state;
    if (getRole.length > 0) {
      let letRole = getRole.find(item => item.key == id);
      let value = letRole ? letRole.value : null;
      return value;
    }
  };

  CheckPost = id => {
    let temporaryData = this.state.itemCliksList;
    temporaryData[id].showCopiedTextInvite = true;
    this.setState({
      itemCliksList: temporaryData
    });
  };


  //=-------------------------------google contact start 

  authorize = () => {

    const scopes = ['https://www.google.com/m8/feeds/', 'https://www.googleapis.com/auth/contacts', 'https://www.googleapis.com/auth/contacts.readonly']

    gapi.auth.authorize({ client_id: '519921550901-bnr3d8e28al2agpsnqufec84pj0iaf4d.apps.googleusercontent.com', scope: scopes, immediate: false }, this.handleAuthorization);
  }

  handleAuthorization = (authorizationResult) => {


    if (authorizationResult && !authorizationResult.error) {
      axios.get(`https://www.google.com/m8/feeds/contacts/default/thin?alt=json&access_token=${authorizationResult.access_token}&max-results=500&v=3.0`,
        {

        }).then(res => {
          var data = res?.data?.feed?.entry?.map((e) => {
            if (e.gd$email && e.gd$email.length > 0) {
              var nameMatch = e.gd$email[0].address.match(/^([^@]*)@/);
              var name = nameMatch ? nameMatch[1] : null;
            } else {
              var name = 'private user'
            }


            return { email: e.gd$email ? e.gd$email[0] : { address: 'private user' }, name: name.replace(".", " ") }

          })
          this.setState({ contactData: data }, () => {
            const newArr = this.state.contactData.map(v => ({ ...v, btnName: 'Invite', clikBtnColor: '#fff', clikTextColor: '#000', clikName: 'Select Clik' }))
            this.setState({ contactData: newArr, showSigninBtn: false })
          })
          this.setState({ AllContacts: data }, () => {
            const newArr = this.state.AllContacts.map(v => ({ ...v, btnName: 'Invite', clikBtnColor: '#fff', clikTextColor: '#000' }))
            this.setState({ AllContacts: newArr })
          })

        })
    }
  }






  importContacts = () => {

    var apiKey = 'AIzaSyBNvlsGvHp-KPCj1KMTswQjLOsjHiy3_Q8';




    gapi.client.setApiKey(apiKey);



    window.setTimeout(this.authorize);

  }


  //=---------------------------------- google contact end

  inviteGoogleContact = (index) => {
    let tempArray = [...this.state.contactData]
    tempArray[index].btnName = 'Invite Sent'
    tempArray[index].clikBtnColor = '#000'
    tempArray[index].clikTextColor = '#fff'
    this.setState({ contactData: tempArray })
  }

  selectClikName = (index, value) => {
    let tempArray = [...this.state.contactData]
    tempArray[index].clikName = value
    this.setState({ contactData: tempArray })
  }

  onMouseEnter = (index) => {
    let tempArray = [...this.state.contactData]
    if (tempArray[index].btnName == 'Invite Sent') {
      tempArray[index].clikBtnColor = '#000'
      tempArray[index].clikTextColor = '#fff'
    } else {
      tempArray[index].clikBtnColor = '#000'
      tempArray[index].clikTextColor = '#fff'
    }

    this.setState({ contactData: tempArray })
  }
  onMouseLeave = (index) => {
    let tempArray = [...this.state.contactData]
    if (tempArray[index].btnName == 'Invite Sent') {
      tempArray[index].clikBtnColor = '#000'
      tempArray[index].clikTextColor = '#fff'
    } else {
      tempArray[index].clikBtnColor = '#fff'
      tempArray[index].clikTextColor = '#000'
    }

    this.setState({ contactData: tempArray })
  }

  searchBar = (text) => {
    if (this.state.contactData.length > 0 && this.state.AllContacts.length > 0) {
      this.setState({
        contactData: this.state.AllContacts?.filter(i => {
          if (text == "") {
            return i
          } else if (i.name.toLowerCase().includes(text.toLowerCase())) {
            return i
          }
        })
      })
    }

  }

  handleChange = event => {
    const { value } = event.target;
    this.setState({ searchValue: value });
  };

  handleShowMore = () => {
    this.setState({
      showItems:
        this.state.showItems >= this.state.contactData.length ?
          this.state.showItems : this.state.showItems + 25
    })
  }
  render() {


    return (
      <View
        style={{
          backgroundColor: "transparent",
          paddingHorizontal: 10
        }}
      >

        <Text
          style={{
            color: "#000",
            fontSize: 16,
            fontWeight: "bold",
            fontFamily: ConstantFontFamily.defaultFont,
            marginTop: 20,
            textAlign: "left"
          }}
        >
          Invite Friends via Link
        </Text>


        <View
          style={{
            marginBottom: 1,
            alignSelf: "center",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#C5C5C5",
              borderRadius: 10,
              padding: 10,
              width: "65%"
            }}
          >
            <Text
              style={{
                textAlign: "left",
                color: "#000",
                fontSize: 14,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont
              }}
            >
              {"https://" +
                window.location.href
                  .replace("http://", "")
                  .replace("https://", "")
                  .replace("www.", "")
                  .split(/[/?#]/)[0] +
                "/invite/" +
                this.props.profileData?.data?.user?.username}
            </Text>
          </View>
          <Icon
            color={"#000"}
            iconStyle={{
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center"
            }}
            name="clone"
            type="font-awesome"
            size={18}
            containerStyle={{
              marginLeft: 40,
              alignSelf: "center"
            }}
            onPress={() => this.writeToClipboard()}
          />
        </View>
        {/* {this.state.showCopiedText == true && ( */}
        <View style={{
          flexDirection: 'row', alignItems: 'center',
          justifyContent: 'flex-start', marginLeft: '14%'
        }}>
          <Image source={require('../assets/image/checkmark.png')} style={{ width: 25, height: 25, marginRight: 10 }} />
          <Text
            style={{
              color: "#009B1A",
              fontSize: 14,
              fontWeight: "bold",
              fontFamily: ConstantFontFamily.defaultFont,
              marginVertical: 10,
              textAlign: "center"
            }}
          >
            {
              "Invitation Link Copied!"
            }
          </Text>
        </View>
        {/* )} */}



      </View >
    );
  }
}
const styles = StyleSheet.create({
  inputIOS: {
    paddingTop: 13,
    paddingHorizontal: 5,
    paddingBottom: 12,
    borderWidth: 1,
    borderColor: "#d7d7d7",
    borderRadius: 4,
    backgroundColor: "white",
    color: "black",
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },

  inputAndroid: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: "#fff",
    borderRadius: 8,
    color: "#000",
    backgroundColor: "white",
    paddingRight: 5,
    fontSize: 15,
    fontWeight: "bold",
    color: '#000',
    fontFamily: ConstantFontFamily.defaultFont
  },
  usertext: {
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  }
});

const mapStateToProps = state => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList
    : [],
});

const mapDispatchToProps = dispatch => ({


  screen: (payload) => dispatch(screen(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InviteFriend);
