import React, { Component, lazy, Suspense } from "react";
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Image,
  Platform
} from "react-native";
// import { TabBar, TabView } from "react-native-tab-view";
// import ShadowSkeletonStar from "../components/ShadowSkeletonStar";
import ConstantFontFamily from "../constants/FontFamily";
// import { retry } from "../library/Helper";
import { Button, Icon } from "react-native-elements";
// import NavigationService from "../library/NavigationService";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import ButtonStyle from "../constants/ButtonStyle";
// import { AsyncStorage } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Colors from "../constants/Colors";
// import BottomPart from "./BottomPart";
import { Hoverable } from "react-native-web-hooks";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import { setIndexes } from '../reducers/AdminTrueFalseReducer';
// import { screen } from '../reducers/action/Completed'

import CliksListDrawerScreens from './CliksListDrawerScreens';
import TopicsListDrawerScreens from './TopicsListDrawerScreens';
import UserListDrawerScreens from './UserListDrawerScreens';
import FeedListDrawerScreen from './FeedListDrawerScreen';
import { leftPanelModalFunc } from '../reducers/AdminTrueFalseReducer';
import { screen } from '../reducers/ScreenNameReducer'



const OurMission = lazy(() => import("./OurMission")
);
// const CliksListDrawerScreens = lazy(() =>
//   retry(() => import("../components/CliksListDrawerScreens"))
// );

// const FeedListDrawerScreen = lazy(() =>
//   retry(() => import("./FeedListDrawerScreen")))

// const TopicsListDrawerScreens = lazy(() =>import("./TopicsListDrawerScreens"))

// const UserListDrawerScreens = lazy(() =>
//   retry(() => import("./UserListDrawerScreens")))



class DrawerScreens extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    index: 3,
    searchedWord: this.props.searchedWord,
    cal: 0,
    FeedListDrawerScreensHeigth: 0,
    UserListDrawerScreensHeigth: 0,
    CliksListDrawerScreensHeigth: 0,
    TopicsListDrawerScreensHeigth: 0,
    routes: [
      // { key: "first", title: "Cliks", icon: "users", type: "font-awesome" },
      // { key: "second", title: "Topics", icon: "book", type: "font-awesome" },
      { key: "third", title: "Users", icon: "user", type: "font-awesome" },
      { key: "fourth", title: "Feeds", icon: "rss", type: "font-awesome" },
    ],
    loading: false,
    clikTextColor: Colors.blueColor,
    clikBtnColor: Colors.buttonbackground,
    topicBtnColor: Colors.buttonbackground,
    topicTextColor: Colors.blueColor,
    userBtnColor: Colors.buttonbackground,
    userTextColor: Colors.blueColor,
    feedBtnColor: Colors.buttonbackground,
    feedTextColor: Colors.blueColor,
    focus: "Feeds"
  };

  changeColor = (isHovered) => {
    if (isHovered == true) {
      switch (this.state.primaryIndex) {
        case 0:
          return "#009B1A";
        case 1:
          return "#4C82B6";
        case 2:
          return "#FEC236";
        default:
          return "#F34225";
      }
    }
  };

  styleChange = () => {
    if (this.state.index == 0) {
      return this.state.TopicsListDrawerScreensHeigth + 50;
    } else if (this.state.index == 1) {
      return this.state.CliksListDrawerScreensHeigth + 50;
    } else if (this.state.index == 2) {
      return this.state.UserListDrawerScreensHeigth + 50;
    } else if (this.state.index == 3) {
      return this.state.FeedListDrawerScreensHeigth + 50;
    }
  };

  searchTextField = (value) => {
    this.setState({ searchedWord: value });
  };

  _renderTabBar = (props) => (
    <View style={{ width: '15%', zIndex: 9999, backgroundColor: Colors.blueColor, marginRight: 15, }}>
      <TabBar
        {...props}

        indicatorStyle={{
          backgroundColor: "transparent",
          height: 1,
          borderRadius: 6,
        }}
        style={{
          backgroundColor: '#fff',
          shadowColor: "transparent",
          height: 60,
          width: '100%',

        }}
        contentContainerStyle={{
          flexDirection: 'column'
        }}
        // renderIcon={({ route, focused, color }) => (
        //   <Icon
        //     name={route.icon}
        //     type={route.type}
        //     color={focused ?
        //       route.title == 'Cliks' ? Colors.blueColor :
        //         route.title == 'Topics' ? Colors.greenColor :
        //           route.title == 'Users' ? '#000' :
        //             route.title == 'Feeds' && Colors.orangeColor : "#D3D3D3"}

        //   />

        // )}
        renderLabel={({ route, focused, color }) => {
          console.log(route, 'route')
          return (
            <View style={{ height: 50, width: 70, justifyContent: 'center', alignItems: 'center', backgroundColor: focused ? '#F2F3F5' : Colors.blueColor, flexDirection: 'column' }}>
              <Icon
                name={route.icon}
                type={route.type}
                color={focused ?
                  route.title == 'Cliks' ? Colors.blueColor :
                    route.title == 'Topics' ? Colors.greenColor :
                      route.title == 'Users' ? '#000' :
                        route.title == 'Feeds' && Colors.orangeColor : "#D3D3D3"}
              />

            </View>
          )
        }}
      />


    </View>
  );

  getSettingButtonState = (type) => {
    AsyncStorage.setItem("getSettingButtonState", true);
  };

  _handleIndexChange = (index) => {
    this.props.activeIndex(index);
    this.setState({ index, searchedWord: "" });
  };
  componentDidUpdate = async (prevProps) => {

    if (prevProps.searchedWord != this.props.searchedWord) {
      this.setState({ searchedWord: this.props.searchedWord });
    }
  };

  componentDidMount() {

  }


  invitefriend = () => {
    // this.getSettingButtonState("Invite Friends");
    // this.props.leftPanelModalFunc(false);
    // NavigationService.navigate("settings");
  }


  changeTab = (title) => {
    this.setState({ searchedWord: '' })
    this.props.setIndexes(title)
    if (title == "Cliks") {
      this.setState({
        index: 0,
        focus: "Cliks"
      })
    } else if (title == "Topics") {
      this.setState({
        index: 1,
        focus: "Topics"
      })
    } else if (title == "Users") {
      this.setState({
        index: 2,
        focus: "Users"
      })
    } else if (title == "Feeds") {
      this.setState({
        index: 3,
        focus: "Feeds"
      })
    }
  }

  // if (this.props.type=="Cliks") {
  //   this.props.setIndexes("Cliks")
  // } else if (this.props.type=="Topics") {
  //   this.props.setIndexes("Topics")
  // } else if (this.props.type=="Feeds") {
  //   this.props.setIndexes("Feeds")
  // } else if (this.props.type=="Users") {
  //   this.props.setIndexes("Users")
  // }

  goToUserProfile = async (username) => {
    this.props.screen('profileScreen')

    this.props.userId({
      username: username,
      type: "feed",
    });
    this.props.navigationRef.current.navigate("userProfile", { id: "" });

    this.props.leftPanelModalFunc(false)
  };

  render() {
    // console.log(this.props.profileData, 'this.props.profileData');
    return (

      <View style={{
        flexDirection: "column",
        height: Dimensions.get("window").width <= 750 ? Dimensions.get("window").height - 50 : Dimensions.get("window").height - 120,
      }}>
        <View
          style={[
            {
              height: Dimensions.get("window").width <= 750 ? hp('15%') : (Dimensions.get("window").height - 120) * .2,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: '#fff',
              paddingVertical: 15,
              paddingHorizontal: 15,
            }
          ]}
        >
          <Suspense fallback={null}>

            <OurMission />
          </Suspense>
        </View>
        {this.props.loginStatus == 1 && Dimensions.get("window").width <= 750 && (
          <Hoverable>
            {isHovered => (
              <TouchableOpacity
                onPress={() => {
                  this.goToUserProfile(
                    this.props.profileData &&
                    this.props.profileData.data.user.username
                  )
                  // this.props.leftPanelModalFunc(false)
                }

                }
              >
                <View
                  style={
                    {
                      height: hp("15%"),
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                      marginVertical: 10,
                      paddingVertical: 30,
                      paddingHorizontal: 20,
                      // backgroundColor: ConstantColors.whiteBackground,

                    }
                  }
                >

                  <Image
                    source={this.props.profileData.data.user.profile_pic ? this.props.profileData.data.user.profile_pic : require("../assets/image/default-image.png")}
                    style={{
                      width: 70,
                      height: 70,
                      borderRadius: 35,
                      borderWidth: 1,
                      borderColor: "#e1e1e1",
                      marginRight: 5
                    }}
                  />


                  <Text
                    style={{
                      color: "#000",
                      fontSize: 22,
                      fontFamily: ConstantFontFamily.defaultFont,
                      textDecorationLine:
                        isHovered == true ? "underline" : "none",
                      position: "relative",
                      top: 8,
                      fontWeight: 'bold',
                      marginRight: 5
                    }}
                  >
                    {this.props.profileData &&
                      "@" +
                      this.props.profileData.data.user.username}
                  </Text>
                </View>

              </TouchableOpacity>
            )}
          </Hoverable>
        )}

        <View style={{ height: 60, backgroundColor: '#fff', flexDirection: 'row', width: '95%', marginLeft: '3%', justifyContent: 'space-evenly' }}>
          {this.state.routes.map((route, index) => {
            // console.log(route);
            return (
              <TouchableOpacity key={index} style={{ height: 50, width: 60, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }} onPress={() => this.changeTab(route.title)}>
                <Icon
                  name={route.icon}
                  type={route.type}
                  color={
                    // route.title == 'Cliks' && this.props.type == "Cliks" ? Colors.blueColor :
                    //   route.title == 'Topics' && this.props.type == "Topics" ? Colors.blueColor :
                    route.title == 'Users' && this.props.type == "Users" ? Colors.blueColor :
                      route.title == 'Feeds' && this.props.type == "Feeds" ? Colors.blueColor : "#D3D3D3"}
                />
                {/* <Text >{route.title}</Text> */}
              </TouchableOpacity>
            )

          })}

        </View>
        <View style={{ width: Dimensions.get("window").width <= 750 ? '95%' : '94%', marginLeft: '3%', paddingHorizontal: 10 }}>

          <View style={{
            width: "100%",
            height: 40,
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 8,
            borderColor: '#d3d3d3',
            borderWidth: 1,
            // paddingRight: "3%",
            marginBottom: 10,
            backgroundColor: Colors.leftPanelColor
          }}>
            <View style={{ width: "16%", marginRight: "auto" }}>
              <Icon name="search" size={18} type="font-awesome" />
            </View>
            <TextInput
              autoFocus={false}
              placeholder={
                // this.state.index == 0 ? "Search Cliks" :
                // this.state.index == 1 ? "Search Topics" :
                this.state.index == 2 ? 'Search User' :
                  this.state.index == 3 && "Search Feeds"}
              placeholderTextColor='#706969'
              onChangeText={(query) => this.searchTextField(query)}
              value={this.state.searchedWord}
              style={{
                height: 40,
                width: "74%",
                paddingHorizontal: 10,
                paddingVertical: 10,
                outline: "none",
                position: "absolute",
                left: "13%",
                fontWeight: 'bold',
                fontFamily: ConstantFontFamily.defaultFont,
                color: Platform.OS == 'web' ? '#706969' : "#000"
              }}
            />
            {this.state.searchedWord ? (
              <TouchableOpacity
                onPress={() => this.setState({ searchedWord: "" })}
                style={{ marginLeft: "auto", width: "10%" }}
              >
                <Icon name="close" size={18} type="font-awesome" />
              </TouchableOpacity>
            ) : null}
          </View>
          {
            // this.props.type == "Cliks" ? <View
            //   onLayout={(event) => {
            //     let { height } = event.nativeEvent.layout;
            //     this.setState({
            //       CliksListDrawerScreensHeigth: height,
            //     });
            //   }}
            //   style={{
            //     height: (Platform.OS == 'web' && Dimensions.get("window").width <= 750) ? hp('35%') :
            //       (Platform.OS == 'web' && Dimensions.get("window").width > 750) ? hp('65%') :
            //         hp('50%'),
            //   }}
            // >
            //   <ScrollView showsVerticalScrollIndicator={false}
            //     contentContainerStyle={{
            //       borderWidth: 1,
            //       padding: 10,
            //       borderColor: Colors.lightGray,
            //       borderRadius: 8,
            //       height: '100%'
            //     }}>
            //     <ScrollView showsVerticalScrollIndicator={false}>

            //       <CliksListDrawerScreens
            //         current={this.state.index}
            //         navigation={this.props.navigation}
            //         searchedWord={this.state.searchedWord}
            //       />
            //     </ScrollView>
            //   </ScrollView>

            //   <Button
            //     testID="newClik"
            //     onPress={() => {

            //       this.props.navigation.navigate('createClik')

            //     }}
            //     onMouseEnter={() => this.setState({ clikBtnColor: Colors.mouseEnterColor })}
            //     onMouseLeave={() => this.setState({ clikBtnColor: Colors.buttonbackground })}
            //     title="New Clik"
            //     titleStyle={ButtonStyle.allButtonTextStyle}
            //     buttonStyle={[ButtonStyle.allButtonbackgroundStyle, { backgroundColor: this.state.clikBtnColor, }]}
            //     containerStyle={ButtonStyle.containerStyle}
            //   />

            // </View> : this.props.type == "Topics" ? <View
            //   onLayout={(event) => {
            //     let { height } = event.nativeEvent.layout;
            //     this.setState({
            //       TopicsListDrawerScreensHeigth: height,
            //     });
            //   }}
            //   style={{

            //     height: (Platform.OS == 'web' && Dimensions.get("window").width <= 750) ? hp('35%') :
            //       (Platform.OS == 'web' && Dimensions.get("window").width > 750) ? hp('65%') :

            //         hp('50%'),
            //   }}
            // >

            //   <ScrollView showsVerticalScrollIndicator={false}
            //     contentContainerStyle={{
            //       borderWidth: 1,
            //       padding: 10,
            //       borderColor: Colors.lightGray,
            //       borderRadius: 8,
            //       height: '100%'
            //     }}>
            //     <ScrollView showsVerticalScrollIndicator={false}>

            //       <TopicsListDrawerScreens
            //         navigation={this.props.navigation}
            //         current={this.state.index}
            //         searchedWord={this.state.searchedWord}
            //       />
            //     </ScrollView>
            //   </ScrollView>
            //   {this.props.isAdmin == true && (
            //     <Button
            //       onPress={() => {

            //         return this.props.navigation.navigate("createTopic");
            //       }}
            //       title="Propose Topic"
            //       titleStyle={ButtonStyle.allButtonTextStyle}
            //       buttonStyle={[ButtonStyle.allButtonbackgroundStyle, { backgroundColor: this.state.topicBtnColor, }]}
            //       containerStyle={ButtonStyle.containerStyle}
            //       onMouseEnter={() => this.setState({ topicBtnColor: Colors.mouseEnterColor, topicTextColor: '#fff' })}
            //       onMouseLeave={() => this.setState({ topicBtnColor: Colors.buttonbackground, topicTextColor: "#fff" })}
            //     />
            //   )}

            //   {this.props.isAdmin == false && (
            //     <Button
            //       onPress={() => {

            //         this.props.navigation.navigate("topichierarchy")

            //       }}
            //       title="Topic Hierarchy"
            //       onMouseEnter={() => this.setState({ topicBtnColor: Colors.mouseEnterColor, topicTextColor: '#fff' })}
            //       onMouseLeave={() => this.setState({ topicBtnColor: Colors.buttonbackground, topicTextColor: "#fff" })}
            //       titleStyle={ButtonStyle.allButtonTextStyle}
            //       buttonStyle={[ButtonStyle.allButtonbackgroundStyle, { backgroundColor: this.state.topicBtnColor, }]}
            //       containerStyle={ButtonStyle.containerStyle}
            //     />
            //   )}

            // </View> : 
            this.props.type == "Users" ? <View
              onLayout={(event) => {
                let { height } = event.nativeEvent.layout;
                this.setState({
                  UserListDrawerScreensHeigth: height,
                });
              }}
              style={{

                height: (Platform.OS == 'web' && Dimensions.get("window").width <= 750) ? hp('35%') :
                  (Platform.OS == 'web' && Dimensions.get("window").width > 750) ? (Dimensions.get("window").height - 120) * .7 :

                    hp('50%'),
              }}
            >


              <ScrollView showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                  borderWidth: 1,
                  padding: 10,
                  borderColor: Colors.lightGray,
                  borderRadius: 8,
                  height: '100%'
                }}>
                <ScrollView showsVerticalScrollIndicator={false}>
                  {/* <Suspense fallback={null}> */}
                  <UserListDrawerScreens
                    current={this.state.index}
                    navigationRef={Platform.OS == 'web' ? this.props.navigationRef : this.props.navigationRef}
                    searchedWord={this.state.searchedWord}
                  />
                </ScrollView>
                {/* </Suspense> */}
              </ScrollView>
              <Button
                onPress={() => {
                  // this.props.screen('createUser')
                  // this.props.leftPanelModalFunc(false);
                  // console.log(this.props.navigationRef,'this.props.navigationRef');
                  this.props.navigationRef?.navigate("settings")
                  this.props.leftPanelModalFunc(false)

                }}
                title="Invite Friends"
                onMouseEnter={() => this.setState({ userBtnColor: Colors.mouseEnterColor, userTextColor: '#fff' })}
                onMouseLeave={() => this.setState({ userBtnColor: Colors.buttonbackground, userTextColor: "#fff" })}
                titleStyle={ButtonStyle.allButtonTextStyle}
                buttonStyle={[ButtonStyle.allButtonbackgroundStyle, { backgroundColor: this.state.userBtnColor, }]}
                containerStyle={ButtonStyle.containerStyle}
              />
              {/* <BottomPart /> */}
            </View> : this.props.type == "Feeds" ? <View
              onLayout={(event) => {
                let { height } = event.nativeEvent.layout;
                this.setState({
                  FeedListDrawerScreensHeigth: height,
                });
              }}
              style={{
                // flex: 1
                // width: '75%',
                // height: '80%',
                height: (Platform.OS == 'web' && Dimensions.get("window").width <= 750) ? hp('35%') :
                  (Platform.OS == 'web' && Dimensions.get("window").width > 750) ? (Dimensions.get("window").height - 120) * .7 :

                    hp('50%'),

                // paddingRight: 15
              }}
            >

              <ScrollView showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                  borderWidth: 1,
                  padding: 10,
                  borderColor: Colors.lightGray,
                  borderRadius: 8,
                  height: '100%'
                }}>
                <ScrollView showsVerticalScrollIndicator={false}>

                  {/* <Suspense fallback={null}> */}
                  <FeedListDrawerScreen
                    current={this.state.index}
                    navigationRef={this.props.navigationRef}
                    searchedWord={this.state.searchedWord}
                  />
                  {/* </Suspense> */}
                </ScrollView>
              </ScrollView>
              <Button
                onPress={() => {
                  // this.props.screen('addFeed')
                  // this.props.leftPanelModalFunc(false);
                  // return NavigationService.navigate("addfeed");
                  // cons 
                  // ole.log(this.props.navigationRef)
                  this.props.navigationRef.navigate('addFeed')
                  this.props.leftPanelModalFunc(false)
                }}
                color="#fff"
                title="Add Feed"
                onMouseEnter={() => this.setState({ feedBtnColor: Colors.mouseEnterColor, feedTextColor: '#fff' })}
                onMouseLeave={() => this.setState({ feedBtnColor: Colors.buttonbackground, feedTextColor: "#fff" })}
                titleStyle={ButtonStyle.allButtonTextStyle}
                buttonStyle={[ButtonStyle.allButtonbackgroundStyle, { backgroundColor: this.state.feedBtnColor, }]}
                containerStyle={ButtonStyle.containerStyle}
              />
              {/* <BottomPart /> */}
            </View> : null}
        </View>

      </View >
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.loginStatus,
  isAdmin: state.AdminTrueFalseReducer.isAdmin,
  type: state.AdminTrueFalseReducer.type,
  // isAdminView: state.AdminTrueFalseReducer.get("isAdminView"),
  // NewHomeFeed: state.HomeFeedReducer.get("NewHomeFeedList"),
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  // windowSize: state.windowResizeReducer.windowResize

});
const mapDispatchToProps = (dispatch) => ({
  setIndexes: (payload) => dispatch(setIndexes(payload)),
  leftPanelModalFunc: (payload) => dispatch(leftPanelModalFunc(payload)),
  // setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  // leftPanelModalFunc: (payload) =>
  //   dispatch({ type: "LEFT_PANEL_OPEN", payload }),
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  screen: (payload) => dispatch(screen(payload)),

  // screen: (payload) => dispatch(screen(payload))
});
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DrawerScreens
);