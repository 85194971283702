import React, { PureComponent } from "react";
import moment from "moment";
import {
  Image,
  Platform,
  TouchableOpacity,
  View, Text, Alert, Dimensions
} from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setFEEDREPORTMODALACTION } from "../actionCreator/FeedReportModalAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
// import Swiper from "../library/Swiper";
import CreateCommentCardWeb from "./CreateCommentCardWeb";
// import PostDetailsCommentSwaper from "./PostDetailsCommentSwaper";
import Colors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
// import CommonTooltipBottom from './CommonTooltipBottom';
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger
} from "react-native-popup-menu";
// import { setParentPath, setCommentStatus, setCommentID, userHighlight, showMoreNestedId, addIconId } from '../reducers/action/Completed';
import { showMoreNestedId } from '../reducers/ShowCommentReducer';
import { DeleteContentMutation } from "../graphqlSchema/FeedMutation";
import { GetCommentMutation } from '../graphqlSchema/PostMutation';
import appolloClient from "../client";
import CommonTooltipBottomComment from "./CommonTooltipBottomComment";
import * as Clipboard from 'expo-clipboard';
import EditCommentCard from "./EditCommentCard";
import { WriteToClipboard } from '../constants/CommonFunction'

class ChildCommentSwaper extends PureComponent {
  state = {
    modalVisible: false,
    editModalVisible: false,
    outSideClick: false,
    showBug: false,
    parent_content_id: "",
    height: 0,
    pressBug: false,
    nameWidth: 0,
    setindex: 0,
    setcalHeight: [],
    gesturesEnabled: true,
    disabledSwaper: false,
    gestureStatus: false,
    arrowColor: '#c5c5c5',
    arrowColorRight: '#c5c5c5',
    comment: [],
    showMoreArray: [],
    showMoreBtnStatus: false,
    btnColor: Colors.buttonbackground,
    swiperIndex: 0,
    editModalVisible: false
  };

  constructor(props) {
    super(props);
    this.commentData = this.props.commentData;
    this.swiperRef = React.createRef();
    this.postDetailsCommentSwaperRef = {};


    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "a few seconds",
        ss: "%ds",
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        w: "1w",
        ww: "%dw",
        M: "1M",
        MM: "%dM",
        y: "1y",
        yy: "%dy"
      }
    });
  }

  onClose = async (parentId) => {
    await this.setState({
      modalVisible: false,
      outSideClick: true,
    });
    this.postDetailsCommentSwaperRef[parentId.replace("Comment:", "")].onOpen(
      parentId
    );
  };

  closeCreateComment = () => {
    this.setState({ modalVisible: false })
  }
  onSubmit = (position, data) => {
    this.setState({
      modalVisible: false,
    });
    this.props.closeModalBySubmit(position, data);
  };

  onOpen(id) {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    let changeData = this.state.modalVisible ? false : true;
    this.setState({
      modalVisible: changeData,
      parent_content_id: id,
    });
  }

  editCommentField = async () => {
    // if (this.props.profileData.subscription == "GOLD") {
    await this.setState(
      {
        editModalVisible: !this.state.editModalVisible,

      }

    );


  };


  calHeightModi = async (height, key) => {
    let data = [];
    data = this.state.setcalHeight;
    data[key] = height;
    this.setState(
      {
        setcalHeight: data,
      },
      () => {
        if (this.state.showMoreBtnStatus == false) {
          this.swiperRef.current._setHeight(
            this.state.setcalHeight[this.state.setindex]
          );
          this.swiperRef.current.props.containerStyle.height = this.state.setcalHeight[
            this.state.setindex
          ];
        }

      }
    );
  };

  setModal = async (cvalue, id) => {
    this.setState({
      modalVisible: cvalue,
      parent_content_id: id,
    });
  };

  setEditModal = async (evalue) => {
    await this.setState(
      {
        editModalVisible: evalue,
      }
    );
  };

  setGesture = async (evalue) => {
    await this.setState({
      gesturesEnabled: evalue,
    });
  };

  disableSwaper = (value) => {
    this.setState({ disabledSwaper: value });
  };


  componentWillUnmount() {
    window.removeEventListener("touchstart", (e) => this.touchStart(e));
    window.removeEventListener("touchmove", (e) => this.preventTouch(e), {
      passive: false,
    });
  }

  touchStart = (e) => {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  };

  preventTouch = (e) => {
    let _self = this;
    const minValue = 5;
    this.clientX = e.touches[0].clientX;
    this.clientY = e.touches[0].clientY;

    if (Math.abs(this.clientX)) {
      if (!this.props.preventScroll) {
        this.stopScrolling(true);
      }
      setTimeout(function () {
        if (_self.props.preventScroll) {
          _self.stopScrolling(false);
        }
      }, 500);
    }
  };

  stopScrolling = (data) => {
    this.props.stopScrolling(data);
  };

  showMoreComment = (item) => {
    let pathArray = []
    // pathArray.push(item)
    item.node.comments.edges.map(e => {
      pathArray.push(e)
    })
    this.setState({ showMoreArray: pathArray, showMoreBtnStatus: true })

  }


  prevComment = (e) => {
    this.setState({ swiperIndex: e - 1 })
  }
  nextComment = (e) => {
    this.setState({ swiperIndex: e + 1 })
  }

  goToUserProfile = (i) => {

  }



  getCommentList = async (id) => {
    appolloClient
      .query({
        query:
          GetCommentMutation,
        variables: {
          id: id
        },
        fetchPolicy: "no-cache"
      }).then(data => {
        let commentData = JSON.parse(data.data.node.nested_comments)
        let l1 = commentData.edges.reverse();
        this.props.setPostCommentDetails(l1);
        this.setState({ delete: !this.state.delete })
      })

  };



  deleteComment = (commentId) => {
    appolloClient
      .query({
        query: DeleteContentMutation,
        variables: {
          id: commentId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        if (response.data.node_delete.status.success == true) {
          this.getCommentList(this.props.PostId);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  copyCommentLink = async (id) => {
    WriteToClipboard(id, 'comment')
    // console.log(id,'iddddd');
    // let Domain = window.location.href
    //   .replace("http://", "")
    //   .replace("https://", "")
    //   .replace("www.", "")
    //   .split(/[/?#]/)[0];

    // console.log(Domain, 'Domain')
    // await Clipboard.setStringAsync(
    //   Domain.startsWith("localhost") == true
    //     ? "http://" + Domain + "/post/" + id.replace("Comment:", "")
    //     : "https://" + Domain + "/post/" + id.replace("Comment:", "")
    // );
    // let uri = window.location.href.toString().search("http://localhost:19006/")
    // if (uri != -1) {
    //   Clipboard.setString("http://localhost:19006/comment/" + id.replace("Comment:", ""))
    // } else {
    //   Clipboard.setString("https://electric-block-241402.appspot.com/comment/" + id.replace("Comment:", ""))
    // }
    // let uri1 = window.location.href.toString().search("https://weclikd-beta.com")

    // if (uri1 != -1) {
    //   Clipboard.setString("https://weclikd-beta.com/comment/" + id.replace("Comment:", ""))
    // }

  }

  getSwiper = (regex) => {
    let commentId = ''
    if (window.location.href.includes("comment")) {
      var url = window.location.href.split('/')
      let id = url[url.length - 1]
      commentId = id
    }
    return (
      <>
        {
          this.props.commentData.map((i, e) => {
            if (e == this.state.swiperIndex) {
              return (
                <>
                  <View key={e} style={{ marginTop: 10, }}
                    onMouseEnter={() => {
                      // this.setState({ showMenu: true, showMoreOption: true })

                      this.props.showMoreNested(i?.node?.id)
                      // this.props.addIcon(this.props.item?.node?.id)

                    }}
                    onMouseLeave={() => {
                      // this.props.addIcon('')
                      // this.state.MenuHover == true
                      //   ? this.setState({ showMenu: true, showMoreOption: false })
                      //   : this.setState({ showMenu: false, showMoreOption: false })
                      this.props.showMoreNested('')
                    }
                    }
                  >
                    <View style={{ flexDirection: "row", justifyContent: "space-between", width: "100%", alignContent: "center" }}

                      onMouseEnter={() => {
                        // this.setState({ showMenu: true, showMoreOption: true })

                        this.props.showMoreNested(i?.node?.id)
                        // this.props.addIcon(this.props.item?.node?.id)

                      }}>
                      <View style={{ width: '96%', }}>

                        <View
                          style={{ flexDirection: "row", alignItems: "center", width: '100%' }}
                        >
                          {/* <View style={{width:"10%",borderWidth:1,borderColor:"#d3d3d3",marginLeft:0, marginRight:5}}></View> */}
                          <Image
                            source={{
                              uri: i.node?.author?.profile_pic == null ? require("../assets/image/weclickd-logo.png") : i.node?.author?.profile_pic,
                            }}
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 8,
                              borderWidth: 1,
                              borderColor: "#e1e1e1",
                              marginRight: 5,
                            }}
                          />

                          <Hoverable>
                            {(isHovered) => (
                              <TouchableOpacity
                                disabled={i.node?.author?.username ? false : true}
                                onPress={() =>
                                  this.goToUserProfile(i.node?.author?.username)
                                }
                              // style={{ alignSelf: "center" }}
                              >
                                <Text
                                  style={{
                                    justifyContent: "flex-start",
                                    alignContent: "flex-start",
                                    color: "#6D757F",
                                    // paddingRight: 10,
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    fontSize: 14,
                                    fontWeight: 'bold'
                                    // marginTop: 12
                                    // alignSelf: this.props.margin == 30 ? null : "center",
                                    // textAlign: this.props.margin == 30 ? null : "center",
                                    // textDecorationLine:
                                    //   this.singleItem?.author?.username ? "none" : "none",
                                  }}
                                >
                                  @{i.node?.author?.username ? i.node.author.username : "deleted_user"}
                                </Text>
                              </TouchableOpacity>
                            )}
                          </Hoverable>
                          <Text
                            style={{
                              marginLeft: 10,
                              textAlign: "center",
                              color: "#6D757F",
                              fontSize: 14,
                              fontFamily: ConstantFontFamily.defaultFont
                            }}
                          >
                            {moment
                              .utc(i.node.created)
                              .local()
                              .fromNow()}
                          </Text>
                        </View>

                      </View>


                      <TouchableOpacity
                        // style={{ justifyContent: 'center', alignItems: 'center' }}

                        // onMouseEnter={() => this.setState({ MenuHover: true })}
                        // onMouseLeave={() => this.setState({ MenuHover: false })}
                        style={{ alignSelf: "flex-end", height: 50, marginRight: 10 }}

                      >

                        <Menu>
                          <MenuTrigger>

                            {/* {this.state.showMoreOption ? */}
                            {this.props.nestedId == i.node.id &&
                              <Image
                                source={require("../assets/image/menu.png")}
                                style={{
                                  height: 20,
                                  width: 20,
                                  alignSelf: "flex-end",
                                  marginRight: 15,
                                  position: 'relative',
                                  transform: [{ rotate: "90deg" }]
                                }}
                              />
                            } 
                            {/* : null} */}
                          </MenuTrigger>


                          <MenuOptions
                            optionsContainerStyle={{
                              borderRadius: 6,
                              borderWidth: 1,
                              borderColor: "#d3d3d3",
                              shadowColor: "transparent",
                            }}
                            customStyles={{
                              optionsContainer: {
                                width: 150,
                                marginLeft: Dimensions.get('window').width < 1600 ? -25 : -130,

                                // position: 'absolute'
                              },
                            }}
                          >
                            <MenuOption
                            // onSelect={async () => {

                            //   this.props.loginStatus == 1
                            //     ? await this.handleSharePost(this.props.item.node.id)
                            //     : this.props.setLoginModalStatus(true);
                            // }}
                            >
                              <Hoverable>
                                {isHovered => (
                                  <Text
                                    style={{
                                      textAlign: this.props.margin == 30 ? null : "center",
                                      color: isHovered == true ? Colors.blueColor : "#000",
                                      fontFamily: ConstantFontFamily.defaultFont
                                    }}
                                  >
                                    Share Comment
                                  </Text>
                                )}
                              </Hoverable>
                            </MenuOption>

                            <MenuOption
                              onSelect={async () => {
                                this.props.loginStatus == 1
                                  ? await this.copyCommentLink(i.node.id)
                                  : this.props.setLoginModalStatus(true);
                              }}
                            >
                              <Hoverable>
                                {isHovered => (
                                  <Text
                                    style={{
                                      textAlign: this.props.margin == 30 ? null : "center",
                                      color: isHovered == true ? Colors.blueColor : "#000",
                                      fontFamily: ConstantFontFamily.defaultFont
                                    }}
                                  >
                                    Copy Link
                                  </Text>
                                )}
                              </Hoverable>
                            </MenuOption>



                            <MenuOption
                              onSelect={() => {
                                this.editCommentField();
                              }}
                            >
                              <Hoverable>
                                {(isHovered) => (
                                  <Text
                                    style={{
                                      textAlign: this.props.margin == 30 ? null : "center",
                                      color:
                                        isHovered == true ? Colors.blueColor : "#000",
                                      fontFamily:
                                        ConstantFontFamily.defaultFont,
                                    }}
                                  >
                                    Edit
                                  </Text>
                                )}
                              </Hoverable>
                            </MenuOption>

                            {/* {this.handleDeleteMenu() == true && ( */}
                            <MenuOption
                              onSelect={() => {
                                this.deleteComment(i.node.id);
                              }}
                            >
                              <Hoverable>
                                {(isHovered) => (
                                  <Text
                                    style={{
                                      textAlign: this.props.margin == 30 ? null : "center",
                                      color: isHovered == true ? Colors.blueColor : "#000",
                                      fontFamily:
                                        ConstantFontFamily.defaultFont,
                                    }}
                                  >
                                    Delete
                                  </Text>
                                )}
                              </Hoverable>
                            </MenuOption>
                            {/* )} */}
                          </MenuOptions>
                        </Menu>


                      </TouchableOpacity>
                    </View>
                    <View style={{ marginVertical: 5, flexDirection: "row",marginLeft:15 }} ref={this.swiperRef} onMouseEnter={() => {
                      // this.setState({ showMenu: true, showMoreOption: true })

                      this.props.showMoreNested(i?.node?.id)
                      // this.props.addIcon(this.props.item?.node?.id)

                    }}>

                      {e == 0 ? null : <Hoverable
                      // onMouseEnter={() => setArrowColor('#009B1A')}
                      // onMouseLeave={() => setArrowColor('#c5c5c5')}
                      >
                        {(isHovered) => (

                          <View style={{
                            height: "100%",
                            // flex:0,
                            justifyContent: "center",
                            alignSelf: "center",
                            // position: "absolute",
                          }}
                          // onMouseEnter={() => setArrowColor('#009B1A')}
                          // onMouseLeave={() => setArrowColor('#c5c5c5')}
                          >
                            <TouchableOpacity
                              style={{

                                height: "100%",
                                justifyContent: "center",
                                alignSelf: "center",


                              }}
                              onPress={() => {

                                if (e > 0) {
                                  this.prevComment(e)
                                }


                              }}
                            >
                              <Image
                                source={
                                  isHovered == true
                                    ? require("../assets/image/left-black.png")
                                    : require("../assets/image/left-light-black.png")
                                }
                                style={{
                                  // tintColor: "#d3d3d3",
                                  alignSelf: "flex-start",
                                  width: 20,
                                  height: 20,
                                  marginRight: 20,
                                  position:'absolute',
                                  right:-5
                                }}

                              />
                            </TouchableOpacity>
                          </View>
                        )}
                      </Hoverable>}





                      {Platform.OS == "web" ? <table onMouseEnter={() => {
                        // this.setState({ showMenu: true, showMoreOption: true })

                        this.props.showMoreNested(i?.node?.id)
                        // this.props.addIcon(this.props.item?.node?.id)

                      }}>
                        <tbody>
                          <tr>
                            {i.node.state != "DELETED" ? <td
                              dangerouslySetInnerHTML={{ __html: i.node.text }}
                              style={{
                                color: "#000",
                                fontFamily: ConstantFontFamily.defaultFont,
                                fontSize: 14,
                                fontWeight: '200',
                                userSelect: "text",
                                wordWrap: 'break-word',
                                display: 'inline-block',
                                width: '90%',
                                justifyContent: null,
                                textAlign: null,
                                backgroundColor: commentId == i.node.id.replace("Comment:", "") ? Colors.leftPanelColor :'#fff',


                              }}


                            /> : <td style={{
                              fontFamily: ConstantFontFamily.defaultFont, fontStyle: 'italic'
                            }}>This Comment is Deleted</td>}

                          </tr>
                          <tr>
                          </tr>
                        </tbody>
                      </table> : <Text
                        style={{
                          color: "#000",
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 17,
                          width: "84%",
                          backgroundColor:this.props.commentId == i.node.id.replace("Comment:", "") ? Colors.leftPanelColor : '#fff',

                          justifyContent: "center",
                          textAlign: "left",

                        }}>{i.node?.text.replace(regex, '')}</Text>}



                      {e < (this.props.commentData.length - 1) ? <Hoverable
                        onMouseEnter={() => {
                          // this.setState({ showMenu: true, showMoreOption: true })

                          this.props.showMoreNested(i?.node?.id)
                          // this.props.addIcon(this.props.item?.node?.id)

                        }}
                      >
                        {(isHovered) => (
                          <View style={{
                            height: '100%',
                            justifyContent: "center",

                          }}

                          >
                            <TouchableOpacity
                              style={{

                                justifyContent: "center",
                                alignSelf: "center",
                                alignItems: "center",

                                marginRight: 5,
                              }}

                              onPress={() => {
                                if (e < (this.props.commentData.length - 1))
                                  this.nextComment(e)

                              }}
                            >
                              <Image
                                source={
                                  isHovered == true
                                    ? require("../assets/image/right-black.png")
                                    : require("../assets/image/right-light-black.png")
                                }
                                style={{
                                  // tintColor: "#d3d3d3",
                                  width: 20,
                                  height: 20,
                                  alignSelf: "flex-end",
                                  marginLeft: 20,
                                  position:'absolute',
                                  right:-10

                                }}
                              />
                            </TouchableOpacity>
                          </View>
                        )}
                      </Hoverable> : null}



                    </View>
                  </View>

                  <View style={{marginLeft:15 }} onMouseEnter={() => {
                    // this.setState({ showMenu: true, showMoreOption: true })

                    this.props.showMoreNested(i?.node?.id)
                    // this.props.addIcon(this.props.item?.node?.id)

                  }}>

                    <CommonTooltipBottomComment props={this.props}
                      item={i.node}
                      heartCount={0}
                      type={'comment'}
                      openComment={() => {
                        // this.onOpen(id)
                      }}
                      margin={30}
                      lastcomment={'no'}
                      parentIdd={this.props.parentIdd}
                    />
                  </View>

                  {this.props.loginStatus == 1 && this.state.editModalVisible ?

                    <View style={{ borderRadius: 20, marginTop: 10 }}>
                      {
                        <EditCommentCard
                          onClose={this.onSubmit}
                          parent_content_id={i.node.id}
                          closeModalBySubmit={this.onSubmit}
                          clickList={i?.node?.cliks?.length > 0 ? i?.node?.cliks : null}
                          initial={"child"}
                          topComment={i.node}
                          navigation={this.props.navigation}
                          outSideClick={null}
                          closeCreateComment={() => { }}
                        />
                      }
                    </View>
                    : null}

                  {this.props.showId == i.node.id ? <CreateCommentCardWeb
                    onClose={() => { }}
                    parent_content_id={i.node.id}
                    closeModalBySubmit={this.onSubmit}
                    clickList={[i][0]?.node?.cliks?.length > 0 ? [i][0]?.node?.cliks : null}
                    initial="child"
                    topComment={''}
                    navigation={this.props.navigation}
                    outSideClick={null}
                    showMore={'no'}

                  /> : <ChildCommentSwaper
                    commentData={
                      i.node.comments.edges
                    }
                    currentIndex={this.state.setindex}
                    key={i.node.id}
                    closeModalBySubmit={this.onSubmit}
                    navigation={this.props.navigation}
                    isFirstComment={false}
                    stopScrolling={this.stopScrolling}
                    preventScroll={this.props.preventScroll}
                    maximizeIcon={this.props.maximizeIcon}
                    props={this.props}
                    repliedId={this.props.repliedId}
                    parentIdd={this.props.parentIdd}
                    showId={this.props.showId}
                    nestedId={this.props.nestedId}
                    showMoreNested={this.props.showMoreNested}
                  />}

                </>
              )
            }
          })


        }
      </>

    )
  };

  getcomment = () => {
    const regex = /(<([^>]+)>)/ig

    var comment = <View />;
    if (this.props.commentData.length > 0) {
      comment = this.getSwiper(regex);
    }

    return comment;
  };

  getrecursiveCommentThread = () => {
    var recursiveCommentThread = <View />;
    if (this.props.commentData.length > 0) {
      recursiveCommentThread = (
        <ChildCommentSwaper
          commentData={
            this.props.commentData[this.state.setindex]?.node?.comments?.edges?.length >
              0
              ? this.props.commentData[this.state.setindex].node.comments.edges : []
          }
          currentIndex={this.state.setindex}
          key={this.props.commentData[this.state.setindex]?.node?.id && this.props.commentData[this.state.setindex]?.node?.id}
          closeModalBySubmit={this.onSubmit}
          navigation={this.props.navigation}
          isFirstComment={false}
          stopScrolling={this.stopScrolling}
          preventScroll={this.props.preventScroll}
          maximizeIcon={this.props.maximizeIcon}
          props={this.props}
          repliedId={this.props.repliedId}
          parentIdd={this.props.parentIdd}
          showId={this.props.showId}
          nestedId={this.props.nestedId}
          showMoreNested={this.props.showMoreNested}
          setPostCommentDetails={this.props.setPostCommentDetails}
        />
      );
    }
    return recursiveCommentThread;
  };

  render() {
    this.state.comment.push(this.props.commentData)
    return (
      <View style={{ paddingHorizontal: 5 }}>
        {this.getcomment()}

      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.loginStatus,
  // repliedId: state.RepliedIReducer.repliedId,
  show: state.ShowCommentReducer.show,
  // postCommentStatus: state.postCommentStatus.postCommentStatus,
  showId: state.ShowCommentReducer.id,
  nestedId: state.ShowCommentReducer.nestedId,
});

const mapDispatchToProps = (dispatch) => ({
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  showMoreNested: (payload => dispatch(showMoreNestedId(payload))),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ChildCommentSwaper
);