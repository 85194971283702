import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modalStatus: false,
    setTermWeb: '',
    setKeyEventWeb: '',
    setDisplayTypeWeb: {},
    setTerm: '',
};

const ScreenLoadingReducer = createSlice({
    name: 'ScreenLoadingReducer',
    initialState,
    reducers: {
        setTermWeb(state, action) {
            return{
                ...state,
                setTermWeb:action.payload
            }
        },
        setTerm(state, action) {
            return{
                ...state,
                setTerm:action.payload
            }
        }
    }
})

export const { setTerm, setTermWeb } = ScreenLoadingReducer.actions
export default ScreenLoadingReducer.reducer
