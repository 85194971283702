// import "firebase/auth";
// import { fromJS, List } from "immutable";
import React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Icon } from "react-native-elements";
// import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
// import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
// import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
// import { listTopicFeed } from "../actionCreator/TopicsFeedAction";
// import { getTrendingTopicsProfileDetails } from "../actionCreator/TrendingTopicsProfileAction";
import {
  saveUserLoginDaitails,
  setLoginStatus
} from "../actionCreator/UserAction";
import appolloClient from "../client";
// import ShadowSkeletonStar from "../components/ShadowSkeletonStar";
import ConstantFontFamily from "../constants/FontFamily";
import { SearchTopicMutation } from "../graphqlSchema/SearchMutation";
import { SearchTopicVariables } from "../graphqlSchema/SearchVariables";
// import NavigationService from "../library/NavigationService";
// import TopicStar from "./TopicStar";
import TrendingTopics from "./TrendingTopics";
import ConstantColors from '../constants/Colors'

class TopicsListDrawerScreens extends React.PureComponent {
  constructor(props) {
    super(props);
    this.timer;
    this.state = {
      showEditIcon: true,
      showSearchIcon: true,
      search: this.props.searchedWord,
      topiclistData: [],
      showTooltip: false,
      loading: true,
      getTrending_Topics_List: this.props.getTrending_Topics_List
    };
  }

  componentDidMount() {
    this.setState({
      topiclistData: this.props.getUserFollowTopicList,
      getTrending_Topics_List: this.props.getTrending_Topics_List
    });
  }

  goToProfile = async id => {
    this.props.topicId({
      id: id,
      type: "feed"
    });
    this.props.setPostCommentReset({
      payload: [],
      postId: "",
      title: "",
      loading: true
    })
  };

  updateTopicSearch = async search => {
    this.setState({ search });
    let tempData = [];
    let tempArray = [];

    if (search.length > 0 && this.props.current === 1) {
      SearchTopicVariables.variables.prefix = search;
      appolloClient
        .query({
          query: SearchTopicMutation,
          ...SearchTopicVariables,
          fetchPolicy: "no-cache"
        })
        .then(res => {
          tempArray = res.data.search.topics;
          for (let i = 0; i < tempArray.length; i++) {
            tempData.push({ topic: tempArray[i] });
          }
          this.setState({
            topiclistData: fromJS(tempData),
            getTrending_Topics_List: []
          });
        });
    } else {
      this.setState({
        topiclistData: this.props.getUserFollowTopicList,
        getTrending_Topics_List: this.props.getTrending_Topics_List
      });
    }
  };

  componentDidUpdate = async prevProps => {
    if (prevProps.searchedWord != this.props.searchedWord) {
      await this.updateTopicSearch(this.props.searchedWord);
    } else if (prevProps.searchedWord.length == 0) {
      this.setState({ topiclistData: this.props.getUserFollowTopicList });
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  };
  toogle = () => {
    if (this.state.showTooltip == false) {
      this.setState({ showTooltip: true });
    } else {
      this.setState({ showTooltip: false });
    }
  };

  calHeightLeftPannel(height) {
    if (height > 0) {
      if (this.props.current == 1) {
        this.props.calHeightLeftPannel(height);
      }
    }
  }

  loginHandle = () => {
    this.props.setLoginModalStatus(true);
  };

  signHandle = () => {
    this.props.setSignUpModalStatus(true);
  };

  render() {
    return (
      <View>
        {/* {this.props.getScreenLoadingStatus && null} */}
        {/* {this.props.getScreenLoadingStatus && null} */}

        {/* {this.props.loginStatus == 1 ? ( */}
          <View style={{backgroundColor: ConstantColors.customeBackgroundColor,}}>
            {/* <ScrollView showsVerticalScrollIndicator={false}>
              {this.state.topiclistData.map((item, i) => {
                return (
                  <View
                    key={i}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%"
                    }}
                  >
                    <View style={{ width: "80%", backgroundColor: "#fff" }}>
                      <TouchableOpacity
                        onPress={() =>
                          this.goToProfile(
                            item.getIn(["topic", "name"]).replace("topic:", "")
                          )
                        }
                        style={{
                          marginTop: 10,
                          //marginLeft: 5,
                          alignSelf: "flex-start",
                          padding: 5,
                          backgroundColor: item.getIn(["topic", "parents"])
                            ? "#e3f9d5"
                            : "#FEF6D1",
                          borderRadius: 6
                        }}
                      >
                        <Hoverable>
                          {isHovered => (
                            <Text
                              style={{
                                width: "100%",
                                color: item.getIn(["topic", "parents"])
                                  ? "#009B1A"
                                  : "#FEC236",
                                fontSize: 15,
                                fontWeight: "bold",
                                fontFamily:
                                  ConstantFontFamily.defaultFont,
                                textDecorationLine:
                                  isHovered == true ? "underline" : "none"
                              }}
                            >
                              /{item.getIn(["topic", "name"]).toLowerCase()}
                            </Text>
                          )}
                        </Hoverable>
                      </TouchableOpacity>
                    </View>

                    <TopicStar
                      TopicName={item.getIn(["topic", "name"])}
                      ContainerStyle={{
                        height: 30,
                        justifyContent: "center",
                        width: "20%",
                        alignItems: "center",
                        marginTop: 10,
                        paddingLeft: 8
                      }}
                      style={{ padding: 5 }}
                      ImageStyle={{
                        height: 20,
                        width: 20,
                        alignSelf: "flex-end",
                        marginRight: 2
                      }}
                    />
                  </View>
                );
              })}
            </ScrollView> */}
            <TrendingTopics
              navigation={this.props.navigation}
              searchedFollowText={this.props.searchedWord}
            />
            {/* {this.state.getTrending_Topics_List.map((item, i) => {
              let index = this.props.getUserFollowTopicList.findIndex(
                i => i.getIn(["topic", "name"]) == item.node.name
              );
              if (index == -1 && i < 10) {
                return (
                  <View
                    key={i}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%"
                    }}
                  >
                    <View
                      style={{
                        width: "80%",
                        backgroundColor: "#fff"
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => this.goToProfile(item.node.name)}
                        style={{
                          marginTop: 10,
                          height: 30,
                          alignSelf: "flex-start",
                          padding: 5,
                          backgroundColor: item.node.parents
                            ? "#e3f9d5"
                            : "#FEF6D1",
                          borderRadius: 6
                        }}
                      >
                        <Hoverable>
                          {isHovered => (
                            <Text
                              style={{
                                width: "100%",
                                color: item.node.parents
                                  ? "#009B1A"
                                  : "#FEC236",
                                fontSize: 15,
                                fontWeight: "bold",
                                fontFamily:
                                  ConstantFontFamily.defaultFont,
                                textDecorationLine:
                                  isHovered == true ? "underline" : "none"
                              }}
                            >
                              /{item.node.name.toLowerCase()}
                            </Text>
                          )}
                        </Hoverable>
                      </TouchableOpacity>
                    </View>
                    <TopicStar
                      TopicName={item.node.name}
                      ContainerStyle={{
                        height: 30,
                        justifyContent: "center",
                        width: "20%",
                        alignItems: "center",
                        marginTop: 10,
                        paddingLeft: 10
                      }}
                      ImageStyle={{
                        height: 20,
                        width: 20,
                        alignSelf: "flex-end",
                        marginRight: 2
                      }}
                    />
                  </View>
                );
              }
            })} */}
          </View>
        {/* ) : ( */}
          {/* <View>
            {this.props.getScreenLoadingStatus == false && (
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center"
                  }}
                  reverse
                  name="sticky-note"
                  type="font-awesome"
                  size={20}
                  containerStyle={{
                    alignSelf: "center"
                  }}
                />
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont,
                    color: "#000",
                    alignSelf: "center"
                  }}
                >
                  <Text
                    onPress={() => this.loginHandle()}
                    style={{ textDecorationLine: "underline" }}
                  >
                    Login
                  </Text>{" "}
                  to follow topics
                </Text>
              </View>
            )}
          </View> */}
        {/* )} */}
      </View>
    );
  }
}

const mapStateToProps = state => ({
  // getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
  //   ? state.LoginUserDetailsReducer.userFollowTopicsList
  //   : [],
  // getTrending_Topics_List: state.TrendingTopicsReducer.get(
  //   "Trending_Topics_List"
  // )
  //   ? state.TrendingTopicsReducer.get("Trending_Topics_List")
  //   : List(),
  // loginStatus: state.UserReducer.get("loginStatus"),
  // getScreenLoadingStatus: state.ScreenLoadingReducer.get("modalStatus"),
  // isAdmin: state.AdminTrueFalseReducer.get("isAdmin"),
  // isAdminView: state.AdminTrueFalseReducer.get("isAdminView")
});

const mapDispatchToProps = dispatch => ({
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  // topicId: payload => dispatch(getTrendingTopicsProfileDetails(payload)),
  // listTopicFeed: payload => dispatch(listTopicFeed(payload)),
  // saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  // setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  // setSignUpModalStatus: payload => dispatch(setSIGNUPMODALACTION(payload)),
  // setPostCommentReset: payload =>
  // dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload })
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TopicsListDrawerScreens
);

const styles = StyleSheet.create({
  bottom: {
    flex: 1,
    justifyContent: "flex-end",
    width: "100%"
  }
});
