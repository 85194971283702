import { Platform } from "react-native";
import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  POSTCOMMENTDETAILS,
  // POSTCOMMENTDETAILS_FAILURE,
  // POSTCOMMENTDETAILS_SUCCESS,
} from "../constants/Action";
import getEnvVars from "../environment";
import appolloClient from "../client";
import { GetCommentMutation } from '../graphqlSchema/PostMutation';
import { setPostCommentDetailSuccess } from "../reducers/PostCommentDetailsReducer";

const isWeb = Platform.OS === "web";
const apiUrl = getEnvVars();

const getResponse = (req) => {
  if (req.id) {


    let comment = appolloClient
      .query({
        query:
          GetCommentMutation,
        variables: {
          id: req.id
        },
        fetchPolicy: "no-cache"
      }).then(data => {
        if (data?.data?.node?.nested_comments?.length > 0) {
          let commentData = JSON.parse(data?.data?.node?.nested_comments)
          return commentData.edges.reverse()
        }
        return []

      })
    return comment
  } else {
    return [];
  }
};

export function* PostCommentMiddleware({ payload }) {
  // console.log(payload,'ppppppppp');
  const getPostId = (state) => state.PostCommentDetailsReducer.PostId;  
  const PostId = yield select(getPostId);
  // console.log(PostId,'PostIdPostId');
  const getTitle = (state) => state.PostCommentDetailsReducer.Title;
  const title = yield select(getTitle);
  const getPostCommentDetails = (state) =>
    state.PostCommentDetailsReducer.PostCommentDetails;
  const PostCommentDetails = yield select(getPostCommentDetails);



  try {
    let response;
    if (Array.isArray(payload)) {
      response = payload;
    } else {
      if (PostId != payload.id) {
        yield put(setPostCommentDetailSuccess({
          // type: POSTCOMMENTDETAILS_SUCCESS,
          payload: [],
          postId: payload.id && payload.id
            .replace("Trending", "")
            .replace("New", "")
            .replace("Discussion", "")
            .replace("Search", ""),
          title: payload.title,
          loading: payload.loading && payload.loading,
        }));
        response = yield call(getResponse, payload);
      } else {
        response = PostCommentDetails;
      }
    }


    let cloneResponse = {
      comments: {
        edges: [...response],
      },
    };
    yield put(setPostCommentDetailSuccess({
      // type: POSTCOMMENTDETAILS_SUCCESS,
      payload: response,
      postId: Array.isArray(payload) ? PostId : payload.id,
      title: Array.isArray(payload) ? title : payload.title,
      loading: payload.loading && payload.loading,
    }));
    // yield put({
    //   type: POSTCOMMENTDETAILS_SUCCESS,
    //   payload: [],
    //   postId: payload.id,
    //   title: payload.title,
    //   loading: false,
    // });
    // title: Array.isArray(payload) ? title : payload.title,
    yield put({
      type: "SET_COMMENT_LEVEL",
      payload: 2,
    });
  } catch (err) {
    yield put({
      type: POSTCOMMENTDETAILS_FAILURE,
      payload: err.message,
    });
    console.log(err);
  }
}

export default function* PostCommentMiddlewareWatcher() {
  yield takeEvery(POSTCOMMENTDETAILS, PostCommentMiddleware);
}
function setArrowKeys(
  items,
  parentItem,
  isRoot = true,
  nextParent = null,
  clevel = 0
) {
  let output, key, value;
  output = [];
  for (key in items) {

    value = { ...items[key] };
    value.up =
      parentItem.node && parentItem.node.id ? parentItem.node.id : null;

    if (value.comments.edges[0] && value.comments.edges[0].node.id)
      value.down = value.comments.edges[0].node.id;
    else if (isRoot && items[parseInt(key) + 1])
      value.down = items[parseInt(key) + 1].node.id;
    else if (nextParent) value.down = nextParent.node.id;
    else value.down = null;

    value.right =
      !isRoot && items[parseInt(key) + 1] && items[parseInt(key) + 1].node.id
        ? items[parseInt(key) + 1].node.id
        : null;

    value.left =
      !isRoot && items[parseInt(key) - 1] && items[parseInt(key) - 1].node.id
        ? items[parseInt(key) - 1].node.id
        : null;

    value.level = clevel;

    value.comments.edges = setArrowKeys(
      value.comments.edges,
      value,
      false,
      nextParent ? nextParent : items[parseInt(key + 1)],
      clevel + 1
    );
    output[key] = value;
  }
  return output;
}
