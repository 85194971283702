
const defaultFont = "Helvetica";
const HeaderBoldFont = "HelveticaBold";

const Helvetica = "Helvetica";
const HelveticaBold = "HelveticaBold";


export default {  
  HeaderBoldFont,
  defaultFont,
  Helvetica,
  HelveticaBold
};
