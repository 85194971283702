// import { List } from "immutable";
import React, { lazy, Component, createRef, Suspense } from "react";

import {
  Dimensions,
  Platform,
  ScrollView,
  Text,
  View,
  TouchableOpacity
} from "react-native";
import { Icon } from "react-native-elements";
import {
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
// import { TabBar, TabView } from "react-native-tab-view";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import ConstantFontFamily from "../constants/FontFamily";
import ButtonStyle from "../constants/ButtonStyle";
import ShadowSkeleton from "../components/ShadowSkeleton";
// import ShadowSkeletonForProfile from "../components/ShadowSkeletonForProfile";
// import { feedFluctuation } from '../reducers/action/Completed';
// import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import Colors from "../constants/Colors";
// import { showDiscussion } from '../reducers/action/Completed';


// import ClikMembersList from "../components/ClikMembersList"

// import ClikProfileUserList from "../components/ClikProfileUserList"

import NewHomeFeed from "../components/NewHomeFeed"

import TrendingHomeFeed from "../components/TrendingHomeFeed"

import DiscussionHomeFeed from "../components/DiscussionHomeFeed"

import CommentDetailScreen from "./CommentDetailScreen"
import FeedCommentStyle from "./Style/FeedCommentStyle";


let lastTap = null;

class CliksProfileFeedScreen extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {};
    this.state = {
      tabPost: false,
      items: [
        {
          label: "Profile",
          value: "FEED",
          key: 0,
        },
        {
          label: "Members",
          value: "USERS",
          key: 1,
        },
        {
          label: "Applications",
          value: "APPLICATIONS",
          key: 2,
        },
      ],
      routes: [

        { key: "first", title: "New", icon: "clock-o", type: "font-awesome" },
        {
          key: "second",
          title: "Trending",
          icon: "fire",
          type: "simple-line-icon",
        },
        {
          key: "third",
          title: "Bookmarks",
          icon: "bookmark",
          type: "font-awesome",
        },
      ],
      index: 1,
      id: "",
      ViewMode: "Default",
      scrollY: 0,
      ProfileHeight: 0,
      feedY: 0,
      focused: "New"
    };
    this.userPermision = false;
    this.userApprovePermision = false;
    this.flatListRefNew = createRef();
    this.flatListRefDiscussion = createRef();
    this.flatListRefTrending = createRef();
  }



  _renderLazyPlaceholder = ({ route }) => <ShadowSkeleton />;

  _handleIndexChange = (index) => {
    this.setState({ index });


    if (index == 0 && this.props.TrendingClikHomeFeedList && this.props.TrendingClikHomeFeedList.length > 0) {

      this.props.feedFluctuation(true)
      // this.props.setPostCommentReset({
      //   payload: [],
      //   postId: '',
      //   title: '',
      //   loading: true
      // });
      this.props.setPostCommentDetails({
        id: "Post:" + this.props.TrendingClikHomeFeedList[0].node.id.replace("Post:", ""),
        title: this.props.TrendingClikHomeFeedList[0].node.title,
        loading: false
      });



    } else if (index == 1 && this.props.TrendingClikHomeFeedList && this.props.TrendingClikHomeFeedList.length > 0) {
      this.props.feedFluctuation(true)
      // this.props.setPostCommentReset({
      //   payload: [],
      //   postId: '',
      //   title: '',
      //   loading: true
      // });

      this.props.setPostCommentDetails({
        id: "Post:" + this.props.TrendingClikHomeFeedList[0].node.id.replace("Post:", ""),
        title: this.props.TrendingClikHomeFeedList[0].node.title,
        loading: false
      });


    } else if (index == 2 && this.props.TrendingClikHomeFeedList && this.props.TrendingClikHomeFeedList.length > 0) {
      this.props.feedFluctuation(true)
      // this.props.setPostCommentReset({
      //   payload: [],
      //   postId: '',
      //   title: '',
      //   loading: true
      // });

      this.props.setPostCommentDetails({
        id: "Post:" + this.props.TrendingClikHomeFeedList[0].node.id.replace("Post:", ""),
        title: this.props.TrendingClikHomeFeedList[0].node.title,
        loading: false
      });

    }


  };

  _renderTabBar = (props) =>

    Dimensions.get("window").width >= 750 && (
      <View>
        <View
          style={[
            ButtonStyle.TabbarBorderStyleForDashboard,

            {
              flexDirection: "row",
              height: 55,
              backgroundColor:
                Dimensions.get("window").width <= 750 ? "#000" : "#fff",
              alignItems: "center",
              paddingHorizontal: 10,
              paddingVertical: 10,
              // marginLeft: 0,
              // width: '100%',
              borderBottomWidth: 1

            },
          ]}
        >
          <TabBar
            onTabPress={() => this.handleDoubleTap()}
            {...props}
            indicatorStyle={{
              backgroundColor: "transparent",
              height: 2,
              borderRadius: 6,
            }}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              shadowColor: "transparent",
              height: 60,
              justifyContent: "center",
            }}
            labelStyle={{
              color: "#000",
              fontFamily: ConstantFontFamily.defaultFont,
            }}
            // renderIcon={({ route, focused, color }) =>
            //   Dimensions.get("window").width >= 750 && (
            //     <Icon
            //       name={route.icon}
            //       type={route.type}
            //       color={focused ? Colors.blueColor : "#D3D3D3"}
            //     />
            //   )
            // }
            renderLabel={({ route, focused, color, isActive }) => (
              <Text
                style={[ButtonStyle.tabbarTitleStyle, {
                  color: focused ? '#000' : isActive ? '#000' : "#D3D3D3",

                }]}
              >
                {route.title}
              </Text>
            )}
          />
        </View>
      </View>
    );


  _renderUserItem = (item) => {
    return (

      <ClikProfileUserList
        item={item}
        navigation={this.props.navigation}
        ClikInfo={this.props.cliksDetails}
      />

    );
  };

  _renderClikMembersItem = (item) => {
    return (

      <ClikMembersList
        item={item}
        navigation={this.props.navigation}
        ClikInfo={this.props.cliksDetails}
        userApprovePermision={this.userApprovePermision}
      />

    );
  };









  componentDidUpdate() {
    // if (this.CliksProfilescrollview) {
    //   // this.CliksProfilescrollview.scrollTo({ x: 0, y: 0, animated: true });
    //   // console.log("helloq")
    // }
    if (this.props.getHasScrollTop == true && this.CliksProfilescrollview) {
      // this.CliksProfilescrollview.scrollTo({ x: 0, y: 0, animated: true });
      this.props.setHASSCROLLEDACTION(false);

    }
    if (this.props.loginStatus == 1) {
      this.getUserPermision();
      this.getUserApprovePermision();
    }
  }

  getUserPermision = () => {
    // const index = this.props.listClikMembers.findIndex(
    //   (i) =>
    //     i.node.user.id == this.props.profileData?.data?.user?.id
    // );
    // if (index != -1) {
    //   this.userPermision = true;
    // } else {
    //   this.userPermision = false;
    // }
  };

  getUserApprovePermision = () => {
    // const index = this.props.listClikMembers.findIndex(
    //   (i) =>
    //     i.node.user.id ==
    //     this.props.profileData?.data?.user?.id &&
    //     (i.node.type == "SUPER_ADMIN" || i.node.type == "ADMIN")
    // );
    // if (index != -1) {
    //   this.userApprovePermision = true;
    // } else {
    //   this.userApprovePermision = false;
    // }
  };

  loginHandle = () => {
    this.props.loginModalStatus(true);
  };

  listScroll = (value) => {
    this.setState({
      feedY: value,
    });
    this.props.listScroll(value);
  };
  doScroll = (value, name) => {
    if (name == "new") {
      this.flatListRefNew = value;
    } else if (name == "trending") {
      this.flatListRefTrending = value;
    } else if (name == "discussion") {
      this.flatListRefDiscussion = value;
    }
  };

  scrollFunc = () => {
    {
      this.flatListRefNew.current &&
        this.flatListRefNew.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true,
        }),
        this.flatListRefTrending.current &&
        this.flatListRefTrending.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true,
        }),
        this.flatListRefDiscussion.current &&
        this.flatListRefDiscussion.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true,
        });
    }
  };

  handleDoubleTap = () => {
    if (lastTap !== null) {
      this.scrollFunc();
      clearTimeout(lastTap);
      lastTap = null;
    } else {
      lastTap = setTimeout(() => {
        clearTimeout(lastTap);
        lastTap = null;
      }, 1000);
    }
    this.setState({ tabPost: true })

  };



  changeTabStatus = () => {
    this.setState({ tabPost: false })
  }


  _renderScene = () => {

    switch (this.state.focused) {
      case "Discussion":
        return (
          <View>
            {this.props.loginStatus == 0 ? (
              <View>
                {this.props.cliksDetails &&
                  this.props.cliksDetails
                    ?.invite_only == true ? (
                  <ScrollView style={{ paddingBottom: 360, height: hp('100%') - 50 }} showsVerticalScrollIndicator={false}
                    scrollEnabled={this.props.scrollY >= 100 ? true : false}
                  >

                    <DiscussionHomeFeed
                      navigation={this.props.navigation}
                      listType={"Clik"}
                      data={this.props.cliksDetails.name}
                      ViewMode={this.state.ViewMode}
                      listScroll={this.listScroll}
                      changeLoadTab={this.props.changeLoadTab}
                      changeTabStatus={this.changeTabStatus}
                      onLoadingComment={this.props.onLoadingComment}
                      tabPost={this.state.tabPost}
                      ActiveTab={this.state.routes[this.state.index].title}
                      doScroll={this.doScroll}
                    />

                  </ScrollView>
                ) : (
                  <ScrollView style={{ paddingBottom: 360, height: hp('100%') - 50 }} showsVerticalScrollIndicator={false}
                    scrollEnabled={this.props.scrollY >= 100 ? true : false}
                  >

                    <DiscussionHomeFeed
                      navigation={this.props.navigation}
                      listType={"Clik"}
                      data={this.props.navigation
                        .getParam("id", "NO-ID")
                        .toLowerCase()}
                      ViewMode={this.state.ViewMode}
                      changeTabStatus={this.changeTabStatus}
                      changeLoadTab={this.props.changeLoadTab}
                      onLoadingComment={this.props.onLoadingComment}
                      tabPost={this.state.tabPost}
                      listScroll={this.listScroll}
                      ActiveTab={this.state.routes[this.state.index].title}
                      doScroll={this.doScroll}
                    />

                  </ScrollView>
                )}
              </View>
            ) : (
              <View>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="sticky-note"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      onPress={() => this.loginHandle()}
                      style={{
                        textDecorationLine: "underline",
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Login
                    </Text>{" "}
                    to see bookmarked posts
                  </Text>
                </View>
              </View>
            )}
          </View>
        );
      case "New":
        return (
          <View>
            {this.props.cliksDetails &&
              this.props.cliksDetails
                ?.invite_only == true ? (
              this.props.loginStatus == 1 ? (
                <ScrollView style={{ paddingBottom: 30, height: hp('100%') - 5 }} showsVerticalScrollIndicator={false}
                  scrollEnabled={this.props.scrollY >= 100 ? true : false}
                >


                  <NewHomeFeed
                    navigation={this.props.navigation}
                    listType={"Clik"}
                    data={this.props.cliksDetails.id}
                    ViewMode={this.state.ViewMode}
                    onLoadingComment={this.props.onLoadingComment}
                    changeLoadTab={this.props.changeLoadTab}
                    changeTabStatus={this.changeTabStatus}
                    tabPost={this.state.tabPost}
                    listScroll={this.listScroll}
                    ActiveTab={this.state.routes[this.state.index].title}
                    doScroll={this.doScroll}
                  />

                </ScrollView>
              ) : (
                <View>
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      color={"#000"}
                      iconStyle={{
                        color: "#fff",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                      reverse
                      name="sticky-note"
                      type="font-awesome"
                      size={20}
                      containerStyle={{
                        alignSelf: "center",
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 12,
                        fontFamily: ConstantFontFamily.defaultFont,
                        color: "#000",
                        alignSelf: "center",
                      }}
                    >
                      <Text
                        onPress={() => this.loginHandle()}
                        style={{
                          textDecorationLine: "underline",
                          fontFamily: ConstantFontFamily.defaultFont,
                        }}
                      >
                        Login
                      </Text>{" "}
                      to see new posts
                    </Text>
                  </View>
                </View>
              )
            ) : (
              <ScrollView style={{ paddingBottom: 30, height: hp('100%') - 50 }}

                scrollEnabled={this.props.scrollY >= 100 ? true : false}
                showsVerticalScrollIndicator={false}
              >

                <NewHomeFeed
                  navigation={this.props.navigation}
                  listType={"Clik"}
                  data={this.props.cliksDetails.id}
                  ViewMode={this.state.ViewMode}
                  onLoadingComment={this.props.onLoadingComment}
                  changeLoadTab={this.props.changeLoadTab}
                  changeTabStatus={this.changeTabStatus}
                  tabPost={this.state.tabPost}
                  listScroll={this.listScroll}
                  ActiveTab={this.state.routes[this.state.index].title}
                  doScroll={this.doScroll}
                />

              </ScrollView>
            )}
          </View>
        );

      case "New":
        return (
          <View>
            {this.props.cliksDetails &&
              this.props.cliksDetails
                ?.invite_only == true ? (
              this.props.loginStatus == 1 ? (
                <ScrollView style={{ paddingBottom: 30, height: hp('100%') - 100 }} showsVerticalScrollIndicator={false}
                  scrollEnabled={this.props.scrollY >= 100 ? true : false}
                >


                  <TrendingHomeFeed
                    navigation={this.props.navigation}
                    listType={"Clik"}
                    data={this.props.cliksDetails.id}
                    ViewMode={this.state.ViewMode}
                    changeTabStatus={this.changeTabStatus}
                    changeLoadTab={this.props.changeLoadTab}
                    tabPost={this.state.tabPost}
                    onLoadingComment={this.props.onLoadingComment}
                    listScroll={this.listScroll}
                    ActiveTab={this.state.routes[this.state.index].title}
                    doScroll={this.doScroll}
                  />

                </ScrollView>
              ) : (
                <View>
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      color={"#000"}
                      iconStyle={{
                        color: "#fff",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                      reverse
                      name="sticky-note"
                      type="font-awesome"
                      size={20}
                      containerStyle={{
                        alignSelf: "center",
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 12,
                        fontFamily: ConstantFontFamily.defaultFont,
                        color: "#000",
                        alignSelf: "center",
                      }}
                    >
                      <Text
                        onPress={() => this.loginHandle()}
                        style={{
                          textDecorationLine: "underline",
                          fontFamily: ConstantFontFamily.defaultFont,
                        }}
                      >
                        Login
                      </Text>{" "}
                      to see trending posts
                    </Text>
                  </View>
                </View>
              )
            ) : (
              <ScrollView style={{ paddingBottom: 30, height: hp('100%') - 50 }} showsVerticalScrollIndicator={false}
                scrollEnabled={this.props.scrollY >= 100 ? true : false}
              >

                <TrendingHomeFeed
                  navigation={this.props.navigation}
                  listType={"Clik"}
                  data={this.props.cliksDetails
                    ?.id}
                  ViewMode={this.state.ViewMode}
                  changeTabStatus={this.changeTabStatus}
                  tabPost={this.state.tabPost}
                  changeLoadTab={this.props.changeLoadTab}
                  onLoadingComment={this.props.onLoadingComment}
                  listScroll={this.listScroll}
                  ActiveTab={this.state.routes[this.state.index].title}
                  doScroll={this.doScroll}
                />

              </ScrollView>
            )}
          </View>
        );
      default:
        return (
          <View>
            {this.props.cliksDetails &&
              this.props.cliksDetails
                ?.invite_only == true ? (
              this.props.loginStatus == 1 ? (
                <ScrollView style={{ paddingBottom: 30, height: hp('100%') - 50 }} showsVerticalScrollIndicator={false}
                  scrollEnabled={this.props.scrollY >= 100 ? true : false}
                >

                  <NewHomeFeed
                    navigation={this.props.navigation}
                    listType={"Clik"}
                    data={this.props.cliksDetails.id}
                    ViewMode={this.state.ViewMode}
                    changeTabStatus={this.changeTabStatus}
                    tabPost={this.state.tabPost}
                    changeLoadTab={this.props.changeLoadTab}
                    onLoadingComment={this.props.onLoadingComment}
                    listScroll={this.listScroll}
                    ActiveTab={this.state.routes[this.state.index].title}
                    doScroll={this.doScroll}
                  />

                </ScrollView>
              ) : (
                <View>
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      color={"#000"}
                      iconStyle={{
                        color: "#fff",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                      reverse
                      name="sticky-note"
                      type="font-awesome"
                      size={20}
                      containerStyle={{
                        alignSelf: "center",
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 12,
                        // fontWeight: "bold",
                        fontFamily: ConstantFontFamily.defaultFont,
                        color: "#000",
                        alignSelf: "center",
                      }}
                    >
                      <Text
                        onPress={() => this.loginHandle()}
                        style={{
                          textDecorationLine: "underline",
                          fontFamily: ConstantFontFamily.defaultFont,
                        }}
                      >
                        Login
                      </Text>{" "}
                      to see new posts
                    </Text>
                  </View>
                </View>
              )
            ) : (
              <ScrollView style={{ paddingBottom: 360, height: hp('100%') - 50 }} showsVerticalScrollIndicator={false}
                scrollEnabled={this.props.scrollY >= 100 ? true : false}
              >


                <NewHomeFeed
                  navigation={this.props.navigation}
                  listType={"Clik"}
                  data={this.props.cliksDetails.id}
                  ViewMode={this.state.ViewMode}
                  changeLoadTab={this.props.changeLoadTab}
                  onLoadingComment={this.props.onLoadingComment}
                  listScroll={this.listScroll}
                  ActiveTab={this.state.routes[this.state.index].title}
                  doScroll={this.doScroll}
                />

              </ScrollView>
            )}
          </View>
        );
    }
  };

  renderTabViewForMobile = () => {
    if (this.props.getTabView == "Bookmarks") {
      return (
        <View>
          {this.props?.loginStatus == 1 ? (
            <View>
              {this.props?.cliksDetails.invite_only == true ? (

                <DiscussionHomeFeed
                  navigation={this.props.navigation}
                  listType={"Clik"}
                  data={this.props.navigation
                    .getParam("id", "NO-ID")
                    .toLowerCase()}
                  ViewMode={this.state.ViewMode}
                  listScroll={this.listScroll}
                  ActiveTab={this.state.routes[this.state.index].title}
                  doScroll={this.doScroll}
                />

              ) : (

                <DiscussionHomeFeed
                  navigation={this.props.navigation}
                  listType={"Clik"}
                  data={this.props.navigation
                    .getParam("id", "NO-ID")
                    .toLowerCase()}
                  ViewMode={this.state.ViewMode}
                  listScroll={this.listScroll}
                  ActiveTab={this.state.routes[this.state.index].title}
                  doScroll={this.doScroll}
                />

              )}
            </View>
          ) : (
            <View>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                  reverse
                  name="sticky-note"
                  type="font-awesome"
                  size={20}
                  containerStyle={{
                    alignSelf: "center",
                  }}
                />
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: ConstantFontFamily.defaultFont,
                    color: "#000",
                    alignSelf: "center",
                  }}
                >
                  <Text
                    onPress={() => this.loginHandle()}
                    style={{
                      textDecorationLine: "underline",
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                  >
                    Login
                  </Text>{" "}
                  to see bookmarked posts
                </Text>
              </View>
            </View>
          )}
        </View>
      );
    } else if (this.props.getTabView == "New") {
      return (
        <View>
          {this.props.cliksDetails &&
            this.props.cliksDetails.invite_only ==
            true ? (
            this.props.loginStatus == 1 ? (

              <NewHomeFeed
                navigation={this.props.navigation}
                listType={"Clik"}
                data={this.props.cliksDetails.id}
                ViewMode={this.state.ViewMode}
                listScroll={this.listScroll}
                ActiveTab={this.state.routes[this.state.index].title}
                doScroll={this.doScroll}
              />

            ) : (
              <View>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="sticky-note"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      onPress={() => this.loginHandle()}
                      style={{
                        textDecorationLine: "underline",
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Login
                    </Text>{" "}
                    to see New posts
                  </Text>
                </View>
              </View>
            )
          ) : (

            <NewHomeFeed
              navigation={this.props.navigation}
              listType={"Clik"}
              data={this.props.cliksDetails.id}
              ViewMode={this.state.ViewMode}
              listScroll={this.listScroll}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
            />

          )}
        </View>
      );
    } else if (this.props.getTabView == "Trending") {
      return (
        <View>
          {this.props.cliksDetails &&
            this.props.cliksDetails.invite_only ==
            true ? (
            this.props.loginStatus == 1 ? (

              <TrendingHomeFeed
                navigation={this.props.navigation}
                listType={"Clik"}
                data={this.props.cliksDetails.id}
                ViewMode={this.state.ViewMode}
                listScroll={this.listScroll}
                ActiveTab={this.state.routes[this.state.index].title}
                doScroll={this.doScroll}
              />

            ) : (
              <View>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="sticky-note"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      onPress={() => this.loginHandle()}
                      style={{
                        textDecorationLine: "underline",
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Login
                    </Text>
                    to see Trending posts
                  </Text>
                </View>
              </View>
            )
          ) : (

            <TrendingHomeFeed
              navigation={this.props.navigation}
              listType={"Clik"}
              data={this.props.cliksDetails.id}
              ViewMode={this.state.ViewMode}
              listScroll={this.listScroll}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
            />

          )}
        </View>
      );
    } else {
      return (

        <TrendingHomeFeed
          navigation={this.props.navigation}
          listType={"Clik"}
          data={this.props.navigation.getParam("id", "NO-ID").toLowerCase()}
          ViewMode={this.state.ViewMode}
          listScroll={this.listScroll}
          ActiveTab={this.state.routes[this.state.index].title}
          doScroll={this.doScroll}
        />

      );
    }
  };

  render() {
    return (
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          height: this.props.windowSize.height,
          marginLeft: 0,
          // marginLeft: Dimensions.get("window").width < 1200 && Dimensions.get("window").width >= 750 ? 13 : 0,
          backgroundColor: '#fff',

        }}
      >
        {Dimensions.get("window").width <= 750 ? (
          <ScrollView

            style={{
              flex: 1,
              width: "100%",
              height: "100%",
            }}
          >
            {this.renderTabViewForMobile()}
          </ScrollView>
        ) : (
          <View
            style={[ButtonStyle.DashboardTabViewStyle, {
              // width:
              //   Platform.OS == "web" &&
              //     Dimensions.get("window").width >= 750 && Dimensions.get("window").width <= 1200
              //     ? '100%' : Platform.OS == "web" && Dimensions.get("window").width >= 1200 ? '50%'
              //       : "100%",
              borderLeftWidth: 1,
              borderRightWidth: 1,

              width: Dimensions.get("window").width > 1200 && Dimensions.get("window").width < 1600 ? 450 :
                Dimensions.get("window").width > 1600 ? 600 : (Dimensions.get("window").width - 310),
              // marginLeft: Dimensions.get("window").width < 1200 && Dimensions.get("window").width >= 750 ? 10 : 0,

              borderColor: Colors.lightGray,
              backgroundColor: Colors.whiteBackground


            }]}
          >
            <View
              style={ButtonStyle.TabbarBorderStyleForDashboard}
            >
              {["New", "Trending", "Discussion"].map((e, index) => {
                return (
                  <TouchableOpacity key = {index} style={{
                    backgroundColor: 'transparent',


                  }}
                    onPress={() => {
                      this.setState({
                        focused: e
                      })
                    }}>
                    <Text style={[ButtonStyle.tabbarTitleStyle, {
                      color: this.state.focused == e ? Colors.blueColor : "#D3D3D3",
                      // color: "#D3D3D3",
                      flexWrap: "wrap",
                      flex: 1,
                      width: "100%",
                      fontWeight: this.state.focused == e ? 'bold' : '100'
                      // fontWeight: '100'
                    }]}>{e}</Text>
                  </TouchableOpacity>
                )
              })}
            </View>
            {this._renderScene()}
          </View>
        )}
        {this.props.windowSize.width > 1200 || this.props.show ?
          <View
            style={FeedCommentStyle.commentContainer}

          >
            <View style={{ width: '92%', marginLeft: 10 }}>

              <View style={{
                height: 55,
                alignItems: "center",
                paddingHorizontal: 10,
                paddingVertical: 10,
                marginLeft: 0,
                width: '100%',
                borderBottomWidth: 1,
                borderColor: '#d3d3d3',
                justifyContent: this.props.show ? 'flex-start' : 'center',
                alignItems: 'center',
                flexDirection: 'row'
                // borderTopWidth: 2,
              }}>
                {this.props.show == true ?
                  <TouchableOpacity style={[ButtonStyle.headerBackStyle, { width: '20%' }]}
                    onPress={() => this.props.showDiscussion(false)}>
                    <Icon
                      color={"#000"}
                      name="angle-left"
                      type="font-awesome"
                      size={40}

                    />
                  </TouchableOpacity> : null}
                < Text style={[ButtonStyle.tabbarTitleStyle, { width: this.props.show ? '55%' : '100%', textAlign: 'center' }]}> Discussion</Text>
              </View>
              {this.props.cliksDetails &&
                this.props.cliksDetails.invite_only ==
                true ? (
                this.props.loginStatus == 1 ? (

                  <CommentDetailScreen
                    type="clikProfile"
                    navigation={this.props.navigation}
                    postId={
                      this.props.PostDetails && this.props.PostDetails?.node?.id
                    }
                    listScroll={this.listScroll}
                    ProfileHeight={this.props.ProfileHeight}
                    commentDelay={this.props.commentDelay}
                    scrollY={this.props.scrollY}
                    ActiveTab={this.state.routes[this.state.index].title}
                  />

                  // null
                ) : null
              ) : (

                <CommentDetailScreen
                  type="clikProfile"
                  navigation={this.props.navigation}
                  postId={this.props.PostDetails && this.props.PostDetails?.node?.id}
                  listScroll={this.listScroll}
                  ProfileHeight={this.props.ProfileHeight}
                  commentDelay={this.props.commentDelay}
                  scrollY={this.props.scrollY}
                  ActiveTab={this.state.routes[this.state.index].title}
                />

                // null
              )}
            </View>
          </View>
          : null}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  cliksDetails: state.ClikProfileDetailsReducer.getTrendingCliksProfileDetails,
  // getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  // listClikMembers: !state.ClikMembersReducer.get("clikMemberList")
  //   ? List()
  //   : state.ClikMembersReducer.get("clikMemberList"),
  loginStatus: state.UserReducer.loginStatus,
  // profileData: state.LoginUserDetailsReducer.userLoginDetails,
  // getTabView: state.AdminTrueFalseReducer.get("tabType"),
  // PostDetails: state.PostDetailsReducer.get("PostDetails"),
  // TrendingClikHomeFeedList: state.HomeFeedReducer.get("TrendingClikHomeFeedList"),
  // show: state.showDiscussionReducer.show,
  windowSize: state.windowResizeReducer.windowResize,


});

const mapDispatchToProps = (dispatch) => ({
  // setHASSCROLLEDACTION: (payload) => dispatch(setHASSCROLLEDACTION(payload)),
  loginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  // // setPostCommentReset: (payload) =>
  // //   dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  // setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),
  // feedFluctuation: payload => dispatch(feedFluctuation(payload)),
  // showDiscussion: (payload) => dispatch(showDiscussion(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CliksProfileFeedScreen
);
