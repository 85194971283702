import ConstantFontFamily from "../../constants/FontFamily";
import { Dimensions, Platform } from "react-native";
import Colors from '../../constants/Colors';

const boxWidth = {
    width: '96%',
    marginLeft: Dimensions.get("window").width <= 1200 ? 8 : 15,
    backgroundColor: "#fff",
    paddingHorizontal: 5,
    paddingVertical: 20
}


export default {
    boxWidth

}