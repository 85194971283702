import React, { useEffect, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
} from "react-native";
import { Icon } from "react-native-elements";
import RNPickerSelect from "react-native-picker-select";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import applloClient from "../client";
import AppHelper from "../constants/AppHelper";
import ConstantFontFamily from "../constants/FontFamily";
import {
  KickClikMemberMutation,
  PromoteClikMemberMutation
} from "../graphqlSchema/FollowandUnFollowMutation";
import {
  KickClikMemberVariables,
  PromoteClikMemberVariables
} from "../graphqlSchema/FollowandUnfollowVariables";
import { clikMemberSuccess } from "../reducers/TrendingCliksProfileReducer";
// import { capitalizeFirstLetter } from "../library/Helper";
// import NavigationService from "../library/NavigationService";
import ButtonStyle from "../constants/ButtonStyle";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import { Hoverable } from "react-native-web-hooks";
import { leftPanelModalFunc } from '../reducers/AdminTrueFalseReducer'
import { Picker } from '@react-native-picker/picker';

const ClikProfileUserList = props => {
  const inputRefs = {};
  const [reqUserMemberChange, setReqUserMemberChange] = useState("MEMBER");
  const [tempType, settempType] = useState("");
  const [toast, setToast] = useState(false)
  let [clikUserList, setClikUserList] = useState([])

  const [RoleItems, setRoleItems] = useState([{
    label: "Member",
    value: "MEMBER",
    key: 0
  },
  {
    label: "Admin",
    value: "ADMIN",
    key: 1
  },
  {
    label: "Super Admin",
    value: "SUPER_ADMIN",
    key: 2
  }])

  useEffect(() => {
    setClikUserList(props.item)
  }, [])

  const promoteMember = async (id, username) => {
    let updatedMemberType = ''
    // if (reqUserMemberChange != "KICK_USER") {
    PromoteClikMemberVariables.variables.clik_id = props.ClikInfo.id
    PromoteClikMemberVariables.variables.user_id = id;
    PromoteClikMemberVariables.variables.member_type = reqUserMemberChange;
    if (reqUserMemberChange == 'ADMIN') {
      updatedMemberType = 'Admin'
    } else if (reqUserMemberChange == 'SUPER_ADMIN') {
      updatedMemberType = 'Super Admin'
    } else if (reqUserMemberChange == 'Member') {
      updatedMemberType = 'Member'
    }

    try {
      await applloClient
        .query({
          query: PromoteClikMemberMutation,
          ...PromoteClikMemberVariables,
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          // await props.setClikMembers({
          //   id: props.ClikInfo.id.replace("Clik:", "")
          // });
          // setToast(true)
          alert(`@${username}'s status has been changed to ${updatedMemberType}`);


        });
      // console.log(props.item, 'props.item.node');
      // if (props.item.node.member_type == "MEMBER" && reqUserMemberChange == "ADMIN") {
      //   alert("Promoted " + props.item.node.user.username + " to Admin")
      // } else if (props.item.node.member_type == "ADMIN" && reqUserMemberChange == "MEMBER") {
      //   alert("Demoted " + props.item.node.user.username + " to Memebr")
      // } else if ((props.item.node.member_type == "ADMIN" || props.item.node.member_type == "MEMBER") && reqUserMemberChange == "SUPER_ADMIN") {
      //   alert("Promoted " + props.item.node.user.username + " " + reqUserMemberChange.replace('_', " "))
      // }
    } catch (e) {
      console.log(e);
    }

    // } else {
    //   KickClikMemberVariables.variables.clik_id = props.ClikInfo.id
    //   KickClikMemberVariables.variables.user_id = id;
    //   try {
    //     await applloClient
    //       .query({
    //         query: KickClikMemberMutation,
    //         ...KickClikMemberVariables,
    //         fetchPolicy: "no-cache"
    //       })
    //       .then(async res => {
    //         console.log(res, 'resulttt');
    //         await props.setClikMembers({
    //           id: props.ClikInfo.id.replace("Clik:", "")
    //         });
    //         alert("Kicked " + props.item.node.user.username + " to " + props.ClikInfo.name)
    //         setToast(false)
    //       });

    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
  };

  const removeCurrentMember = (username) => {

    window.confirm(
      "Are you sure you want to remove " +
      "@" + username + ' from ' +
      '#' + props.ClikInfo.name + " ?")
    {
      KickClikMemberVariables.variables.clik_id = props.ClikInfo.id;
      KickClikMemberVariables.variables.user_id = username;
      try {
        applloClient
          .query({
            query: KickClikMemberMutation,
            ...KickClikMemberVariables,
            fetchPolicy: "no-cache"
          })
          .then(async res => {
            props.getCurrentMember(username)

          });
      } catch (e) {
        console.log(e);
      }
    }

  }

  const goToProfile = (username) => {
    props.userId({
      username: username,
      type: "feed",
    });
    props.navigation.navigate("profile", {
      username: username,
      type: "feed",
      postId: " ",
    });
    // props.setPostCommentReset({
    //   payload: [],
    //   postId: "",
    //   title: "",
    //   loading: true,
    // });
    props.leftPanelModalFunc(false);
  };

  const setTemporayType = (member, itemValue) => {
    // console.log(props.item, 'props.item');

    let newList = clikUserList.map(e => {
      if (e.node.user.id == member.node.user.id) {
        console.log(e, 'eeeeeee');
        return { ...e, node: { ...e.node, member_type: itemValue } }
      } else {
        return { ...e }
      }
    })
    setClikUserList(newList)
  }
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{
        backgroundColor: "#fff",
        paddingVertical: 10,
        paddingBottom: 20
      }}
    >
      {/* {toast ? <View style={{ zIndex: 999, position: "absolute", top: 0, right: 5, width: 150, backgroundColor: "#fff", borderRadius: 20 }}>
        <Text style={{ color: "#000" }}>@username's status has been changed to member type</Text>
      </View> : null} */}
      {clikUserList?.length > 0 && clikUserList.map((member, index) => {
        console.log(member, '===========')
        return (
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingVertical: 10
            }}
          >
            <Hoverable>
              {(isHovered) => (
                <TouchableOpacity
                  style={{
                    width: "55%",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                // onPress={() => goToProfile(props.item.node.user.username)}
                >
                  
                  <Text
                    style={{
                      color: "#000",
                      fontSize: 14,
                      fontWeight: "bold",
                      alignSelf: 'center',
                      marginLeft: 5,
                      fontFamily: ConstantFontFamily.defaultFont,
                      textDecorationLine:
                        isHovered == true ? "underline" : "none",
                    }}
                  >
                    @{member.node.user.username}
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>
            {(props.MemberType == "SUPER_ADMIN" || props.MemberType == "ADMIN") 
              && props.userDetails.username != member.node.user.username 
              ?
              <View
                style={{
                  flexDirection: "row",
                  width: '45%',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                <View
                  style={[ButtonStyle.shadowStyle, {
                    width: "66%",
                    justifyContent: "flex-end",
                    borderRadius: 5,
                    borderColor: "#d7d7d7",
                    borderWidth: 1,
                    marginLeft: 5,
                    height: 40,
                    marginTop: 5,
                    alignSelf: "center",
                    marginTop: 0,
                    shadowOpacity: 0.12,
                    marginBottom: 5
                  }]}
                >





                  <Picker
                    style={[ButtonStyle.dropdownBorder, { width: '100%', backgroundColor: '#fff', border: 0 }
                    ]}
                    color={'#fff'}
                    // ref={pickerRef}
                    selectedValue={tempType ? tempType : member.node.member_type}
                    onValueChange={(itemValue, itemIndex) => {
                      console.log(member, itemValue, 'itemValue');
                      setReqUserMemberChange(itemValue),

                        setTemporayType(member, itemValue)
                    }
                    }>
                    {RoleItems.map((item, index) => {
                      return (
                        <Picker.Item label={item.label} value={item.value} />

                      )
                    })
                    }
                  </Picker>

                </View>
                <View style={{
                  width: 40,
                  marginHorizontal: 15,
                  justifyContent: 'center'
                }}>


                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#000",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    name="save"
                    type="font-awesome"
                    size={18}
                    containerStyle={{
                      justifyContent: 'center',
                    }}
                    onPress={() =>
                      promoteMember(member.node.user.id, member.node.user.username)
                    }
                  />
                </View>

                {/* {props.MemberType == "SUPER_ADMIN" || props.MemberType == "ADMIN" ? */}
                <Icon
                  color={"#000"}
                  name="trash"
                  type="font-awesome"
                  size={18}
                  iconStyle={{
                    justifyContent: 'center'
                  }}
                  onPress={() => removeCurrentMember(member.node.user.username)}
                />

              </View>

              : null}
          </View>
        )
      })}

    </ScrollView >
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.UserProfileDetailsReducer.getCurrentUserProfileDetails,
})
const mapDispatchToProps = dispatch => ({
  setClikMembers: payload => dispatch(clikMemberSuccess(payload)),
  leftPanelModalFunc: (payload) => dispatch(leftPanelModalFunc(payload)),
  // setPostCommentReset: (payload) =>
  //   dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  React.memo(ClikProfileUserList)
);

const styles = StyleSheet.create({

  inputIOS: {
    paddingTop: 13,
    paddingHorizontal: 10,
    paddingBottom: 12,
    borderWidth: 0,
    borderRadius: 6,
    backgroundColor: "white",
    color: "black",
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },
  inputAndroid: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderWidth: 0,
    borderRadius: 6,
    color: "#000",
    backgroundColor: "white",
    paddingRight: 30,
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  }
});