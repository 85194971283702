import React, { useEffect, useState, useRef } from "react";
import {
  // AsyncStorage,
  Dimensions,
  Image,
  Platform,
  Text,
  TouchableOpacity,
  View,
  Modal,
  TextInput,
  Animated

} from "react-native";
import appolloClient from "../client";

import { connect } from "react-redux";
import { compose } from "react-recompose";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import ConstantFontFamily from "../constants/FontFamily";
import { Button, Icon } from "react-native-elements";
import Colors from "../constants/Colors";
import ButtonStyle from "../constants/ButtonStyle";
import { showMoreId, showbox } from "../reducers/ShowCommentReducer";
import { CreateCommentVariables } from "../graphqlSchema/LikeContentVariables";
import { CreateCommentMutation } from "../graphqlSchema/LikeContentMutation";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import SearchinputComment from './SearchInputComponent';
import CreateCommentStyle from "./Style/CreateCommentStyle";
import { screen } from '../reducers/ScreenNameReducer';
// import { indexOf } from "lodash";


const CreateCommentCardWeb = (props) => {

  const editorReference = useRef();
  const [title, settitle] = useState("");
  const [item, setitem] = useState([]);
  const [click, setclick] = useState(null);
  const [getBorderColor, setBorderColor] = useState("#e8e8e8");
  // const [editorState, onChange] = useState(EditorState.createEmpty());
  const [placeholderState, setPlaceholderState] = useState(true);
  const [titleContent, setTitleContent] = useState("char. required");
  const [getSubmitData, setSubmitData] = useState("");
  const [getLockStatus, SetLockStatus] = useState(false);
  const [getSelectedItem, setSelectedItem] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedCliks, setSelectedCliks] = useState({});
  const [opened, setOpened] = useState(false);
  const [getFeedItem, setFeedItem] = useState("");
  const [isReadOnly, setisReadOnly] = useState(false);
  const SearchComment = useRef(null)
  let [clikData, setClikData] = useState([])
  let [userData, setUserData] = useState([])
  let [cancel, setCancel] = useState(false)
  const [searchnput, setSearchInput] = useState(false)
  const [SubmitColor, setSubmitColor] = useState(false)
  const [ShowDummyText, setShowDummyText] = useState(true)
  const [displayBox, setdisplayBox] = useState(false)
  const [text, setText] = useState('')
  const [close, setClose] = useState(false)


  const comment = useRef(null)


  const submitComment = () => {
    // props.showbox({ id: "", show: false })
    setisReadOnly(false);
    let trimmedData = ""

    // if (props.initial == "main") {

    trimmedData = title
      ? title.replace(/  +/g, " ")
      : title;
    // } else {

    //   trimmedData = getSubmitData
    //     ? getSubmitData.replace(/  +/g, " ")
    //     : getSubmitData
    // }

    CreateCommentVariables.variables.text = trimmedData;
    CreateCommentVariables.variables.parent_content_id =
      props.parent_content_id;

    // if (props.initial != "main") {
    //   CreateCommentVariables.variables.clik = props.topComment.clik
    //     ? props.topComment.clik
    //     : null;
    // } else {
    //   if (click == null) {
    //     CreateCommentVariables.variables.clik = null;
    //   } else if (click == "Everyone" || click == "all") {
    //     CreateCommentVariables.variables.clik = null;
    //   } else {
    //     CreateCommentVariables.variables.clik = click
    //       .toLowerCase()
    //       .replace("#", "");
    //   }
    // }
    let clik = []
    let user1 = []
    clikData.map(e => {
      clik.push(e.id)
    })


    userData.map(e => {
      user1.push(e.id)
    })








    appolloClient
      .query({
        query: CreateCommentMutation,
        variables: {
          parent_id: props.pid,
          text: trimmedData,
          cliks: clik.length > 0 ? clik : null,
          users: user1.length > 0 ? user1 : null
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        settitle("");
        setclick(null);

        let prevCommentList = props.PostCommentDetails;

        let data = {
          node: {
            cliks: res.data.comment_create.discussion.cliks,
            comment: {
              author: res.data.comment_create.comment.author,
              created: res.data.comment_create.comment.created,
              id: res.data.comment_create.comment.id,
              num_comments: res.data.comment_create.comment.num_comments,
              num_likes: res.data.comment_create.comment.num_likes,
              num_reports: res.data.comment_create.comment.num_reports,
              text: res.data.comment_create.comment.text,
              comments: { edges: [] },
              path: res.data.comment_create.comment.path


            },
            users: res.data.comment_create.discussion.users,
            id: "Discussion:2767176656819853225",
            locked: res.data.comment_create.discussion.locked,
            private: res.data.comment_create.discussion.private,
            path: []
          }
          // id: props.parent_content_id
        }
        // console.log(props.PostCommentDetails);
        // props.setCommentStatus(true)
        props.showbox({ show: false, id: "", showmoreId: "" });
        if (props.showMore == 'no') {
          props.showMoreId({ id: "", showmoreId: '' })
        } else {
          props.showMoreId({ id: "", showmoreId: props.showMoreIdd })
        }

        if (props.pid.startsWith('Comment')) {

          let updatedArray = updateNestedArray1(prevCommentList, props.parent_content_id, data)
          // if (props.parent_content_id == props.parentId) {
          //   props.setNestedTotalComment({ comment: props.nestedComment + 1, parentId: props.parent_content_id, path: pathArray })
          // } else {
          //   props.setNestedTotalComment({ comment: 1, parentId: props.parent_content_id, path: pathArray })
          // }
          // props.setTotalComment(props.totalComment + 1)
          props.setPostCommentDetails([...updatedArray])
          // props.onClose()

        } else {

          // props.setTotalComment(props.totalComment + 1)
          // props.parentCommentId(props.parent_content_id)
          props.setPostCommentDetails([data, ...prevCommentList])

        }

        props.navigation.navigate("PostDetailScreen")

      })
      .catch((e) => {
        console.log(
          e.message.toLowerCase().replace("CreateCommentMutation error: ", "")
        );
      });
    setPlaceholderState(true);
    setSelectedCliks({});
    setSelectedUser([]);
  };

  const showClose = () => {
    setClose(true)
  }

  const handleUserDelete = (e) => {
    // console.log(userData,'eeeeee');
    let user = userData
    user.splice(user.indexOf(e), 1)

    // let user=[...userData]
    // console.log(user, 'iiii');
    setText('')
    setUserData([...user])
  }

  const handleClikDelete = (e) => {
    // console.log(userData,'eeeeee');
    let clik = clikData
    clik.splice(clik.indexOf(e), 1)
    setText('')

    // let user=[...userData]
    // console.log(user,'iiii');
    setClikData([...clik])
  }

  const showDisplayBox = (e) => {
    setText(e)
    if (e.startsWith('@')) {
      setdisplayBox(true)
    } else {
      setdisplayBox(false)

    }
  }
  console.log(props.profileData, 'props----------');
  return (
    <>
      {
        Dimensions.get("window").width <= 750 ? (
          <Animated.View
            style={{
              left: 0,
              right: 0,
              zIndex: 10,
              overflow: "hidden",
              borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,

            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: "100%",
                  height: 50,
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom:
                    Dimensions.get("window").width <= 750 ? 0 : 10,
                  backgroundColor: "#000",
                  borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
                }}
              >
                <TouchableOpacity
                  style={[
                    ButtonStyle.headerBackStyle,
                    {
                      width:"5%",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignSelf: "center",
                    },
                  ]}
                  onPress={() => {
                    props.screen('home')
                    props.navigation.goBack();
                  }}
                >
                  <Icon
                    color={"#fff"}
                    name="angle-left"
                    type="font-awesome"
                    size={40}
                  />
                </TouchableOpacity>
                {!props.getsearchBarStatus && (
                  <TouchableOpacity
                    style={{
                      flex: props.loginStatus
                        ? 1
                        : !props.loginStatus && Platform.OS == "web"
                          ? 1
                          : !props.loginStatus &&
                          Platform.OS != "web" &&
                          0,
                      justifyContent: "center",
                      marginLeft: 10
                    }}
                  >
                    <Text
                      style={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: 18,
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Discussions
                    </Text>
                  </TouchableOpacity>
                )}

              </View>
            </View>
          </Animated.View>
        ) : null
      }
      <Text style={{
        // color: "#fff",
        fontWeight: "bold",
        fontSize: 18,
        fontFamily: ConstantFontFamily.defaultFont,
        textAlign: "center",
        marginTop: 20
      }}>{props.Title}</Text>
      <View
        style={[
          // props.initial == "main" ? ButtonStyle.normalFeedStyleForDashboard : null,
          {
            backgroundColor: "#fff",
            marginTop: 15,
            // zIndex: 10,
            marginLeft: props.initial == "child" ? 0 : 0,
            // marginRight:'2%',
            borderRadius: 15,
            borderColor: "#d3d3d3",
            width: Dimensions.get("window").width >= 750 && props.initial == "main" ? '100%' : Dimensions.get("window").width >= 750 && props.initial != "main" ? '95%' : '95%',
            borderWidth: props.initial == "main" ? 1 : 0,
            // borderTopWidth: 1,
            marginBottom: props.initial == "main" ? 0 : 10,
            width: '100%',
            // shadowColor: "#d3d3d3",
            // shadowOffset: {
            //   width: 3,
            //   height: 3,
            // },

          },
        ]}
      >
        {props.initial == "main" ? <View style={{ width: "100%", flexDirection: "row", alignItems: 'center', marginTop: 10, justifyContent: 'space-evenly' }}>

          {getLockStatus ? (
            <View >
              <Icon
                size={25}
                name="lock"
                type="font-awesome"
                color="#000"
                underlayColor="#fff"
                onPress={() => {
                  !getLockStatus
                    ? alert(
                      "Discussion will be closed to users and cliks that were invited"
                    )
                    : alert("Discussion will be open to everyone");
                  SetLockStatus(false);
                }}
              />

            </View>
          ) : null}
          {!getLockStatus ? (
            <View  >
              <Icon
                size={25}
                name="unlock-alt"
                type="font-awesome"
                color="#000"
                underlayColor="#fff"
                onPress={() => {
                  !getLockStatus
                    ? alert(
                      "Discussion will be closed to users and cliks that were invited"
                    )
                    : alert("Discussion will be open to everyone");
                  SetLockStatus(true);
                }}
              />
            </View>
          ) : null}

          <View style={CreateCommentStyle.tagsMainView}
          >
            {clikData.length > 0 || userData.length > 0 ?
              <View style={CreateCommentStyle.tagWrapView}>
                {clikData.map(e => {

                  return (
                    <View style={[ButtonStyle.clikNameBackgroundStyle, {
                      marginLeft: 5,
                      backgroundColor: Colors.clikNamebackgroundColor,
                      flexDirection: "row",
                      flexWrap: 'wrap',
                    }]}
                      key={e.id}
                    >
                      <Text style={[ButtonStyle.clikNameTitleStyle,
                      {
                        paddingHorizontal: 5,
                        color: Colors.blueColor,

                      }]}>#{e.name}</Text>
                      <Icon
                        name="close" size={18} type="font-awesome"
                        iconStyle={{ marginLeft: 5, }}
                        onPress={() => {
                          handleClikDelete(e)
                        }}

                      />
                    </View>
                  )
                })}
                {userData.map(e => {
                  console.log(e, 'eeeeeee');
                  return (
                    <View style={[ButtonStyle.clikNameBackgroundStyle, {
                      marginLeft: 10,
                      flexDirection: "row"

                    }]}
                      key={e.id}>
                      <Text style={[ButtonStyle.clikNameTitleStyle,
                      {
                        paddingHorizontal: 5,
                      }]}>{e.username}</Text>
                      <Icon
                        name="close" size={18} type="font-awesome"
                        iconStyle={{ marginLeft: 5, }}
                        onPress={() => {
                          handleUserDelete(e)
                        }}

                      />
                    </View>
                  )
                })}
              </View>
              :
              <View style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", width: "90%" }}>
                <TextInput placeholder="Tag @users"
                  style={{ outline: "none" }}
                  onFocus={() => showClose()}
                  value={text} onChangeText={(e) => showDisplayBox(e)} />
              </View>
            }
            {close ?
              <Icon
                name="close" size={18} type="font-awesome"
                iconStyle={{ marginHorizontal: 5, }}
                onPress={() => {
                  setdisplayBox(false),
                    setText('')
                }}

              /> : null}
          </View>

          <TouchableOpacity onPress={() => props.showbox({ id: "", show: false })}>
            <Icon
              size={20}
              name="trash-bin"
              type="ionicon"
              color="red"
              underlayColor="#fff"

            />
          </TouchableOpacity>

        </View> : null}
        <View style={{
          height: 250,
          position: 'relative'
        }}>
          {displayBox ?
            <View style={{
              height: 100,
              backgroundColor: '#fff',
              flex: 1,
              position: "absolute",
              zIndex: 5, width: '85%', marginLeft: 44
            }}>
              <SearchinputComment
                input={text}
                setClikData={setClikData}
                setUserData={setUserData}
                setdisplayBox={setdisplayBox}
              />

            </View> : null}
          {title.length > 0 ?
            <View style={{ justifyContent: "flex-end", alignSelf: "flex-end", marginTop: 10, marginBottom: 10, marginRight: 30, height: 30 }}>
              {props.pid.startsWith('Post') && title.length < 3000 && (
                <Text
                  style={{
                    color: title.length < 25 ? "#de5246" : "#009B1A",
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont,
                    alignSelf: "center",
                    marginRight: 10,
                  }}
                >
                  {title.length < 25 ? `${25 - title.length} ${titleContent}`
                    : title.length < 145 ? `${144 - title.length} char. left` : null}

                </Text>
              )}
            </View> : null}

          {props.initial == "main" ? <View style={{
            minHeight: 175,
            maxHeight: hp('55%'),

          }}>

            <TextInput
              style={[CreateCommentStyle.textInputStyle, {
                marginTop: 75,
                // props.initial == "main" && displayBox ? 50 :
                // props.initial == "main"  && title.length == 0 ? 75 :
                //   props.initial == "main"  && title.length > 0 ? 25 :25,
                // 10,
                paddingLeft: 20
              }]}
              value={title}
              onChangeText={(e) => {
                if (e.length > 0) {
                  setShowDummyText(false)
                } else {
                  setShowDummyText(true)
                } settitle(e)
              }}
              ref={comment}
              multiline={true}
              maxLength={props.initial == "main" ? 144 : 3000}
              placeholder={props.initial == "main" ? 'Write a succinct discussion topic that is \n\u2022 debatable\n\u2022 thought provoking.' : "write one insightful comment"}
              placeholderTextColor='#d3d3d3'

            />

          </View> :
            <View style={{
              marginTop: 20,
              // borderWidth:2,
              // borderRadius:20,
              width: "95%",
              alignSelf: "flex-end",
              marginRight: 5,
              borderColor: "#d3d3d3"
            }}>

              <View style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}>
                <Image
                  source={props?.profileData?.data?.user?.profile_pic ? { uri: props?.profileData?.data?.user?.profile_pic } : require("../assets/image/logolastOne.png")}
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 6
                  }}
                />
                <Text style={{ marginLeft: 5 }}>{props?.profileData?.data?.user?.username}</Text>
              </View>

              <TextInput
                style={{
                  minHeight: 140,
                  maxHeight: hp('55%'),
                  fontSize: 15,
                  color: "#000",
                  marginTop: 2,
                  borderWidth: 1,
                  borderColor: '#d3d3d3',
                  marginTop: 10,
                  padding: 10,
                  fontStyle: ConstantFontFamily.defaultFont,
                  borderRadius: 15,
                  outline: 'none'
                }}
                value={title}
                onChangeText={(e) => {
                  if (e.length > 0) {
                    setShowDummyText(false)
                  } else {
                    setShowDummyText(true)
                  } settitle(e)
                }}
                ref={comment}
                multiline={true}
                maxLength={props.initial == "main" ? 144 : 3000}



              />

            </View>}
          {!props.pid.startsWith('Post') ?
            <View style={{ flexDirection: "row", alignItems: "center", height: 30 }}>
              {title.length < 3000 && (
                <Text
                  style={{
                    color: title.length < 140 ? "#de5246" : "#009B1A",
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont,
                    alignSelf: "center",
                    marginRight: 10,
                    marginLeft: 20
                  }}
                >
                  {title
                    ? title.length < 144
                      ? `${144 - title.length} ${titleContent}`
                      : `${3000 - title.length} char. left`
                    : ""}
                </Text>
              )}

            </View> : null}

        </View>
        <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginBottom: 10 }}>



          <TouchableOpacity

            style={{
              marginRight: 10, borderWidth: 1,
              width: 130, height: 30,
              borderRadius: 20,
              justifyContent: "center", alignItems: "center", alignSelf: "center", borderColor: Colors.blueColor,
              backgroundColor: "#D0DAF8",
              opacity: props.initial == "main" && title.length < 25 ? 0.3 : props.initial != "main" && title.length < 145 ? 0.3 : 1,
              marginTop: props.initial == "main" ? 10 : 20
            }}
            disabled={(props.initial == "main" && title.length < 25) ? true : (props.initial != "main" && title.length < 145) ? true : false}
            onPress={() => {
              if (props.initial == "main") {
                submitComment();

              } else {

                submitComment();
                //   onChange(
                //     EditorState.push(
                //       editorState,
                //       ContentState.createFromText("")
                //     )
                //   );

              }

            }}>
            <Text style={[ButtonStyle.titleStyle, { color: Colors.blueColor }]}>Submit</Text>

          </TouchableOpacity>


        </View>
      </View>
    </>
  )
}

const mapStateToProps = (state) => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  // PostCommentDetails: state.PostCommentDetailsReducer.get("PostCommentDetails"),
  // loginStatus: state.UserReducer.get("loginStatus"),
  // getfocusCreateComment: state.AdminTrueFalseReducer.get("focusCreateComment"),
  // user: state.UserProfileDetailsReducer.get(
  //   "getCurrentUserProfileDetails"
  // ),
  // totalComment: state.TotalCommentReducer.totalComment,
  // nestedComment: state.NestedCommentReducer.nestedComment,
  // parentId: state.NestedCommentReducer.parentId,
  // path1: state.NestedCommentReducer.path,
  // HighlightId: state.commentIdReducer.id,
  // UserHighlight: state.UserHighlight.userHighlight,
  // Title: state.PostCommentDetailsReducer.get("Title"),
  // TrendingHomeFeedList: state.HomeFeedReducer.get('TrendingHomeFeedList'),
  // NewHomeFeedList: state.HomeFeedReducer.get('NewHomeFeedList'),
  // DiscussionHomeFeedList: state.HomeFeedReducer.get('DiscussionHomeFeedList'),
  // TrendingTopicHomeFeedList: state.HomeFeedReducer.get('TrendingTopicHomeFeedList'),
  // TrendingClikHomeFeedList: state.HomeFeedReducer.get('TrendingClikHomeFeedList'),
  // TrendingExternalFeedHomeFeedList: state.HomeFeedReducer.get('TrendingExternalFeedHomeFeedList'),
  // TrendingUserHomeFeedList: state.HomeFeedReducer.get('TrendingUserHomeFeedList'),
  // windowSize: state.windowResizeReducer.windowResize,
  // commentTitle: state.getCommentTitleReducer.getTitle,
  // setZindexzero: state.AdminTrueFalseReducer.get("setZindexzero"),
  PostDetails: state.PostDetailsReducer.PostDetails,
  PostCommentDetails: state.PostCommentDetailsReducer.PostCommentDetails,
  PostId: state.PostCommentDetailsReducer.PostId,
  Title: state.PostCommentDetailsReducer.Title,
  Loading: state.PostCommentDetailsReducer.Loading,
  show: state.ShowCommentReducer.show,
  showId: state.ShowCommentReducer.id,
  // commentTitle: state.getCommentTitleReducer.getTitle,
  loginStatus: state.UserReducer.loginStatus,
  windowSize: state.windowResizeReducer.windowResize,
  Title: state.PostCommentDetailsReducer.Title,
  pid: state.ShowCommentReducer.id,
  screen: state.screenNameReducer.screen




});

const mapDispatchToProps = (dispatch) => ({
  // userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
  // showbox: (payload) => dispatch(showbox(payload)),
  // setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  // focusCreateComment: (payload) =>
  //   dispatch({ type: "FOCUS_CREATE_COMMENT", payload }),
  // setTotalComment: (payload) => dispatch(setTotalComment(payload)),
  // setNestedTotalComment: (payload) => dispatch(setNestedTotalComment(payload)),
  // setCommentStatus: (payload) => dispatch(setCommentStatus(payload)),
  // showbox: (payload) => dispatch(showbox(payload)),
  // parentCommentId: (payload) => dispatch(parentCommentId(payload)),
  // setPostShareModel: (payload) =>
  //   dispatch({ type: "POSTSHARESTATUS", payload }),
  // showMoreComment: (payload) => dispatch(showMoreComment(payload)),
  // showMoreId: (payload) => dispatch(showMoreId(payload)),
  // setZindexZero: payload => dispatch({ type: "SET_ZINDEX", payload })


  setPostCommentReset: (payload) =>
    dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  setGoogleLogin: payload => dispatch({ type: "SET_GOOGLE_LOGIN", payload }),
  setAnonymousUser: payload =>
    dispatch({ type: "ANONYMOUS_USER", payload }),
  setUserApproachAction: payload => dispatch(setUserApproachAction(payload)),
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  setUsernameModalStatus: (payload) =>
    dispatch(setUSERNAMEMODALACTION(payload)),
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  showbox: (payload) => dispatch(showbox(payload)),
  setPostShareModel: (payload) =>
    dispatch({ type: "POSTSHARESTATUS", payload }),
  showMoreId: (payload) => dispatch(showMoreId(payload)),
  screen: (payload) => dispatch(screen(payload)),


});


export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateCommentCardWeb
);


function updateNestedArray1(input, parentId, newData) {

  // console.log(input, parentId, newData, 'newwww');


  let repliedData = {
    node: {
      author: newData.node.comment.author,
      comments: { edges: [] },
      created: newData.node.comment.created,
      num_comments: newData.node.comment.num_comments,
      id: newData.node.comment.id,
      num_likes: newData.node.comment.num_likes,
      text: newData.node.comment.text,
      num_reports: newData.node.comment.num_reports,
      path: newData.node.comment.path,
      __typename: "Comment"

    }

  }

  let repliedData1 = []
  let newArray = []
  let n1 = []
  let neww1 = []
  let neww2 = []

  input.map(e => {
    if (e.node.comment.id == parentId) {
      repliedData1 = e.node.comment.comments.edges
      // let arr = repliedData1.unshift(repliedData)
      n1 = [repliedData, ...repliedData1]



      newArray = input

      neww1 = newArray.map(e => {


        e = {
          ...e, node: {
            ...e.node, comment: {
              ...e.node.comment, comments: {
                ...e.node.comment.comments, edges: n1
              }
            }
          }
        }


        return e

      })

      return neww1
    } else {


      let n = arr(e.node.comment.comments.edges, e)


      newArray = input
      neww1 = newArray.map(e => {


        e = {
          ...e, node: {
            ...e.node, comment: {
              ...e.node.comment, comments: {
                ...e.node.comment.comments, edges: n
              }
            }
          }
        }


        return e

      })

      // console.log(neww1,'neww1neww1neww1neww1');

      return neww1
      // return newArray
    }
  })


  function arr(item, e) {
    let replied2 = []
    item.map(i => {
      if (i.node.id == parentId) {

        replied2 = [repliedData, ...i.node.comments.edges]

        newArray = item

        neww2 = newArray.map(e => {


          e = {
            ...e, node: {
              // ...e.node, comment: {
              ...e.node, comments: {
                ...e.node.comments, edges: replied2
              }
              // }
            }
          }


          return e

        })


        return neww2
      } else {
        arr(i.node.comments.edges, e)
      }
    })
    return neww2
  }



  return neww1

}