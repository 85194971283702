import React, { Component } from "react";
import ConstantFontFamily from "../constants/FontFamily";
import {
  Animated,
  Dimensions,
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  FlatList,
  Keyboard,
} from "react-native";
import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import {
  SearchClikMutation,
  SearchEveryThingMutation,
  SearchFeedMutation,
  SearchTopicMutation,
  SearchUserMutation,
} from "../graphqlSchema/SearchMutation";
import { UserLoginMutation } from "../graphqlSchema/UserMutation";
import {
  ClikFollowVariables,
  ClikUnfollowVariables,
  FeedFollowVariables,
  FeedUnFollowVariables,
  TopicFollowVariables,
  TopicUnFollowVariables,
  UserFollowVariables,
  UserUnfollowVariables,
} from "../graphqlSchema/FollowandUnfollowVariables";
import {
  SearchClikVariables,
  SearchEveryVariables,
  SearchFeedVariables,
  SearchTopicVariables,
  SearchUserVariables,
} from "../graphqlSchema/SearchVariables";
import appolloClient from "../client";
import { AddTerm, TermConcat } from "../reducers/AddtermReducer";
import { IsCompleted,searchOpenBarStatus,setDisplayType } from '../reducers/AdminTrueFalseReducer';
import { setTermWeb, setTerm } from '../reducers/ScreenLoadingReducer';


class SearchInputWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: "",
      keyEvent: "",
      boxColor: '#333333',
      isFocus: false,
      clearInput: false,
      updatedData: [],
      drop: false,
      clikOutsude: true,
    };
    // this.nameInput = {};
    // this.nameInput = React.createRef();
    // this.handlePress = this.handlePress.bind(this);
  }

  // handlePress = () => {
  //   this.props.navigation.navigate("search");
  // };

  // getTopicStar = (TopicName) => {
  //   let index = 0;
  //   index = this.props.getUserFollowTopicList.findIndex(
  //     (i) =>
  //       i.getIn(["topic", "name"]).toLowerCase().replace("topic:", "") ==
  //       TopicName.toLowerCase().replace("topic:", "")
  //   );
  //   if (index == -1) {
  //     return "TRENDING";
  //   } else {
  //     return this.props.getUserFollowTopicList.getIn([
  //       index,
  //       "settings",
  //       "follow_type",
  //     ]);
  //   }
  // };

  // getClikStar = (ClikName) => {
  //   let index = 0;
  //   index = this.props.getUserFollowCliksList.findIndex(
  //     (i) =>
  //       i.getIn(["clik", "name"]).toLowerCase().replace("clik:", "") ==
  //       ClikName.toLowerCase().replace("clik:", "")
  //   );
  //   if (index == -1) {
  //     return "TRENDING";
  //   } else {
  //     return this.props.getUserFollowCliksList.getIn([
  //       index,
  //       "settings",
  //       "follow_type",
  //     ]);
  //   }
  // };

  // favroiteCliks = async (cliksId) => {
  //   ClikFollowVariables.variables.clik_id = cliksId;
  //   ClikFollowVariables.variables.follow_type = "FAVORITE";
  //   appolloClient
  //     .query({
  //       query: ClikFollowMutation,
  //       ...ClikFollowVariables,
  //       fetchPolicy: "no-cache",
  //     })
  //     .then(async (res) => {
  //       let resDataLogin = await this.props.Login();
  //       await this.props.saveLoginUser(resDataLogin.data.login);
  //     });
  // };

  // unfollowCliks = async (cliksId) => {
  //   ClikUnfollowVariables.variables.clik_id = cliksId;
  //   appolloClient
  //     .query({
  //       query: ClikUnfollowMutation,
  //       ...ClikUnfollowVariables,
  //       fetchPolicy: "no-cache",
  //     })
  //     .then(async (res) => {
  //       let resDataLogin = await this.props.Login();
  //       await this.props.saveLoginUser(resDataLogin.data.login);
  //     });
  // };

  // getUserStar = (UserName) => {
  //   let index = 0;
  //   index = this.props.getUserFollowUserList.findIndex(
  //     (i) =>
  //       i.getIn(["user", "username"]).toLowerCase().replace("user:", "") ==
  //       UserName.toLowerCase().replace("user:", "")
  //   );
  //   if (index == -1) {
  //     return "TRENDING";
  //   } else {
  //     return this.props.getUserFollowUserList.getIn([
  //       index,
  //       "settings",
  //       "follow_type",
  //     ]);
  //   }
  // };

  // getFeedStar = (Name) => {
  //   let index = 0;
  //   index = this.props.getUserFollowFeedList.findIndex(
  //     (i) =>
  //       i.getIn(["feed", "name"]).toLowerCase().replace("ExternalFeed:", "") ==
  //       Name.toLowerCase().replace("ExternalFeed:", "")
  //   );
  //   if (index == -1) {
  //     return "TRENDING";
  //   } else {
  //     return this.props.getUserFollowFeedList.getIn([
  //       index,
  //       "settings",
  //       "follow_type",
  //     ]);
  //   }
  // };

  // favroiteFeed = async (Id) => {
  //   FeedFollowVariables.variables.feed_id = Id;
  //   FeedFollowVariables.variables.follow_type = "FAVORITE";
  //   appolloClient
  //     .query({
  //       query: FeedFollowMutation,
  //       ...FeedFollowVariables,
  //       fetchPolicy: "no-cache",
  //     })
  //     .then(async (res) => {
  //       let resDataLogin = await this.props.Login();
  //       await this.props.saveLoginUser(resDataLogin.data.login);
  //     });
  // };

  // unfollowFeed = async (Id) => {
  //   FeedUnFollowVariables.variables.feed_id = Id;
  //   appolloClient
  //     .query({
  //       query: FeedUnFollowMutation,
  //       ...FeedUnFollowVariables,
  //       fetchPolicy: "no-cache",
  //     })
  //     .then(async (res) => {
  //       let resDataLogin = await this.props.Login();
  //       await this.props.saveLoginUser(resDataLogin.data.login);
  //     });
  // };

  // favroiteTopics = async (topicId) => {
  //   FollowVariables.variables.id = topicId;
  //   FollowVariables.variables.follow_type = "FAVORITE";
  //   appolloClient
  //     .query({
  //       query: FollowMutation,
  //       ...FollowVariables,
  //       fetchPolicy: "no-cache",
  //     })
  //     .then(async (res) => {
  //       let resDataLogin = await this.props.Login();
  //       await this.props.saveLoginUser(resDataLogin.data.login);
  //     });
  // };

  // unfollowTopics = async (topicId) => {
  //   TopicUnFollowVariables.variables.topic_id = topicId;
  //   appolloClient
  //     .query({
  //       query: TopicUnFollowMutation,
  //       ...TopicUnFollowVariables,
  //       fetchPolicy: "no-cache",
  //     })
  //     .then(async (res) => {
  //       let resDataLogin = await this.props.Login();
  //       await this.props.saveLoginUser(resDataLogin.data.login);
  //     });
  // };

  // followTopics = (topicId) => {
  //   if (this.props.loginStatus == 0) {
  //     this.props.setLoginModalStatus(true);
  //     return false;
  //   }
  //   FollowVariables.variables.id = topicId;
  //   FollowVariables.variables.follow_type = "FOLLOW";
  //   appolloClient
  //     .query({
  //       query: FollowMutation,
  //       ...FollowVariables,
  //       fetchPolicy: "no-cache",
  //     })
  //     .then(async (res) => {
  //       let resDataLogin = await this.props.Login();
  //       await this.props.saveLoginUser(resDataLogin.data.login);
  //     });
  // };
  componentDidMount() {
    this.props.setTerm(this.state.term);
    this.props.setKeyEvent(this.state.keyEvent);
    this.props.setDisplayType("");
    this.props.TermConcat([]);
    this.props.IsCompleted(this.state.drop);
    if (Dimensions.get('window').width <= 750) {
      this.props.nameInput.current.focus()
    }

    if (this.props.term && this.props.term.length > 0) {
      this.props.TermConcat(this.props.termArray.termArray)

    } else {
      // this.setState({ addArray: [] })
      this.props.TermConcat([])
    }
    if (this.props.termWeb && this.props.termWeb.length > 0) {
      // this.setState({ addArray: this.props.termArray.termArray }
      this.props.TermConcat(this.props.termArray.termArray)
    } else {
      // this.setState({ addArray: [] })
      this.props.TermConcat([])
    }

    this.setState({ updatedArray: this.props.termArray.termArray })
    this.props.TermConcat([])
    if(this.props.term.length>0){
    this.setState({term:this.props.termArray.termArray.toString().replace(',','')})

    }else{
      this.props.TermConcat([])
    }
    if (window.location.href.includes('search') || this.props.screenName == "search") {
      this.props.nameInput.current.focus()
    }
    if (this.props.screenName == "home") {
      this.setState({
        boxColor: "#333333"
      })
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.term != this.props.term) != "") {
      this.setState({ term: this.props.term, isFocus: true });
      // this.props.navigation.navigate('search')
      if (this.props.term && this.props.term.length > 0) {
        // this.setState({ addArray: this.props.termArray.termArray })
      } else {
        // this.setState({ addArray: [] })
        this.props.TermConcat([]);
      }
      // if (this.props.term && this.props.term.length > 0) {
      //   this.props.TermConcat(this.props.termArray.termArray)

      // } else {
      //   // this.setState({ addArray: [] })
      //   this.props.TermConcat([])
      // }
      // // if (this.props.termWeb && this.props.termWeb.length > 0) {
      // //   // this.setState({ addArray: this.props.termArray.termArray }
      // //   this.props.TermConcat(this.props.termArray.termArray)
      // // } else {
      // //   // this.setState({ addArray: [] })
      // //   this.props.TermConcat([])
      // // }
    }
  }

  handleEvent = async (e) => {
    let queryTerm = "";
    var code = e.keyCode || e.which;
    this.props.setKeyEvent(code);
    this.setState({ keyEvent: code });
    this.props.setDisplayType(this.props.displayType);
    var NewTerm = this.state.term.split(" ");
    var t = [];

    for (var i = 0; i <= NewTerm.length; i++) {
      if (NewTerm[i] != null && NewTerm[i].startsWith("@") == true) {
        t.push(NewTerm[i].replace("@", ""));
      } else if (NewTerm[i] != null && NewTerm[i].startsWith("#") == true) {
        t.push(NewTerm[i].replace("#", ""));
      } else if (NewTerm[i] != null && NewTerm[i].startsWith("/") == true) {
        t.push(NewTerm[i].replace("/", ""));
      } else if (NewTerm[i] != null && NewTerm[i].startsWith("%") == true) {
        t.push(NewTerm[i].replace("%", ""));
      } else if (NewTerm[i] == null) {
        t = t;
      } else {
        t.push(NewTerm[i]);
      }
    }
    //this.props.onkeypress1(e);

    if (e.nativeEvent.key == "Enter") {
      queryTerm = t.join("");
      this.props.navigation.navigate("search");
    }
    // if (e.nativeEvent.key === "Backspace") {
    //   await this.setState({ drop: true })
    //   await this.props.IsCompleted(this.state.drop)

    // }
  };

  getRespectiveSuggestions = async (value) => {
    console.log(value,'vvvvv');
    // var TotalTerm = value.split(" ");
    var TotalTerm = value.split(" ");

    for (var i = 0; i < TotalTerm.length; i++) {
      if (TotalTerm[i].length > 0) {
        if (
          TotalTerm[i].slice(-5).startsWith("#") == true ||
          this.state.index == 1
        ) {
          await this.setState({
            TopicSuggestList: [],
            UserSuggestList: [],
            FeedSuggestList: [],
            drop: false,
          });
          await this.props.IsCompleted(this.state.drop);
          await this.getClikSuggestion(TotalTerm[i].replace("#", ""));
        } else if (
          TotalTerm[i].startsWith("/") == true ||
          this.state.index == 2
        ) {
          await this.setState({
            ClikSuggestList: [],
            UserSuggestList: [],
            FeedSuggestList: [],
            drop: false,
          });
          await this.props.IsCompleted(this.state.drop);
          await this.getTopicSuggestion(TotalTerm[i].replace("/", ""));
        } else if (
          TotalTerm[i].slice(-5).startsWith("@") == true ||
          this.state.index == 3
        ) {
          await this.setState({
            TopicSuggestList: [],
            ClikSuggestList: [],
            FeedSuggestList: [],
            drop: false,
          });
          await this.props.IsCompleted(this.state.drop);
          await this.getUserSuggestion(TotalTerm[i].replace("@", ""));
        } else if (
          TotalTerm[i].slice(-5).startsWith("%") == true ||
          this.state.index == 4
        ) {
          await this.setState({
            TopicSuggestList: [],
            ClikSuggestList: [],
            UserSuggestList: [],
            drop: false,
          });
          await this.props.IsCompleted(this.state.drop);
          await this.getFeedSuggestion(TotalTerm[i].replace("%", ""));
        } else {
          this.props.IsCompleted(true);
          await this.setState({
            TopicSuggestList: [],
            ClikSuggestList: [],
            UserSuggestList: [],
            FeedSuggestList: [],
            drop: true,
          });
          await this.props.IsCompleted(this.state.drop);
        }
      } else {
        await this.setState({ drop: true });
        await this.props.IsCompleted(this.state.drop);
      }
    }
  };
  getFeedSuggestion = (value) => {
    SearchFeedVariables.variables.prefix = value.replace("-", " ");
    appolloClient
      .query({
        query: SearchFeedMutation,
        ...SearchFeedVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.feeds;
        this.setState({
          FeedSuggestList: data,
          TopicSuggestList: [],
          ClikSuggestList: [],
          UserSuggestList: [],
        });
      });
  };

  getTopicSuggestion = (value) => {
    SearchTopicVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchTopicMutation,
        ...SearchTopicVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.topics;
        this.setState({
          TopicSuggestList: data,
          ClikSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  getClikSuggestion = (value) => {
    SearchClikVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchClikMutation,
        ...SearchClikVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.cliks;
        this.setState({
          ClikSuggestList: data,
          TopicSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  getUserSuggestion = (value) => {
    SearchUserVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchUserMutation,
        ...SearchUserVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.users;
        this.setState({
          UserSuggestList: data,
          TopicSuggestList: [],
          ClikSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  handleArray = async (value) => {
    // let prevArray = this.props.termArray.termArray
    // console.log(value, 'value');
    if (Platform.OS == "web") {
      let updated = [];
      var updatedArray = value.split(" ");
      for (var i = 0; i < updatedArray.length; i++) {
        if (updatedArray[i].startsWith("@") == true || this.state.index == 3) {
          this.getRespectiveSuggestions(updatedArray[i]);
          this.props.setTerm(value);
          this.props.setTermWeb(value);
          if (updatedArray[i].length > 1) {
            // this.props.IsCompleted(false)
          }
          if (updatedArray[i].length > 4) {
            updated.push(updatedArray[i]);
          }
        } else if (
          updatedArray[i].startsWith("#") == true ||
          this.state.index == 1
        ) {
          this.getRespectiveSuggestions(updatedArray[i]);
          this.props.setTerm(value);
          this.props.setTermWeb(value);
          if (updatedArray[i].length > 1) {
            // this.props.IsCompleted(false)
          }
          if (updatedArray[i].length > 4) {
            updated.push(updatedArray[i]);
          }
        } else if (
          updatedArray[i].startsWith("/") == true ||
          this.state.index == 2
        ) {
          this.getRespectiveSuggestions(updatedArray[i]);
          this.props.setTerm(value);
          this.props.setTermWeb(value);
          if (updatedArray[i].length > 1) {
            // this.props.IsCompleted(false)
          }
          if (updatedArray[i].length > 4) {
            updated.push(updatedArray[i]);
          }
        } else if (
          updatedArray[i].startsWith("%") == true ||
          this.state.index == 4
        ) {
          this.getRespectiveSuggestions(updatedArray[i]);
          this.props.setTerm(value);
          this.props.setTermWeb(value);
          if (updatedArray[i].length > 1) {
            // this.props.IsCompleted(false)
          }
          if (updatedArray[i].length > 4) {
            updated.push(updatedArray[i]);
          }
        } else {
          this.getRespectiveSuggestions(updatedArray[i]);
          updated.push(updatedArray[i]);
          this.props.TermConcat(updated);
          this.props.setTerm(value);
          this.props.setTermWeb(value);
        }
      }
    } else {
      await this.props.setTerm(value);
      await this.props.setTermWeb(value);
    }
  };

  render() {
    // console.log(this.props.termWeb, 'this.props.termWeb');
    // console.log(this.props.term, 'termterm');
    // console.log(this.state.term, 'this.state.term');
    return (
    

      <View style={{ width: '100%' }}>
        <View
          style={{
            backgroundColor: '#000',
            // width:
            //   Dimensions.get("window").width <= 750
            //     ? Dimensions.get("window").width - 70
            //     : Dimensions.get("window").width >= 750 &&
            //       Dimensions.get("window").width <= 1200
            //       ? Dimensions.get("window").width - 100
            //       : 445,
            width: "100%",
            height: 55,
            // transform: Dimensions.get("window").width <= 1200 && [{ translateX: -11 }],
            borderRadius: 6,
            borderWidth: 0,
            flexDirection: "row",
            borderColor: this.state.isFocus ? "#388bfd" : "#444D56",
            // borderWidth: 0.2,
            justifyContent: "space-between",
            alignItems: "center",
            transition: "width 200ms",
            // paddingHorizontal: "2%",
            elevation: 5,

            // marginHorizontal:
            // Platform.OS =='web' && Dimensions.get("window").width <=750 ? 10 :
            // Platform.OS !='web' ? '10%' : 0,
          }}
        >
          {/* <OuterClick
            onOuterClick={() => {
              if (this.props.termWeb == '' || this.props.termWeb == '' || this.state.term == '' || this.props.term) {
                this.setState({
                  boxColor: "#333333",
                  isFocus: false,
                });
              } else {
                this.setState({
                  boxColor: "#fff",
                  isFocus: false,
                });
              }

            }}
            style={{ flexDirection: "row", width: "100%", alignItems: "center" }}
          > */}
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              borderRadius: 6,
              backgroundColor: this.state.isFocus ? '#fff' : '#333333',
            }}
          >
            <Icon
              iconStyle={{ paddingLeft: 5, width: 20 }}
              name="search"
              color={this.state.boxColor == "#fff" ? "#333333" : "#717071"}
              size={18}
              type="font-awesome"
            />
            <TextInput
              style={{
                width: "85%",
                paddingLeft: 15,
                height: 34,
                borderColor: "#388bfd",
                borderWidth: 0,
                borderRadius: 6,
                backgroundColor: this.props.boxColor,
                outline: "none",
                color: "#000",
                fontSize: 14,
                fontFamily: ConstantFontFamily.defaultFont,
              }}
              // ref={(input) => { this.nameInput = input; }}
              ref={this.props.nameInput}
              placeholder="Search Weclikd"
              placeholderTextColor={this.state.boxColor == "#fff" ? "#d3d3d3" : "#fff"}
              onChangeText={(term) => {
                // console.log(term, 'termmmm');
                this.setState({ term: term }, () => {
                  // this.getRespectiveSuggestions(this.state.term)
                  // this.props.setTerm(this.state.term);
                  // this.props.IsCompleted(false)
                  // this.props.TermConcat(term.split("",","))
                  // this.props.TermConcat([])
                  this.handleArray(term);
                });
              }}
              value={
                this.props.termWeb ? this.props.termWeb : this.props.term ? this.props.term : this.state.term
              }
              // value={this.props.addTermArray.termArr}
              // value={this.state.term}
              onKeyPress={this.handleEvent}
              // focus={this.props.focus.isFocus}
              // onSubmitEditing={async () => {
              //   //   await this.props.searchOpenBarStatus(false);
              //   await this.props.setTerm(this.state.term);
              //   Keyboard.dismiss,
              //     NavigationService.navigate("search");
              //   this.props.navigation.navigate('search')
              // }}
              onSubmitEditing={async () => {
                await this.props.setTermWeb(this.state.term);
                await this.props.setTerm(this.state.term);
                // console.log(this.props.navigation,'this.props.navigatiothis.props.navigatio');
                this.props.navigation.navigate("search");
                // this.handlePress()
              }}
              onFocus={() => {
                this.setState({
                  boxColor: "#fff",
                  isFocus: true,
                });
              }}
              // autoFocus={false}
              onBlur={() => {
                if (this.props.term != "") {
                  this.setState({ boxColor: "#fff", isFocus: true });
                } else {
                  this.setState({
                    boxColor: "#fff",
                    isFocus: false,
                  });
                }
              }}
              returnKeyType={"search"}
            // onEndEditing = {this.nameInput.current.focus()}
            />
            {this.state.isFocus ? (
              <TouchableOpacity onPress={() => {
                this.setState({ isFocus: false }, () => {
                  this.props.setTerm("");
                  this.props.setTermWeb("");
                  this.props.press(true);
                  this.props.searchOpenBarStatus(false);
                })

              }} style={{ width: 30 }}>
                <Icon
                  name="times"

                  size={20}
                  type="font-awesome"

                />
              </TouchableOpacity>
            ) : null}
          </View>
         

        </View>
        {/* <SearchSuggestion/> */}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    termArray: state.TermArrayReducer,
    addTermArray: state.TermArrayReducer,
    // focus: state.FocusReducer,
    term: state.ScreenLoadingReducer.setTermWeb,
    getsearchBarStatus: state.AdminTrueFalseReducer.searchBarOpenStatus,
    getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
      ? state.LoginUserDetailsReducer.userFollowTopicsList
      : [],
    getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
      ? state.LoginUserDetailsReducer.userFollowCliksList
      : [],
    getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
      ? state.LoginUserDetailsReducer.userFollowUserList : [],
    getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
      ? state.LoginUserDetailsReducer.userFollowFeedList
      : [],
    getDisplayType: state.AdminTrueFalseReducer.setDisplayType,
    // screenName: state.screenNameReducer.screen
  };
};

const mapDispatchToProps = (dispatch) => ({
  setKeyEvent: (payload) => dispatch({ type: "SET_KEY_EVENT_WEB", payload }),
  setDisplayType: (payload) => dispatch(setDisplayType(payload)),
  searchOpenBarStatus: (payload) => dispatch(searchOpenBarStatus(payload)),
  IsCompleted: (payload) => dispatch(IsCompleted(payload)),
  AddTerm: (payload) => dispatch(AddTerm(payload)),
  TermConcat: (payload) => dispatch(TermConcat(payload)),
  setTerm: (payload) => dispatch(setTerm(payload)),
  setTermWeb: (payload) => dispatch(setTermWeb(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SearchInputWeb
);

//export default SearchInputWeb;
