
import React, { Component } from "react";
import { Platform, StyleSheet, View, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";
import Colors from "../constants/Colors";
import { connect } from "react-redux";
import ActionButton from "react-native-circular-action-menu";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import { setSHARELINKMODALACTION } from '../reducers/AdminTrueFalseReducer';
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import applloClient from "../client";
import {
  GetNumUnreadNotificationsMutation,
  MarkNotificationsAsReadMutation,
} from "../graphqlSchema/Notification";
import { screen } from '../reducers/action/Completed'


class FloatingFooter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showIocnSet: false
    }
  }
  showIcon = () => {
    // console.log('hello')
    this.setState({ showIocnSet: !this.state.showIocnSet })
  }

  goToScrollTop = () => {
    this.setState({ showIocnSet: !this.state.showIocnSet })
    this.props.navigationRef.current?.navigate('home')
  };
  goToUserProfile = async (username) => {
    this.setState({ showIocnSet: !this.state.showIocnSet })

    console.log(username)
    this.props.userId({
      username: username,
      type: "feed",
    });
    this.props.navigationRef.current?.navigate("userProfile", {
      username: username,
      type: "feed",
      postId: ' '
    });

  };
  goNotifications = () => {
    console.log(this.props, 'this.props')
    this.props.navigationRef.current?.navigate("notification");
    this.setMarkAsRead();
    this.setState({
      indexicon: 0
    });
  };

  setMarkAsRead = () => {
    applloClient
      .query({
        query: MarkNotificationsAsReadMutation,
        variables: {
          id: "Account:CurrentUser"
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        this.setState({
          UnreadNotifications: 0,
        });
        this.getUnreadNotifications();
      });
  };

  getUnreadNotifications = () => {
    applloClient
      .query({
        query: GetNumUnreadNotificationsMutation,
        variables: {
          id: "Account:CurrentUser",
          first: 100,
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {

        this.props.showUnReadNotification(res.data.node.num_unread_notifications);

        let timer = setTimeout(() => {
          if (this.props.loginStatus == 1) {
            this.getUnreadNotifications();
          }
        }, 60000);
      });
  };
  render() {
    return (

      <View style={{ bottom: Platform.OS == 'web' ? 50 : 30 }}>

        <ActionButton
          buttonColor="rgba(0,0,0,1)"
          position="right"
          radius={90}

          onPress={this.showIcon}
          degrees={0}
          icon={
            !this.state.showIocnSet ? (
              <View>
                <Icon name="plus" type="font-awesome" color="#fff" size={25} />
              </View>
            ) : (
              <Icon name="close" type="font-awesome" color="#fff" size={25} />
            )
          }
          zIndex={1000}
          size={60}
        >
          <ActionButton.Item
            buttonColor={"#CBAA56"}
            onPress={this.goToScrollTop}

          >
            <Icon
              name='home'
              type="font-awesome"
              iconStyle={styles.actionButtonIcon}
              color="#fff"
            />
          </ActionButton.Item>

          <ActionButton.Item
            buttonColor={"#5B9642"}
            onPress={() => {
              this.setState({ showIocnSet: !this.state.showIocnSet })

              if (this.props.loginStatus == 1) {
                this.goToUserProfile(
                  this.props.profileData?.user?.username.toString()
                )
              } else {
                this.props.setLoginModalStatus(true);
              }

            }}
          >
            <Icon
              name='user'
              type="font-awesome"
              iconStyle={styles.actionButtonIcon}
              color="#fff"
            />
          </ActionButton.Item>



          <ActionButton.Item
            buttonColor={"#rgb(216,68,51)"}
            onPress={() => {
              this.props.setShareLinkModalStatus(true),
                this.setState({ showIocnSet: !this.state.showIocnSet })
            }}


          >
            <Icon
              testID="AddLink"
              size={32}
              name="add"
              type="ion-icon"
              color="#fff"
            />

          </ActionButton.Item>
          <ActionButton.Item
            buttonColor={"#031FBC"}
            onPress={() => {
              this.setState({ showIocnSet: !this.state.showIocnSet })

              this.props.loginStatus == 1
                ? this.goNotifications()
                : this.props.setLoginModalStatus(true)
            }

            }


          >
            <Icon
              testID="AddLink"
              size={20}
              name="bell"
              type="font-awesome"
              color="#fff"
            />

          </ActionButton.Item>
          
        </ActionButton>



      </View>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  leftPanelModalFunc: (payload) =>
    dispatch({ type: "LEFT_PANEL_OPEN", payload }),
  setSearchBarStatus: (payload) =>
    dispatch({ type: "SEARCH_BAR_STATUS", payload }),
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  setShareLinkModalStatus: (payload) =>
    dispatch(setSHARELINKMODALACTION(payload)),
  setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  showUnReadNotification: (payload) =>
    dispatch({ type: "NOTIFICATION", payload }),
  screen: (payload) => dispatch(screen(payload))

});

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.loginStatus,
  profileData: state.LoginUserDetailsReducer.userLoginDetails,

});
export default connect(mapStateToProps, mapDispatchToProps)(FloatingFooter);

const styles = StyleSheet.create({
  actionButtonIcon: {
    fontSize: 20,
    height: 25,
    color: "white",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center"
  }
});