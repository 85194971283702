import React, { Component, lazy, Suspense } from "react";
import {
  Animated,
  Dimensions,
  FlatList,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Alert,

} from "react-native";


import { Icon } from "react-native-elements";

import { connect } from "react-redux";
import { compose } from "react-recompose";

import { listClikUserRequest } from "../actionCreator/ClikUserRequestAction";

import { getTrendingCliksProfileDetails } from "../actionCreator/TrendingCliksProfileAction";

import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";

import ButtonStyle from "../constants/ButtonStyle";

import Overlay from "react-native-modal-overlay";
import { clikMemberSuccess } from "../reducers/TrendingCliksProfileReducer";
import { ClikFollowingMutation } from "../graphqlSchema/UserMutation";



import CliksProfileCard from "../components/CliksProfileCard";
import { clikIconUpload } from '../reducers/AdminTrueFalseReducer';
import ClikMembersList from '../components/ClikMembersList';
import ClikProfileUserList from '../components/ClikProfileUserList';

import QualificationToJoin from "../components/QualificationToJoin";

import LeftPanel from "../components/LeftPanel";


import CliksProfileFeedScreen from "./CliksProfileFeedScreen"
import { ClikMembersMiddleware } from "../Middleware/ClikMembersMiddleware";
import { getClikMember } from "../actionCreator/ClikUserRequestAction";
import applloClient from "../client";





class CliksProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {};
    this.state = {
      selectTab: 'Post',
      modalVisible: false,
      commentDelay: false,
      memberValue: "",
      filterItems: this.props.clikMemberList ? this.props.clikMemberList : "",
      loadTab: false,

      cliksselectItem: "",
      showIcon: "#fff",
     
      index: 0,
      id: "",
      scrollY: 0,
      ProfileHeight: 0,
      feedY: 0,
      showInviteLink: false,
      qualification: "",
      type: "",
      member: "",
      qualification: "",
      invite_key: "",
      status: "Default",
      member_type: "",
      InviteClik: {},
      showInviteKeyJoinModal: false,
      InviteType: "",
      MemberType: "",
      showCard: false,
      tempitemId: "",
      opentool: false,
      Badge: require("../assets/image/YBadge.png"),

    };
    this.userPermision = false;
    this.userApprovePermision = false;
  }

  onLoadingComment = () => {
    this.setState({ commentDelay: true });
    setTimeout(() => {
      if (this.props.PostCommentDetails) {
        this.setState({ commentDelay: false });
      }
    }, 100);
  };


  _handleIndexChange = (index) => {
    this.setState({ index });
    this.props.setIndex(index);
  };


  changeLoadTab = () => {
    this.setState({ loadTab: true });
  };
  _renderTabBar = (props) =>

    Dimensions.get("window").width >= 750 && (
      <View>
        {this.state.loadTab == true ? (
          <View
            style={[
              ButtonStyle.TabbarBorderStyleForDashboard,
              {
                flexDirection: "row",
                height: 55,
                backgroundColor:
                  Dimensions.get("window").width <= 750 ? "#000" : "#fff",
                alignItems: "center",
                paddingHorizontal: 10,
                paddingVertical: 10,
                marginLeft: 0,
                width: '100%',
                borderTopWidth: 1
              },
            ]}
          >
            <TabBar
              onTabPress={({ route }) => {
                this.props.setClikTabView(false);
                if (route.key === "second") {
                  this.props.userId({
                    id:
                      this.props.cliksDetails.name,
                    type: "members",
                  });
                } else if (route.key === "third") {
                  this.props.userId({
                    id:
                      this.props.cliksDetails.name,
                    type: "applications",
                  });
                } else if (route.key === "first") {
                  this.props.userId({
                    id:
                      this.props.cliksDetails.name,
                    type: "feed",
                  });
                }
              }}
              {...props}
              indicatorStyle={{
                backgroundColor: "transparent",
                height: 3,
                borderRadius: 6,
              }}
              style={{
                backgroundColor: "transparent",
                width: "100%",
                shadowColor: "transparent",
                height: 40,
                justifyContent: "center",
                marginVertical: 8,
                // alignItems: "center",
              }}
              labelStyle={{
                color: "#4169e1",
                fontFamily: ConstantFontFamily.defaultFont,
              }}
              renderLabel={({ route, focused, color, isActive }) => (
                <Text
                  style={[ButtonStyle.tabbarTitleStyle, {
                    color: focused ? '#000' : isActive ? '#000' : "#D3D3D3",

                  }]}
                >
                  {route.title}
                </Text>
              )}
            />
          </View>
        ) : null}
      </View>
    );


  _renderUserItem = (item) => {
    return (

      <ClikProfileUserList
        item={item}
        navigation={this.props.navigation}
        onLoadingComment={this.onLoadingComment}
        commentDelay={this.state.commentDelay}
        ClikInfo={this.props.cliksDetails}
      />

    );
  };

  _renderClikMembersItem = (item) => {
   
    return (
      <ClikMembersList
        item={this.props.clikMemberList}
        navigation={this.props.navigation}
        ClikInfo={this.props.cliksDetails}
        MemberType={this.state.MemberType}
        // userApprovePermision={this.userApprovePermision}
        // MemberType={tempitemId && tempitemId}
        getCurrentMember={this.getCurrentMember}
      />

    );
  };


  getCurrentMember = (username) => {
    this.props.clikMemberList?.splice(
      this.props.clikMemberList.indexOf(username),
      1
    );
    this.setState({ filterItems: this.props.clikMemberList });
  };

  showIcon = (data) => {
    this.setState({
      showIcon: data,
    });
  };

  members = () => {
    this.setState({
      cliksselectItem: "FEED",
    });
  };

  showMembers = (type) => {
    this.setState({
      index: 1,
      InviteType: type,
    });
  };

  showInviteLink = (value, type, member) => {
    this.setState({
      showInviteLink: value,
      type: type,
      member: member,
    });
  };

  onClose = () => {
    this.setState({
      modalVisible: false,
      showInviteKeyJoinModal: false,
      opentool: false
    });
  };


  requestJoin = async () => {
    this.setState({
      index: 1,
    });
    if (
      this.props.cliksDetails?.invite_only == true
    ) {
      this.getKeyProfile(this.props.navigation.getParam("key", ""));
    }
  };

  getKeyProfile = async (key) => {
    try {
      await applloClient
        .query({
          query: InviteKeyClikProfileMutation,
          variables: {
            invite_key: key,
          },
          fetchPolicy: "no-cache",
        })
        .then(async (res) => {
          this.setState({
            InviteClik: res.data.clik_invite_key,
            invite_key: key,
            showInviteKeyJoinModal: true,
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  async componentDidMount() {
    
    this.getStaticData();
    
  }

  callFollowingMutation = () => {
    applloClient
      .query({
        query: ClikFollowingMutation,
        variables: {
          id: "User:CurrentUser",
          first: 50
        },
        fetchPolicy: "no-cache"
      }).then(e => {


        let userData = {
          data: props.profileData,
          externalfollow: props.getUserFollowFeedList,
          clikfollow: e.data.node.cliks_following.edges,
          topicfollow: props.getUserFollowTopicList,
          userfollow: props.getUserFollowUserList

        }

        this.props.saveLoginUser(userData);
      })

  }

  updateStar = () => {
    
    this.getStaticData();
    
    this.props.clikIconUpload(false);

  };

  setSignupData = async (itemId) => {
    await this.props.setUsernameModalStatus(true);
    await this.props.setInviteUserDetail({
      clikName: itemId,
      inviteKey: this.props.navigation.getParam("key", ""),
      userName: "",
    });
  };

  async getStaticData() {
    let itemId = "";
    
    itemId = this.props.cliksDetails.id
    this.setState({
      id: itemId,
    });
    

    if (itemId) {
    
      await this.props.setClikMembers({
        id: itemId,
      });
      let index = this.props.getUserFollowCliksList.findIndex(obj => obj.node.clik.name.toLowerCase() == this.props.cliksDetails.name.toLowerCase())


      if (index != -1) {
        this.setState({
          MemberType: this.props.getUserFollowCliksList[index].node.member_type,
        });
        console.log(this.props.getUserFollowCliksList[index].node.member_type, 'MemberType')

        if (
          this.props.getUserFollowCliksList[index].node.member_type ==
          "SUPER_ADMIN"
        ) {
          this.setState({
            Badge: require("../assets/image/YBadge.png"),
            showIcon: require("../assets/image/YBadge.png"),
            MemberType: "SUPER_ADMIN",
            routes: [
              { key: "first", title: "Feed" },
              { key: "second", title: "Members" },
              { key: "third", title: "Applications" },
            ],
          });
        } else if (
          this.props.getUserFollowCliksList[index].node.member_type ==
          "ADMIN"
        ) {
          this.setState({
            Badge: require("../assets/image/SBadge.png"),
            showIcon: require("../assets/image/SBadge.png"),
            MemberType: "ADMIN",
            routes: [
              { key: "first", title: "Feed" },
              { key: "second", title: "Members" },
              { key: "third", title: "Applications" },
            ],
          });
        } else if (
          this.props.getUserFollowCliksList[index].node.member_type ==
          "MEMBER"
        ) {
          this.setState({
            Badge: require("../assets/image/badge.png"),
            showIcon: require("../assets/image/badge.png"),
            MemberType: "MEMBER",
            routes: [
              { key: "first", title: "Feed" },
              { key: "second", title: "Members" },
            ],
          });
        } else {
          this.getIconColour(index);
          this.setState({
            Badge: 'icon',
            routes: [
              { key: "first", title: "Feed" },
              { key: "second", title: "Members" },
            ],
          });
        }
      } else {
        this.getIconColour(index);
        this.setState({
          Badge: 'icon',
          routes: [
            { key: "first", title: "Feed" },
            { key: "second", title: "Members" },
          ],
        });
      }
    }
  }

  getIconColour = (index) => {
    if (index != -1) {
      if (
        this.props.getUserFollowCliksList[index].node.follow_type == "FAVORITE"
      ) {
        this.setState({
          showIcon: "#FADB4A",
        });
      }
      if (
        this.props.getUserFollowCliksList[index].node.follow_type == "FOLLOW"
      ) {
        this.setState({
          showIcon: "#E1E1E1",
        });
      }
    } else {
      this.setState({
        showIcon: "#fff",
      });
    }
  };

  componentDidUpdate(prevProps) {

    if (this.props.getHasScrollTop == true && this.UserProfilescrollview) {
      this.UserProfilescrollview.scrollTo({ x: 0, y: 0, animated: true });
      this.props.setHASSCROLLEDACTION(false);

    }
    if (prevProps.cliksDetails.name != this.props.cliksDetails.name) {
      this.getStaticData();
      this.setState({ selectTab: 'Post' })
    }
    

    if (this.props.loginStatus == 1) {
     
    }
    

  }
  getUserPermision = () => {

    const index = this.props.clikMemberList.findIndex(
      (i) =>
        i.node.user.id ==
        this.props.profileData?.data?.user?.id
    );
    if (index != -1) {
      this.userPermision = true;
    } else {
      this.userPermision = false;
    }
  };

  getUserApprovePermision = () => {
    const index = this.props.clikMemberList.findIndex(
      (i) =>
        i.node.user.id ==
        this.props.profileData?.data?.user?.id &&
        (i.node.type == "SUPER_ADMIN" || i.node.type == "ADMIN")
    );
    if (index != -1) {
      this.userApprovePermision = true;
    } else {
      this.userApprovePermision = false;
    }
  };



  listScroll = (value) => {
    this.setState({
      feedY: value,
    });
  };


  renderTabViewForMobile = () => {
    if (this.props.getProfileTabView == "FEED") {
      return (
        
        <CliksProfileFeedScreen
          navigation={this.props.navigation}
          listScroll={this.listScroll}
          ProfileHeight={this.state.ProfileHeight}
          scrollY={this.state.scrollY}
        />
        
      );
    } else if (this.props.getProfileTabView == "USERS") {
      return (
        <ScrollView containerStyle={{ marginBottom: 50, flex: 1 }}>
          {this.props.cliksDetails && (

            <QualificationToJoin
              type={this.state.type}
              navigation={this.props.navigation}
              MemberType={this.state.MemberType}

            />

          )}

          

          {this.userApprovePermision == true && (

            <InviteUserModal
              onClose={() => this.onClose()}
              {...this.props}
              ClikInfo={this.props.cliksDetails}
              showHeader={false}
            />

          )}
          <View
            style={[
              ButtonStyle.ZeroBorderStyle,
              {
                borderWidth: 0,
                borderRadius: 0,
                backgroundColor: "#fff",
                marginBottom: 50,
              },
            ]}
          >
            <Text
              style={{
                textAlign: "left",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont,
                marginVertical: 10,
                marginHorizontal: 10,
              }}
            >
              Current Members
            </Text>
            {this.props.clikMemberList.length ? (
              <View
                style={{
                  paddingLeft: 10,
                  width: "100%",
                  paddingRight: 10,
                }}
              >
                <FlatList
                  data={this.state.filterItems}
                  keyExtractor={(item) => item.node.user.id}
                  renderItem={this._renderClikMembersItem}
                />
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: hp("15%"),
                }}
              >
                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                  reverse
                  name="user-times"
                  type="font-awesome"
                  size={20}
                  containerStyle={{
                    alignSelf: "center",
                  }}
                />
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont,
                    color: "#000",
                    alignSelf: "center",
                  }}
                >
                  No Member found
                </Text>
              </View>
            )}
          </View>
        </ScrollView>
      );
    } else if (this.props.getProfileTabView == "APPLICATIONS") {
      return (
        <View>
          <View
            style={[
              ButtonStyle.cardShadowStyle,
              {
                borderWidth: 0,
                borderRadius: 10,
                backgroundColor: "#fff",
                marginVertical: 10,
              },
            ]}
          >
            <Text
              style={{
                textAlign: "left",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont,
                marginVertical: 10,
                marginHorizontal: 10,
              }}
            >
              Qualifications
            </Text>
            {this.props.cliksDetails
              .ualifications == null && (
                <Text
                  style={{
                    textAlign: "center",
                    color: "#000",
                    fontFamily: ConstantFontFamily.defaultFont,
                    fontSize: 16,
                    fontWeight: "bold",
                    margin: 10,
                  }}
                >
                  No prerequisites to join this clik
                </Text>
              )}
            {this.props.cliksDetails
              .qualifications != null &&
              this.props.cliksDetails
                .qualifications
                .map((item, index) => {
                  return (
                    <Text
                      key={index}
                      style={{
                        color: "#000",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 16,
                        fontWeight: "bold",
                        marginBottom: 20,
                        marginLeft: 30,
                      }}
                    >
                      {"\u25CF"} {item}
                    </Text>
                  );
                })}
          </View>
          {this.userApprovePermision == true && (

            <InviteUserModal
              onClose={() => this.onClose()}
              {...this.props}
              ClikInfo={this.props.cliksDetails}
              showHeader={false}
            />

          )}
          <View
            style={[
              ButtonStyle.cardShadowStyle,
              {
                borderWidth: 0,
                borderRadius: 10,
                backgroundColor: "#fff",
                marginBottom: 10,
              },
            ]}
          >
            <Text
              style={{
                textAlign: "left",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont,
                marginVertical: 10,
                marginHorizontal: 10,
              }}
            >
              Member Applications
            </Text>
            {this.props.listClikUserRequest.length > 0 &&
              this.userApprovePermision == true ? (
              <View>
                <FlatList
                  data={this.state.filterItems}
                  keyExtractor={(item) => item.node.user.id}
                  renderItem={this._renderUserItem}
                />
              </View>
            ) : (
              this.userApprovePermision == true && (
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: hp("15%"),
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="user-times"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    No Applications
                  </Text>
                </View>
              )
            )}
          </View>
        </View>
      );
    } else {
      return (
        <View style={{ flex: 1 }}>
         
          <CliksProfileFeedScreen
            navigation={this.props.navigation}
            listScroll={this.listScroll}
            ProfileHeight={this.state.ProfileHeight}
            scrollY={this.state.scrollY}
          />
          
        </View>
      );
    }
  };

  selectMember = (memberValue) => {
    this.setState({ memberValue: memberValue });
  };
  openPopUp = () => {
    this.setState({ opentool: true })
  }

  showAlert = () => {

    if (Platform.OS === "web") {
      var result = confirm(
        "Are you sure you want to delete " +
        this.props.cliksDetails.name
          .replace("Clik%3A", "#")
          .replace("Clik:", "#")
      );
      if (result == true) {
        applloClient
          .query({
            query: DeleteContentMutation,
            variables: {
              id: cliks.get("id")
            },
            fetchPolicy: "no-cache"
          })
          .then(async res => {

            await applloClient
              .query({
                query: ClikFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 50
                },
                fetchPolicy: "no-cache"
              }).then(e => {


                let userData = {
                  data: this.props.profileData,
                  externalfollow: this.props.getUserFollowFeedList,
                  clikfollow: e.data.node.cliks_following.edges,
                  topicfollow: this.props.getUserFollowTopicList,
                  userfollow: this.props.getUserFollowUserList

                }
                this.props.saveLoginUser(userData);
                this.props.getTrendingClicks({
                  currentPage: 100
                });
                this.props.navigation.navigate("home")

              })
          });
      }
    } else {
     
      Alert.alert(
        "Are you sure you want to delete " +
        this.props.cliksDetails.name
          .replace("Clik%3A", "#")
          .replace("Clik:", "#")[
        ({
          text: "NO",
          onPress: () => console.warn("NO Pressed"),
          style: "cancel"
        },
        {
          text: "YES",
          onPress: () => {
            applloClient
              .query({
                query: DeleteContentMutation,
                variables: {
                  id: this.props.cliksDetails
                    .id
                },
                fetchPolicy: "no-cache"
              })
              .then(async res => {
                await applloClient
                  .query({
                    query: ClikFollowingMutation,
                    variables: {
                      id: "User:CurrentUser",
                      first: 50
                    },
                    fetchPolicy: "no-cache"
                  }).then(e => {


                    let userData = {
                      data: this.props.profileData,
                      externalfollow: this.props.getUserFollowFeedList,
                      clikfollow: e.data.node.cliks_following.edges,
                      topicfollow: this.props.getUserFollowTopicList,
                      userfollow: this.props.getUserFollowUserList

                    }
                    props.saveLoginUser(userData);
                  })
              });
          }
        })
        ]
      );
    }
  };
  setTab = (tabValue, clikid) => {
    
    this.setState({ selectTab: tabValue })


    if (tabValue == "Members") {
      this.props.setClikMembers({
        id: clikid,
      });
    } else if (tabValue == "Applications") {
      this.props.setClikUserRequest({ id: clikid })
     

    }
  }
  render() {
    return (
      <View style={styles.container}>
        {this.state.showInviteKeyJoinModal == true ? (
          Platform.OS !== "web" ? (

            <Overlay
              animationType="zoomIn"
              visible={this.state.showInviteKeyJoinModal}
              onClose={() => this.onClose()}
              closeOnTouchOutside
              children={
                <InviteKeyJoinModal
                  onClose={() => this.onClose()}
                  {...this.props}
                  InviteClik={this.state.InviteClik}
                  invite_key={this.state.invite_key}
                />
              }
              childrenWrapperStyle={{
                padding: 0,
                margin: 0,
              }}
            />

          ) : (

            <Modal
              isVisible={this.state.showInviteKeyJoinModal}
              onBackdropPress={() => this.onClose()}
              style={{
                marginHorizontal:
                  Dimensions.get("window").width > 750 ? "30%" : 10,
                padding: 0,
              }}
            >
              <InviteKeyJoinModal
                onClose={() => this.onClose()}
                {...this.props}
                InviteClik={this.state.InviteClik}
                invite_key={this.state.invite_key}
              />
            </Modal>

          )
        ) : null}


        {
          Platform.OS != 'web' ? (
            <Animated.View
              style={{
                position: Platform.OS == "web" ? "sticky" : null,
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                overflow: "hidden",
                borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
              }}
            >
              <View
                style={{
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    marginBottom:
                      Dimensions.get("window").width <= 750 ? 0 : 10,
                    backgroundColor: "#000",
                    borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
                    height: 50,
                  }}
                >
                  <TouchableOpacity
                    style={[
                      ButtonStyle.headerBackStyle,
                      { marginRight: !this.props.getsearchBarStatus ? 10 : 15 },
                    ]}
                    onPress={() => {
                      this.props.searchOpenBarStatus(false);
                      let nav =
                        this.props.navigation.dangerouslyGetParent().state;
                      if (nav.routes.length > 1) {
                        if (this.state.cliksselectItem == "USERS") {
                          this.setState({
                            cliksselectItem: "FEED",
                          });
                        } else {
                          this.props.navigation.goBack();
                        }
                      } else {
                        this.props.navigation.navigate("home");
                      }
                    }}
                  >
                    <Icon
                      color={"#fff"}
                      name="angle-left"
                      type="font-awesome"
                      size={40}
                    />
                  </TouchableOpacity>

                  {this.props.cliksDetails.size > 0 &&
                    !this.props.getsearchBarStatus && (
                      <TouchableOpacity
                        style={{
                          flex: this.props.loginStatus
                            ? 1
                            : !this.props.loginStatus && Platform.OS == "web"
                              ? 1
                              : !this.props.loginStatus &&
                              Platform.OS != "web" &&
                              0,
                          flexDirection: "row",
                          alignSelf: "center",
                          alignItems: "center",
                        }}
                      >
                        {this.props.loginStatus == 1 &&
                          this.state.showIcon.toString().startsWith("#") ==
                          (
                            <Image
                              source={{ uri: this.state.showIcon }}
                              style={{
                                width: 22,
                                height: 22,
                              }}
                            />
                          )}
                        <TouchableOpacity
                          style={{
                            paddingVertical: 5,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onPress={() =>
                            this.CliksProfilescrollview.scrollTo({
                              x: 0,
                              y: 0,
                              animated: true,
                            })
                          }
                        >
                          <Text
                            style={{
                              color: "#fff",
                              fontWeight: "bold",
                              fontSize: 18,
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            #
                            {this.props.navigation.state.params.id
                              .replace("Clik%3A", "#")
                              .replace("Clik:", "#")}
                          </Text>
                        </TouchableOpacity>

                      </TouchableOpacity>
                    )}
                  <View
                    style={[
                      ButtonStyle.headerRightStyle,


                      {
                        width:
                          this.props.getsearchBarStatus ? "85%" : '20%'
                      },
                    ]}
                  >

                    <HeaderRightDashboard navigation={this.props.navigation} />

                  </View>
                </View>
              </View>
            </Animated.View>
          ) : null
        }
        <ScrollView
          contentContainerStyle={Dimensions.get("window").width > 750 ? ButtonStyle.threeColStrucALL : null}
          scrollEnabled={false}


        >
          {Dimensions.get("window").width > 750 ?


            <LeftPanel

              navigation={this.props.navigation}
            />
            : null}
          {Dimensions.get('window').width <= 750 ?

            <
             
              >


              {this.props.cliksDetails && (
                <CliksProfileCard
                  item={this.props.cliksDetails}
                  navigation={this.props.navigation}
                  icon={this.showIcon}
                  members={this.members}
                  showMembers={this.showMembers}
                  feedY={this.state.feedY}
                  ProfileHeight={this.state.ProfileHeight}
                  showIcon={this.state.showIcon}
                  showInviteLink={this.showInviteLink}
                  MemberType={this.state.MemberType}
                  updateStar={this.updateStar}
                  openPopUp={this.openPopUp}
                  setTab={this.setTab}
                />
              )}

            </>
            : null}
          <ScrollView
            contentContainerStyle={{
              height: Dimensions.get("window").height - 50,
              paddingBottom: Dimensions.get("window").width <= 750 ? 150 : 20,
              // width:Dimensions.get('window').width > 1200 ? 1200 : '100%',

            }}
            ref={(ref) => {
              this.CliksProfilescrollview = ref;
            }}
            showsVerticalScrollIndicator={false}
            onScroll={(event) => {
              // console.log(event, 'evvv')
              // this.TopicsProfilescrollview.scrollTo({x: 100, y:200, animated:true})
              this.setState({
                scrollY: event.nativeEvent.contentOffset.y,
              })
            }}
            // scrollEnabled={this.state.scrollY <= 190 ? true : false}
            scrollEventThrottle={16}
          >
            {Dimensions.get('window').width > 750 ?

              <
              
                >

                {this.props.cliksDetails && (
                  <CliksProfileCard
                    item={this.props.cliksDetails}
                    navigation={this.props.navigation}
                    icon={this.showIcon}
                    members={this.members}
                    showMembers={this.showMembers}
                    feedY={this.state.feedY}
                    ProfileHeight={this.state.ProfileHeight}
                    showIcon={this.state.showIcon}
                    showInviteLink={this.showInviteLink}
                    MemberType={this.state.MemberType}
                    updateStar={this.updateStar}
                    openPopUp={this.openPopUp}
                    setTab={this.setTab}
                  />
                )}

              </>
              : null}



            {Dimensions.get("window").width <= 750 ? (
              <View
                style={{
                  flex: 1,
                  width: "100%",
                }}
              >
                {this.renderTabViewForMobile()}
              </View>
            ) : (
              <View
                style={{

                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                }
                }
              >

                {this.state.selectTab == "Post" ?

                  <CliksProfileFeedScreen
                    navigation={this.props.navigation}
                    listScroll={this.listScroll}
                    ProfileHeight={this.state.ProfileHeight}
                    changeLoadTab={this.changeLoadTab}
                    loadFalse={() => this.setState({ loadTab: false })}
                    onLoadingComment={this.onLoadingComment}
                    commentDelay={this.state.commentDelay}
                    scrollY={this.state.scrollY}
                    showCard={this.state.showCard}
                  />
                  :
                  this.state.selectTab == "Members" ?
                    <ScrollView
                      containerStyle={{ width: "100%" }}
                      style={[
                        ButtonStyle.normalFeedStyleForDashboard,
                        {
                          width: "100%",
                          marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
                          marginBottom: 20,
                          borderWidth: 1,
                          borderRightWidth: 0,
                          borderColor: '#d3d3d3',
                          borderRadius: 0,
                          marginLeft: 0,
                          marginRight: 0,
                          marginTop: 0,
                        },
                      ]}
                      showsVerticalScrollIndicator={false}
                    >
                      {this.props.cliksDetails && (

                        <QualificationToJoin
                          status={"Default"}
                          type={this.state.type}
                          navigation={this.props.navigation}
                          MemberType={this.state.MemberType}

                        />

                      )}

                      

                      {this.userApprovePermision == true && (


                        <InviteUserModal
                          onClose={() => this.onClose()}
                          {...this.props}
                          ClikInfo={this.props.cliksDetails}
                          showHeader={false}
                        />

                      )}
                      
                      <View
                        style={[
                          {
                            backgroundColor: "#fff",
                            // borderBottomWidth: 1,
                            borderColor: "#D7D7D7",
                          },
                        ]}
                      >
                        <Text
                          style={{
                            textAlign: "left",
                            color: "#000",
                            fontSize: 16,
                            fontWeight: "bold",
                            fontFamily: ConstantFontFamily.defaultFont,
                            marginVertical: 10,
                            marginHorizontal: 10,
                            marginTop: 20,
                          }}
                        >
                          Current Members
                        </Text>

                        {this.props.clikMemberList?.length ? (
                          <View
                            style={{
                              paddingLeft: 10,
                              width: "100%",
                              paddingRight: 10,
                              height: '100%',
                            }}
                          >
                            <ClikMembersList
                              item={this.props.clikMemberList}
                              navigation={this.props.navigation}
                              ClikInfo={this.props.cliksDetails}
                              MemberType={this.state.MemberType}
                              // userApprovePermision={this.userApprovePermision}
                              // MemberType={tempitemId && tempitemId}
                              getCurrentMember={this.getCurrentMember}
                            />
                            
                          </View>
                        ) : (
                          <View
                            style={{
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: Dimensions.get('window').height * .15,
                            }}
                          >
                            <Icon
                              color={"#000"}
                              iconStyle={{
                                color: "#fff",
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                              }}
                              reverse
                              name="user-times"
                              type="font-awesome"
                              size={20}
                              containerStyle={{
                                alignSelf: "center",
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                fontFamily: ConstantFontFamily.defaultFont,
                                color: "#000",
                                alignSelf: "center",
                              }}
                            >
                              No Member found
                            </Text>
                          </View>
                        )}
                      </View>
                    </ScrollView>
                    :
                    this.state.selectTab == "Applications" ?
                      <ScrollView
                        containerStyle={{
                          paddingBottom: 1,
                          flex: 1,
                          width: "100%",
                          // marginTop: 20,


                        }}
                        style={[
                          ButtonStyle.normalFeedStyleForDashboard,
                          {
                            borderTopWidth: 1, width: '100%',
                            borderColor: '#d3d3d3', borderRadius: 0, marginLeft: 0,
                            marginRight: 0,
                            marginTop: 0
                          },
                        ]}
                        showsVerticalScrollIndicator={false}
                      >
                        <View
                          style={{
                            backgroundColor: "#fff",
                            marginBottom: 10,
                            borderWidth: 1,
                            borderTopWidth: 0,
                            borderColor: "#d7d7d7",
                            borderLeftWidth: 0,
                            borderRightWidth: 0

                          }}
                        >
                          <Text
                            style={{
                              textAlign: "left",
                              color: "#000",
                              fontSize: 16,
                              fontWeight: "bold",
                              fontFamily: ConstantFontFamily.defaultFont,
                              paddingVertical: 10,
                              marginHorizontal: 10,
                            }}
                          >
                            Member Applications
                          </Text>
                          {this.props.ClikApplicationList?.length > 0 ? (
                            <View >
                              <FlatList
                                extraData={this.state}
                                data={this.props.ClikApplicationList}
                                // keyExtractor={(item) => item.node.user.id}
                                renderItem={this._renderUserItem}
                              />
                            </View>
                          ) : (
                            this.userApprovePermision == true && (
                              <View
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: Dimensions.get('window').height * .15,
                                }}
                              >
                                <Icon
                                  color={"#000"}
                                  iconStyle={{
                                    color: "#fff",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignSelf: "center",
                                  }}
                                  reverse
                                  name="user-times"
                                  type="font-awesome"
                                  size={20}
                                  containerStyle={{
                                    alignSelf: "center",
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    color: "#000",
                                    alignSelf: "center",
                                  }}
                                >
                                  No Applications
                                </Text>
                              </View>
                            )
                          )}
                        </View>
                      </ScrollView>
                      :
                      <CliksProfileFeedScreen
                        navigation={this.props.navigation}
                        listScroll={this.listScroll}
                        ProfileHeight={this.state.ProfileHeight}
                        scrollY={this.state.scrollY}
                      />
                }

              </View>
            )}
          </ScrollView>
        </ScrollView>
        {
          this.props.show == true || Dimensions.get("window").width >= 1200 ?
            <View style={{
              width: Platform.OS == "web" &&
                Dimensions.get("window").width >= 750 && Dimensions.get("window").width <= 1200
                ? '100%' : Platform.OS == "web" && Dimensions.get("window").width >= 1200 ? '50%'
                  : "100%", marginLeft: Dimensions.get("window").width >= 1200 ? '50%' : 0, zIndex: 999, position: 'absolute', bottom: 10,
            }}>
              
            </View> : null
        }

        {this.state.opentool ? <Overlay
          animationType="fadeInUp"
          visible={this.state.opentool}
          onClose={this.onClose}
          closeOnTouchOutside
          children={
            <View style={{ width: '100%', backgroundColor: "#fff", borderRadius: 8, }}>
              <View style={{ padding: 20 }}>

                {this.state.MemberType == "" || this.state.MemberType == null ? <TouchableOpacity style={{ justifyContent: 'center', alignItems: "center", marginBottom: 10 }}
                  onPress={() => this.showMembers(this.state.Badge == "icon" ?
                    this.props.cliksDetails && this.props.cliksDetails.invite_only == true
                      ?
                      "Apply" : "Join" : "Invite")}>

                  <Text
                    style={{
                      textAlign: "center",
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 20
                    }}
                  >
                    {this.state.Badge == "icon" ?
                      this.props.cliksDetails && this.props.cliksDetails.invite_only == true
                        ?
                        "Apply" : "Join" : "Invite"}
                  </Text>

                </TouchableOpacity> : null}


                {(this.props.isAdmin == true || this.state.MemberType == "SUPER_ADMIN") &&
                  <TouchableOpacity
                    style={{ marginBottom: 10 }}
                    onPress={async () => {
                      await this.props.editClik({
                        clikId: this.props.cliksDetails && this.props.cliksDetails.id,
                        cliksName: this.props.cliksDetails && this.props.cliksDetails.name,
                        profile_pic: this.props.cliksDetails && this.props.cliksDetails.profile_pic,
                        description: this.props.cliksDetails && this.props.cliksDetails.description,
                        cliksMembers: this.props.cliksDetails && this.props.cliksDetails.max_members,
                        website: this.props.cliksDetails && this.props.cliksDetails.websites,
                        invite_only: this.props.cliksDetails && this.props.cliksDetails.invite_only,
                        qualifications:
                          this.props.cliksDetails && this.props.cliksDetails.qualifications
                      });
                      // console.log(this.props.navigation, 'this.props.navigation');
                      // this.props.navigation.navigate("editclik");
                      this.onClose()
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 20
                      }}
                    >
                      Edit
                    </Text>
                  </TouchableOpacity>
                }

                <TouchableOpacity style={{ marginBottom: 10 }}>

                  <Text
                    style={{
                      textAlign: "center",
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 20
                    }}
                  >
                    Report
                  </Text>

                </TouchableOpacity>
                {(this.props.isAdmin == true || this.state.MemberType == "SUPER_ADMIN") &&
                  <TouchableOpacity style={{ marginBottom: 10 }}
                    onPress={() => { this.showAlert() }}
                  >

                    <Text
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 20
                      }}
                    >
                      Delete
                    </Text>

                  </TouchableOpacity>
                }

              </View>
            </View>
          }
          childrenWrapperStyle={{
            padding: 0,
            margin: 0,
            borderRadius: 6
          }}
        /> : null}
        
        
      </View>
    );
  }
}

const mapStateToProps = (state) => (
  {
    cliksDetails: state.ClikProfileDetailsReducer.getTrendingCliksProfileDetails,
    // getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
    listClikUserRequest: !state.ClikUserRequestReducer.ClikUserRequestList
      ? []
      : state.ClikUserRequestReducer.ClikUserRequestList,
    //   : state.ClikMembersReducer.get("clikMemberList"),
    loginStatus: state.UserReducer.loginStatus,
    profileData: state.LoginUserDetailsReducer.userLoginDetails,
    getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
      ? state.LoginUserDetailsReducer.userFollowCliksList : [],
    
    getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
      ? state.LoginUserDetailsReducer.userFollowTopicsList
      : [],
    getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
      ? state.LoginUserDetailsReducer.userFollowUserList
      : [],
    windowSize: state.windowResizeReducer.windowResize,
    clikMemberList: state.ClikMembersReducer.clikMemberList,
    ClikApplicationList: state.ClikMembersReducer.ClikApplicationList,

  });

const mapDispatchToProps = (dispatch) => ({
  
  userId: (payload) => dispatch(getTrendingCliksProfileDetails(payload)),
  setClikUserRequest: (payload) => dispatch(listClikUserRequest(payload)),
  setClikMembers: (payload) => dispatch(getClikMember(payload)),
  clikIconUpload: (payload) => dispatch(clikIconUpload(payload)),


  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  

});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CliksProfileScreen
);

const styles = StyleSheet.create({
  header: {
    position: Platform.OS == "web" ? "fixed" : null,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  container: {
    flex: 1,
    backgroundColor: ConstantColors.whiteBackground,
  },
});
