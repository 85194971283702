import React from "react";
// import { graphql } from "react-apollo";
import { Text, TouchableOpacity, View,  Dimensions } from "react-native";
import * as Clipboard from 'expo-clipboard';

import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
import { ChangeAccessKeyMutation } from "../graphqlSchema/ChangeAccessKey";
import { Hoverable } from "react-native-web-hooks";
import { setRESETPASSWORDMODALACTION } from "../actionCreator/ResetPasswordModalAction";

const apiUrlMain = getEnvVars();

class Security extends React.Component {
  state = {
    showInfoText: false,
    keyText: '',
    disableClick: false,
    showCopiedText: false
  };

  writeToClipboard = async () => {
    //To copy the text to clipboard
    await Clipboard.setString(this.state.keyText);
    this.setState({
      showCopiedText: true
    });
    let timer = setTimeout(() => {
      this.setState({
        showCopiedText: false
      });
    }, 2000);
  };

  render() {
    
    return (
      <View style={{
        backgroundColor: '#fff',
        paddingHorizontal: 20,
        marginVertical: Dimensions.get("window").width >= 750 ? 10 : 0
      }}>
        <View style={{ flexDirection: "row", width: "100%" }}>
          <View
            style={{
              flexDirection: "row",
              width: "25%"
            }}
          >
            <Text
              style={{
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont,
                marginVertical: 10
              }}
            >
              Access Key
            </Text>
            <Icon
              color={"#000"}
              name="info-circle"
              type="font-awesome"
              size={18}
              containerStyle={{
                marginLeft: 20,
                marginTop: 10
              }}
              onPress={() =>
                this.setState({
                  showInfoText: !this.state.showInfoText
                })
              }
            />
          </View>
          {this.state.showInfoText == true && (
            <View style={{ width: "75%" }}>
              <Text
                style={{
                  color: "#000",
                  fontSize: 14,
                  //fontWeight: "bold",
                  fontFamily: ConstantFontFamily.defaultFont,
                  marginTop: 10,
                  marginLeft: 15,
                  textAlign: "left"
                }}
              >
                {
                  "When using the Weclikd GraphQL API, set the X-Weclikd-Access-key header to authenticate."
                }
                {"Store this key in a safe place!"}
              </Text>
            </View>
          )}
        </View>

        <View style={{ flexDirection: "row" }}>
          <TouchableOpacity
            disabled={this.state.disableClick}
            style={{
              marginTop: 10,
              width: Dimensions.get("window").width <= 450 && '100%',
              marginBottom: Dimensions.get("window").width <= 450 ? 10 : 0,
            }}
            onPress={() => {
              this.setState({
                keyText: "Loading..."
              });
              this.props.ChangeAccessKey().then(res => {
                this.setState({
                  keyText: res.data.account_change_access_key.access_key,
                  disableClick: true
                });
              });
            }}
          >
            <Hoverable>
              {isHovered => (
                <View>
                  {this.state.keyText.length > 0 && (<Text
                    style={{
                      color: "#000",
                      //fontWeight: "bold",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 14,
                      marginBottom: 12
                    }}
                  >
                    {this.state.keyText}
                  </Text>)}
                  {this.state.keyText.length == 0 && (<View style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <Text
                      style={{
                        color: "#000",
                        //fontWeight: "bold",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                        //textDecorationLine: isHovered == false  && 'underline'
                        //textDecorationLine:isHovered == true  && 'underline'
                      }}
                    >
                      Click {' '}
                    </Text>
                    <Text
                      style={{
                        color: "#000",
                        //fontWeight: "bold",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                        textDecorationLine: isHovered == false && 'underline'
                        //textDecorationLine:isHovered == true  && 'underline'
                      }}
                    >
                      here
                    </Text>
                    <Text
                      style={{
                        color: "#000",
                        //fontWeight: "bold",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                        //textDecorationLine: isHovered == false  && 'underline'
                        //textDecorationLine:isHovered == true  && 'underline'
                      }}
                    >
                      {' '} to get new key, old key will no longer work.
                    </Text>
                  </View>)}
                </View>
              )

              }
           
            </Hoverable>
          </TouchableOpacity>
          {this.state.disableClick == true && (
            <Icon
              color={"#000"}
              iconStyle={{
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
              }}
              name="clone"
              type="font-awesome"
              size={18}
              containerStyle={{
                marginTop: 10,
                marginLeft: 40,
                alignSelf: "center",
                marginBottom: 12
              }}
              onPress={() => this.writeToClipboard()}
            />
          )}
          {this.state.showCopiedText == true && (
            <Text
              style={{
                color: "#000",
                fontSize: 14,
                //fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont,
                marginTop: 10,
                marginLeft: 10,
                textAlign: "left"
              }}
            >
              {"Weclikd-Access-Key Copied!"}
            </Text>
          )}
        </View>
        {/* {this.props.googleLogin == false && (<>
          <View style={{ flexDirection: "row", width: "100%" }}>
            <View
              style={{
                flexDirection: "row",
                width: "25%"
              }}
            >
              <Text
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: ConstantFontFamily.defaultFont,
                  marginVertical: 10
                }}
              >
                Change Password Link
              </Text>
              
            </View>

          </View>
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              // disabled={this.state.disableClick}
              style={{
                marginTop: 10,
                width: Dimensions.get("window").width <= 450 && '100%',
                marginBottom: Dimensions.get("window").width <= 450 ? 10 : 0,
              }}
           
            onPress={()=>this.props.setResetPasswordModalStatus(true)}
            >
              <Hoverable>
                {isHovered => (
                  <View>

                    {(<View style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>
                      <Text
                        style={{
                          color: "#000",
                          //fontWeight: "bold",
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                        
                        }}
                      >
                        Click {' '}
                      </Text>
                      <Text
                        style={{
                          color: "#000",
                         
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          textDecorationLine: isHovered == false && 'underline'
                          
                        }}
                      >
                        here
                      </Text>
                      <Text
                        style={{
                          color: "#000",
                         
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                        }}
                      >
                        {' '} to change Password
                      </Text>
                    </View>)}
                  </View>
                )

                }

              </Hoverable>
            </TouchableOpacity>


          </View>
        </>)} */}

      </View>
    );
  }
}

const mapStateToProps = state => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  // googleLogin: state.AdminReducer.googleLogin
});

const mapDispatchToProps = dispatch => ({
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  // setResetPasswordModalStatus: payload =>
  // dispatch(setRESETPASSWORDMODALACTION(payload)),
});

// const SecurityContainerWrapper = graphql(ChangeAccessKeyMutation, {
//   name: "ChangeAccessKey",
//   options: { fetchPolicy: "no-cache" }
// })(Security);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Security
);
