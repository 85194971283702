// import { fromJS, List } from "immutable";
// import Modal from "modal-enhanced-react-native-web";
import React from "react";
import {
  Dimensions,
  Platform,
  Text,
  View
} from "react-native";
import { Icon } from "react-native-elements";
// import Overlay from "react-native-modal-overlay";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import appolloClient from "../client";
// import CreateNewCliksModal from "../components/CreateNewCliksModal";
import ConstantFontFamily from "../constants/FontFamily";
import { SearchClikMutation } from "../graphqlSchema/SearchMutation";
import { SearchClikVariables } from "../graphqlSchema/SearchVariables";
import TrendingClik from "./TrendingClicks";

class CliksListDrawerScreens extends React.PureComponent {
  constructor(props) {
    super(props);
    this.timer;
    this.state = {
      createNewCliksModal: false,
      search: "",
      cliklistData: this.props.getUserFollowCliksList,
      showTooltip: false,
      showLeaveCliksModal: false,
      listTrending_cliks: this.props.listTrending_cliks
    };
  }


  updateClikSearch = async search => {
    this.setState({ search });
    let tempData = [];
    let tempArray = [];
    let memberType = "";

    if (search.length > 0 && this.props.current === 0) {
      SearchClikVariables.variables.prefix = search;
      appolloClient
        .query({
          query: SearchClikMutation,
          ...SearchClikVariables,
          fetchPolicy: "no-cache"
        })
        .then(res => {
          tempArray = res.data.search.cliks;
          for (let i = 0; i < tempArray.length; i++) {
            memberType = this.getMemberArray(tempArray[i].name);
            tempData.push({ clik: tempArray[i], member_type: memberType });
          }
          this.setState({
            cliklistData: fromJS(tempData),
            listTrending_cliks: []
          });
        });
    } else {
      this.setState({
        cliklistData: this.props.getUserFollowCliksList,
        listTrending_cliks: this.props.listTrending_cliks
      });
    }
  };

  getMemberArray = (itemId, clickArray) => {
    let member_type = "";
    const index = this.props.getUserFollowCliksList.findIndex(
      i =>
        i.node.clik.name ==
        itemId
          .replace("%3A", ":")
          .toLowerCase()
          .replace("clik:", "")
    );
    if (index != -1) {
      if (
        this.props.getUserFollowCliksList[index].node.member_type ==
        "SUPER_ADMIN"
      ) {
        member_type = "SUPER_ADMIN";
      } else if (
        this.props.getUserFollowCliksList[index].node.member_type ==
        "ADMIN"
      ) {
        member_type = "ADMIN";
      } else if (
        this.props.getUserFollowCliksList[index].node.member_type ==
        "MEMBER"
      ) {
        member_type = "MEMBER";
      }
    } else if (index == -1) {
      member_type = "FOLLOWER";
    }
    return member_type;
  };

  componentDidUpdate = async prevProps => {
    if (prevProps.searchedWord != this.props.searchedWord) {
      await this.updateClikSearch(this.props.searchedWord);
    } else if (prevProps.searchedWord.length == 0) {
      this.setState({ cliklistData: this.props.getUserFollowCliksList });
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  };
  toogle = () => {
    if (this.state.showTooltip == false) {
      this.setState({ showTooltip: true });
    } else {
      this.setState({ showTooltip: false });
    }
  };

  onClose = () => {
    this.setState({
      createNewCliksModal: false
    });
    this.setState({
      showLeaveCliksModal: false
    });
  };


  loginHandle = () => {
    this.props.setLoginModalStatus(true);
  };

  render() {
    const {
      createNewCliksModal
    } = this.state;
    return (
      <View>
        {/* {this.props.loginStatus == 1 ? ( */}
          <View>
            {/* {createNewCliksModal == true ? (
              Platform.OS !== "web" ? (
                <Overlay
                  animationType="zoomIn"
                  visible={createNewCliksModal}
                  onClose={this.onClose}
                  closeOnTouchOutside
                  children={
                    <CreateNewCliksModal
                      onClose={this.onClose}
                      {...this.props}
                    />
                  }
                  childrenWrapperStyle={{
                    padding: 0,
                    margin: 0
                  }}
                />
              ) : (
                <Modal
                  isVisible={createNewCliksModal}
                  onBackdropPress={this.onClose}
                  style={{
                    marginHorizontal:
                      Dimensions.get("window").width > 750 ? "30%" : 0
                  }}
                >
                  <CreateNewCliksModal onClose={this.onClose} {...this.props} />
                </Modal>
              )
            ) : null} */}

            <TrendingClik
              navigation={this.props.navigation}
              searchedFollowText={this.props.searchedWord}
            />
          </View>
        {/* ) : ( */}
          {/* <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Icon
              color={"#000"}
              iconStyle={{
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
              }}
              reverse
              name="tags"
              type="font-awesome"
              size={20}
              containerStyle={{
                alignSelf: "center"
              }}
            />
            <Text
              style={{
                fontSize: 14,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont,
                color: "#000",
                alignSelf: "center"
              }}
            >
              <Text
                onPress={() => this.loginHandle()}
                style={{ textDecorationLine: "underline" }}
              >
                Login
              </Text>{" "}
              to follow cliks
            </Text>
          </View>
        )} */}
      </View>
    );
  }
}

const mapStateToProps = state => ({
  // getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
  //   ? state.LoginUserDetailsReducer.userFollowCliksList
  //   : List(),
  // listTrending_cliks: !state.TrendingCliksReducer.getIn(["Trending_cliks_List"])
  //   ? List()
  //   : state.TrendingCliksReducer.getIn(["Trending_cliks_List"]),
  // loginStatus: state.UserReducer.get("loginStatus")
});

const mapDispatchToProps = dispatch => ({
  // setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CliksListDrawerScreens
);

