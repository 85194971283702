// import "@expo/browser-polyfill";
import { takeEvery, call, put, select } from "redux-saga/effects";
import {
  POSTDETAILS,
  // POSTDETAILS_SUCCESS,
  // POSTDETAILS_FAILURE,
} from "../constants/Action";
import appolloClient from "../client";
import {
  PostQuery,
  AdminPostQuery,
} from "../graphqlSchema/PostMutation";
import { PostQueryVariables } from "../graphqlSchema/PostVariables";
// import NavigationService from "../library/NavigationService";
import { Platform, Dimensions } from "react-native";
import { setPostDetailSuccess, setPostDetailFailure } from '../reducers/PostDetailsReducer';

const isWeb = Platform.OS === "web";

const getResponse = (req, isAdmin) => {
  PostQueryVariables.variables.id = req.id;
  return appolloClient
    .query({
      query: PostQuery,
      variables: {
        id: req.id,
        cliks_first: 20,
        topics_first: 20,
        users_first: 20,
        feeds_first: 20,
      },
      fetchPolicy: "no-cache",
    })

    .then((res) => {
      return res;
    });
};

export function* PostDetailsMiddleware({ payload }) {
  // console.log(payload,'payyyyyyyyyyy');
  // const getIsAdmin = (state) => state.AdminTrueFalseReducer.get("isAdmin");
  // const isAdmin = yield select(getIsAdmin);
  try {
    if (payload.id != "Post:") {
      const response = yield call(getResponse, payload, false);
      yield put(setPostDetailSuccess({
        // type: POSTDETAILS_SUCCESS,
        payload: response,
      }));
      // if (Dimensions.get("window").width <= 1000) {
      if (payload.navigate == true) {
        if (payload.title) {
          payload.navigation.navigate("PostDetailScreen")
        }
      }
    }

    // }
  } catch (err) {
    yield put(setPostDetailFailure({
      // type: POSTDETAILS_FAILURE,
      payload: err.message,
      comment: false,
    }));
    console.log(err);
  }
}

export default function* PostDetailsMiddlewareWatcher() {
  yield takeEvery(POSTDETAILS, PostDetailsMiddleware);
}
