
import { Component } from 'react';
import {
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    Platform
} from 'react-native';
import ButtonStyle from "../constants/ButtonStyle";
import { Icon } from "react-native-elements";
import ConstantFontFamily from "../constants/FontFamily";
import { connect } from "react-redux";
import { compose } from "react-recompose";
// import { openToolkit, toolkitOff, showDiscussion, showbox, getCommentTitle, userHighlight, getRepliedId, postCommentStatus } from '../reducers/action/Completed';
import { showComment, showbox } from "../reducers/ShowCommentReducer";
import TooltipBox from './TooltipBox';
import { getRepliedId } from '../reducers/AdminTrueFalseReducer';
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setLikeContent } from "../actionCreator/LikeContentAction";
import _ from "lodash";
import { OuterClick } from 'react-outer-click';
import ConstantColor from '../constants/Colors';
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import { openToolkit } from '../reducers/openTookitReducer';
import { setPostDetails } from "../actionCreator/PostDetailsAction";
import { screen } from '../reducers/ScreenNameReducer'
import { ContentReportMutation } from '../graphqlSchema/FeedMutation';
import appolloClient from "../client";

// import { title } from 'process';



class CommonTooltipBottomPost extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showTooltip: false,
            blueLikeCount: 0,
            yellowLikeCount: 0,
            silverLikeCount: 0,
            redLikeCount: 0,
            showRedHeart: true
        }
        this.props1 = this.props

    }

    increaseLikeCount = (id, likeColor) => {
        if (likeColor == 'DIAMOND') {
            this.setState({ blueLikeCount: this.state.blueLikeCount + 1 }, () => {
            })
        } else if (likeColor == 'GOLD') {
            this.setState({ yellowLikeCount: this.state.yellowLikeCount + 1 })
        } else if (likeColor == 'SILVER') {
            this.setState({ silverLikeCount: this.state.silverLikeCount + 1 })
        } else if (likeColor == 'RED') {
            console.log(this.state.redLikeCount, this.props.item);
            if (this.props.item.node?.num_likes?.num_red_likes == 0 && this.state.redLikeCount == 0) {
                this.setState({ redLikeCount: this.state.redLikeCount + 1 })
            }
        }
        this.props.LikeContent({
            content_id: this.props.type == "comment" ? id : id,
            like_type: likeColor
        });
        this.setState({ showTooltip: false, showRedHeart: false })
    }

    openCommentIdd = (id) => {
        this.setState({ openCommentId: id })
        this.props.showComment(true)
    }

    reportContent = (reportType) => {
        this.setState({ showTooltip: false })

        appolloClient
            .query({
                query: ContentReportMutation,
                variables: {
                    content_id: this.props.item.node.id,
                    report_type: reportType,

                },
                fetchPolicy: "no-cache",
            })
            .then(async (res) => {
                if (res.data.report_create.status.status == 'OK') {
                    alert('you have succesfully submitted a report of this content')
                }
            })
    }


    render() {
        const props = this.props1

        return (
            <>
                <View style={{ flexDirection: 'row', alignItems: this.props.margin != 30 && this.props.type == "comment" ? 'center' : null, justifyContent: this.props.margin != 30 && this.props.type == "comment" ? 'center' : null }}>


                    {this.props?.item?.node?.num_likes?.num_gold_likes > 0 || this.state.yellowLikeCount > 0 ? <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>

                        <Icon
                            color={'#ffce44'}
                            name={"heart"}
                            type="font-awesome"
                            size={12}
                            iconStyle={{ alignSelf: "center" }}
                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                        />
                        <Text
                            style={{
                                paddingLeft: 4,
                                fontSize: 13,
                                fontFamily: ConstantFontFamily.defaultFont,
                                color: ConstantColor.blueColor
                            }}
                        >
                            {this.props?.item?.node?.num_likes?.num_gold_likes > 0 ? this.props?.item?.node?.num_likes?.num_gold_likes : this.state.yellowLikeCount}

                        </Text>
                    </View> : null}


                    {this.props?.item?.node?.num_likes?.num_diamond_likes > 0 || this.state.blueLikeCount > 0 ?
                        <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>

                            <Icon
                                color={ConstantColor.blueColor}
                                name={"heart"}
                                type="font-awesome"
                                size={12}
                                iconStyle={{ alignSelf: "center" }}
                                containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                            />
                            <Text
                                style={{

                                    paddingLeft: 4,
                                    fontSize: 13,
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    color: ConstantColor.blueColor
                                }}
                            >
                                {this.props.item.node.num_likes.num_diamond_likes > 0 ? this.props.item.node.num_likes.num_diamond_likes : this.state.blueLikeCount > 0 && this.state.blueLikeCount}

                            </Text>
                        </View> : null}

                    {this.props?.item?.node?.num_likes?.num_silver_likes > 0 || this.state.silverLikeCount > 0 ?
                        <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>
                            <Icon
                                color={'#b0b0b0'}
                                name={"heart"}
                                type="font-awesome"
                                size={12}
                                iconStyle={{ alignSelf: "center" }}
                                containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                            />
                            <Text
                                style={{
                                    // width: 15,
                                    paddingLeft: 4,
                                    fontSize: 14,
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    color: ConstantColor.blueColor
                                }}
                            >{this.props?.item?.node?.num_likes ? this.props?.item?.node?.num_likes?.num_silver_likes + this.state.silverLikeCount : props?.item?.item?.node?.num_likes?.num_silver_likes + this.state.silverLikeCount}
                            </Text>
                        </View> : null}




                    <TouchableOpacity
                        style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}
                        onPress={() => {
                            if (this.props.type == "comment") {
                                if (this.props.item?.num_likes?.num_red_likes == 0 && this.state.redLikeCount == 0) {
                                    this.increaseLikeCount(this.props?.item?.id, 'RED')
                                }

                            } else {
                                if (this.state.redLikeCount == 0) {
                                    this.increaseLikeCount(this.props?.item?.node?.id, 'RED')
                                }

                            }
                        }

                        }
                    >
                        <Icon
                            color={"#de5246"}
                            name={"heart"}
                            type="font-awesome"
                            size={12}

                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                        />

                        {this.props?.item?.node?.num_likes?.num_red_likes > 0 || this.state.redLikeCount > 0 ?
                            <Text
                                style={{

                                    paddingLeft: 4,
                                    fontSize: 14,
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    color: ConstantColor.blueColor
                                }}
                            >
                                {
                                    this.props.item.node.num_likes.num_red_likes > 0 ? this.props.item.node.num_likes.num_red_likes : this.state.redLikeCount > 0 && this.state.redLikeCount}
                            </Text> : null}
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}
                        onPress={async () => {

                            // this.props.focusCreateComment(true)
                            // this.props.handleDoubleTap()
                            // console.log(this.props);
                            if (Dimensions.get("window").width < 750) {
                                console.log(this.props.navigation,'this.props.navigation');

                                this.props.screen("comment")
                                this.props.navigation.navigate("comment")
                            }

                            this.props.setPostCommentDetails({
                                id: this.props.item.node.id,
                                title: this.props.item.node.title,
                                loading: false
                            })

                            this.props.setPostDetails({
                                id: this.props.item.node.id,
                                title: this.props.item.node.title,
                                navigate: false,
                                navigation: this.props.props.navigation
                            })
                            this.props.showbox({ show: true, id: this.props.item.node.id, showmoreId: "" })
                            this.props.showComment(true)


                            if (this.props.type == "comment") {

                                if (this.props.loginStatus == 1) {

                                    // await this.props.postCommentStatus(false)
                                    await this.props.getRepliedId(this.props.item.id)

                                    // await this.props.focusCreateComment(true)
                                    // await this.props.setUserr(this.props.item?.author?.username)
                                    if (this.props.lastcomment == 'no') {
                                        await this.props.showbox({ show: true, id: this.props.item.id, showmoreId: '' })
                                    } else {
                                        await this.props.showbox({ show: true, id: this.props.item.id, showmoreId: this.props.parentIdd.id })
                                    }


                                } else {

                                    await props.setLoginModalStatus(true)


                                }
                            } else {
                                this.props.showbox({ show: true, id: this.props.PostId })



                            }
                        }
                        }
                    >
                        <Icon
                            color={ConstantColor.blueColor}
                            name={"comment"}
                            type="font-awesome"
                            size={12}

                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                        />
                        {this.props?.props?.item?.item?.node.num_comments > 0 ?
                            <Text
                                style={{

                                    paddingLeft: 4,
                                    fontSize: 14,
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    color: ConstantColor.blueColor
                                }}
                            >
                                {this.props?.props?.item?.item?.node.num_comments ? this.props?.props?.item?.item?.node.num_comments : 0}
                            </Text>
                            : null}
                    </TouchableOpacity>

                    <View style={{ position: "relative", alignItems: "center", flexDirection: 'row' }}>
                        {this.state.showTooltip == true && this.props.openId == this.props?.item?.node?.id ?
                            <>
                                {Platform.OS == 'web' ?
                                    <OuterClick
                                        onOuterClick={() => {
                                            this.setState({ showTooltip: false })
                                            // this.props.toolkitOff(true)
                                        }}
                                        style={{ flexDirection: "row", width: "100%", alignItems: "center" }}
                                    >
                                        <TooltipBox increaseLikeCount={this.increaseLikeCount} reportContent={this.reportContent} type={this.props.type} commentId={this.props.type != "post" ? this.props?.item?.id : null} postId={this.props.type == "post" ? this.props?.item?.node?.id ? this.props?.item?.node?.id : this.props?.props?.item?.node?.id : null} profileData={this.props.profileData} />
                                    </OuterClick> : <TooltipBox increaseLikeCount={this.increaseLikeCount} reportContent={this.reportContent} type={this.props.type} commentId={this.props.type != "post" ? this.props?.item?.id : null} postId={this.props.type == "post" ? this.props?.item?.node?.id ? this.props?.item?.node?.id : this.props?.props?.item?.node?.id : null} profileData={this.props.profileData} />}
                            </>
                            : null}

                        {this.props.addIcon == props?.item?.item?.node?.id || this.props.addIcon == this.props?.props?.item?.node?.id || this.props.nestedId == this.props?.item?.id ? <Icon
                            size={20}
                            iconStyle={{ fontWeight: '100', minWidth: 50 }}
                            name="add"
                            type="ion-icon"
                            color={"#899198"}
                            onPress={() => {
                                // console.log(this.props)
                                this.props.openToolkit(this.props?.item?.node?.id)
                                if (this.props.openId == this.props?.item?.node?.id) {
                                    this.setState({ showTooltip: !this.state.showTooltip })

                                } else if (this.props.openId == this.props?.item?.node?.id) {

                                    this.setState({ showTooltip: !this.state.showTooltip })

                                } else {
                                    this.setState({ showTooltip: !this.state.showTooltip })

                                }

                            }}
                        /> : <View style={{ width: 50 }}></View>}

                    </View>

                </View>
            </>
        )
    }
}
const mapStateToProps = (state) => ({

    openId: state.openTookitReducer.openId,
    // totalComment: state.TotalCommentReducer.totalComment,
    // nestedComment: state.NestedCommentReducer.nestedComment,
    // PostId: state.PostCommentDetailsReducer.get("PostId"),
    // postCommentId: state.PostCommentIdReducer.id,
    profileData: state.LoginUserDetailsReducer.userLoginDetails,
    // nestedId: state.showMoreNestedReducer.nestedId,
    // addIcon: state.addIconReducer.id,
    loginStatus: state.UserReducer.loginStatus,
    PostId: state.PostCommentDetailsReducer.PostId,






});
const mapDispatchToProps = dispatch => ({
    // userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
    // sharePostId: (payload) => dispatch({ type: "SHAREDPOSTID", payload }),
    setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
    openToolkit: (payload) => dispatch(openToolkit(payload)),
    // focusCreateComment: (payload) =>
    //     dispatch({ type: "FOCUS_CREATE_COMMENT", payload }),
    LikeContent: payload => dispatch(setLikeContent(payload)),
    // toolkitOff: (payload) => dispatch(toolkitOff(payload)),
    setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),
    // showDiscussion12: (payload) => dispatch(showDiscussion(payload)),
    showbox: (payload) => dispatch(showbox(payload)),
    // getCommentTitle: (payload) => dispatch(getCommentTitle(payload)),
    // setUserr: (payload) => dispatch(userHighlight(payload)),
    getRepliedId: (payload) => dispatch(getRepliedId(payload)),
    showComment: (payload) =>
        dispatch(showComment(payload)),
    setPostDetails: payload => dispatch(setPostDetails(payload)),
    screen: (payload) => dispatch(screen(payload)),


    // postCommentStatus: (payload) => dispatch(postCommentStatus(payload))


});
export default compose(connect(mapStateToProps, mapDispatchToProps))(CommonTooltipBottomPost)
