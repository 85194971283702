// import { logInAsync } from "expo-google-app-auth";
import { getAuth, signInWithEmailAndPassword, getReactNativePersistence, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signInWithCredential, signInAndRetrieveDataWithCredential } from "firebase/auth";
import jwt_decode from "jwt-decode";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
import * as React from 'react';
import { Component, useState, useEffect } from "react";
// import { graphql } from "react-apollo";
import { graphql } from '@apollo/client/react/hoc';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  // AsyncStorage,
  Dimensions,
  Image,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Alert
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { setAdminStatus } from "../reducers/AdminTrueFalseReducer";
import { getHomefeedList } from "../actionCreator/HomeFeedAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setRESETPASSWORDMODALACTION } from "../actionCreator/ResetPasswordModalAction";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
import { getTrendingExternalFeeds } from "../actionCreator/TrendingExternalFeedsAction";
import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
import { getTrendingUsers } from "../actionCreator/TrendingUsersAction";
// import {
//   // saveUserLoginDaitails,
//   setLoginStatus
// } from "../actionCreator/UserAction";
import { setLoginStatus } from '../reducers/UserReducer';
import { saveUserLoginDaitails } from '../reducers/LoginUserDetailsReducer';
import { setUserApproachAction } from "../actionCreator/UserApproachAction";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import appolloClient from "../client";
// import "../components/Firebase";
// import LoaderComponent from "../components/LoaderComponent";
// import AppHelper from "../constants/AppHelper";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
import {
  HomeFeedMutation
} from "../graphqlSchema/FeedMutation";
import { UserLoginMutation, UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation } from "../graphqlSchema/UserMutation";
// import { setLocalStorage } from "../library/Helper";
// import NavigationService from "../library/NavigationService";
import ButtonStyle from "../constants/ButtonStyle";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import * as Google from 'expo-auth-session/providers/google';
// import * as WebBrowser from 'expo-web-browser';
// import { makeRedirectUri } from "expo-auth-session";
import * as AuthSession from "expo-auth-session";
import Colors from "../constants/Colors";
import { setGoogleLogin } from '../reducers/AdminTrueFalseReducer';
// import app from "../components/Firebase"
// import axios from "axios";





const apiUrl = getEnvVars();

// WebBrowser.maybeCompleteAuthSession();
const NATIVE_REDIRECT_PARAMS = { native: "com.weclikd.weclikd://" }
const redirectUri = AuthSession.makeRedirectUri(NATIVE_REDIRECT_PARAMS)


const LoginModal = (props) => {

  const [googleAccessToken, setGoogleAccessToken] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState(false);
  const [loginBtnColor, setloginBtnColor] = useState(Colors.buttonbackground)


  const [request, response, promptAsync] = Google.useAuthRequest({
    expoClientId: apiUrl.EXPOCLIENT_ID,
    iosClientId: apiUrl.IOSCLIENT_ID,
    androidClientId: apiUrl.ANDROIDCLIENT_ID,
    webClientId: apiUrl.EXPOCLIENT_ID,
    redirectUri:"com.weclikd.weclikd://"
  });

  React.useEffect(() => {
    if (Platform.OS != "web") {
      console.log(request,'ksckdk');


      oAuthFuntion()

      // alert(JSON.stringify(cred))

    }
  }, [response]);

  const oAuthFuntion = () => {

    if (response?.type === 'success') {
      const { authentication } = response;
      var credential = GoogleAuthProvider.credential(null, authentication.accessToken);
      // alert(JSON.stringify(credential))
      const auth = getAuth()
      const cred = signInWithCredential(auth, credential).then(async e => {
        // alert(JSON.stringify(e))
        await auth
          .currentUser.getIdToken(true)
          .then(async function (idToken) {
            // alert(idToken);
            // Alert(idToken, 'token hit')
            await AsyncStorage.setItem("userIdTokenFirebase", idToken);
            if (Platform.OS == 'web') {
              await setLocalStorage(
                "admin",
                jwt_decode(idToken)?.claims?.admin ? "true" : "false"
              );
            }


            await props.changeAdminStatus(
              jwt_decode(idToken)?.claims?.admin
                ? jwt_decode(idToken)?.claims?.admin
                : false
            );
          })
          .then(async () => {
            // alert('login mutation')
            await appolloClient
              .query({
                query: UserLoginMutation,
                variables: {
                  id: "Account:" + "CurrentUser"
                },
                fetchPolicy: "no-cache"
              }).then(async res => {
                if (res.data.node.user == null) {
                  // props.setUsernameModalStatus(true);
                } else {
                  let userfollow = await appolloClient
                    .query({
                      query: UserFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e?.data?.node?.users_following?.edges
                    })

                  let topicfollow = await appolloClient
                    .query({
                      query: TopicFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e?.data?.node?.topics_following?.edges
                    })
                  let clikfollow = await appolloClient
                    .query({
                      query: ClikFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e?.data?.node?.cliks_following?.edges
                    })
                  let externalfollow = await appolloClient
                    .query({
                      query: ExternalFeedFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e?.data?.node?.external_feeds_following?.edges
                    })
                  let userData = {
                    data: res.data.node,
                    externalfollow: externalfollow,
                    clikfollow: clikfollow,
                    topicfollow: topicfollow,
                    userfollow: userfollow

                  }
                  props.onClose()
                  await props.saveLoginUser(userData);
                  await props.changeLoginStatus(1);


                  if(res?.data?.node?.user?.id){

                  await AsyncStorage.setItem(
                    "userLoginId",
                    res.data.node.user.id
                  );
                  await AsyncStorage.setItem(
                    "MyUserUserId",
                    res.data.node.user.id
                  );
                  await AsyncStorage.setItem(
                    "userIdTokenWeclikd",
                    // state.access_key
                    ""
                  );
                  await AsyncStorage.setItem(
                    "UserId",
                    res.data.node.user.id
                  );
                }


                  onClose();
                  // NavigationService.navigate("home");
                }
              }).catch(e => console.log(e))
          })
      })
    }
  }



  let [UserEmail, setUserEmail] = useState('')
  let [UserPassword, setUserPassword] = useState('')
  let [errorMsg, setErrorMsg] = useState('')

  let [buttonName, setButtonName] = useState('')
  let [rememberMe, setrememberMe] = useState(false)
  let [loading, setloading] = useState(false)
  let [pageInfo, setpageInfo] = useState('')
  let [page, setPage] = useState(1)
  let [access_key, setAccessKey] = useState('')

  let _isMounted = false;




  // useEffect(() => {
  //   _isMounted = true;
  //   // let __self = this;
  //   let res = AsyncStorage.getItem("rememberMe")
  //   if (res) {
  //     // setState({
  //     //   rememberMe: true,
  //     //   UserEmail: res.UserEmail,
  //     //   UserPassword: res.UserPassword,
  //     //   buttonName: "Login"
  //     // });
  //     setrememberMe(true)
  //     setUserEmail(res.UserEmail)
  //     UserPassword(res.UserPassword)
  //     setButtonName("Login")

  //   } else {
  //     // setState({
  //     //   rememberMe: false,
  //     //   buttonName: "Login"
  //     // });
  //     setrememberMe(false)
  //     setButtonName("Login")
  //   }
  // }, [])

  // React.useEffect(() => {
  //   if (response?.type === 'success') {
  //     console.log(response, 'ress');
  //     const { id_token } = response.params;
  //     const auth = getAuth();
  //     const credential = GoogleAuthProvider.credential(id_token);
  //     signInWithCredential(auth, credential);
  //   }
  // }, [response]);


  const loginWithGoogle = async () => {
    if (Platform.OS == "web") {
      await loginWithGoogleWeb();

    } else {
      await props.leftPanelModalFunc(false)
      await loginWithGoogleMobile();
    }
  };

  const loginWithGoogleWeb = async () => {

    // setState({
    //   buttonName: "Logging in...",
    //   UserEmail: "",
    //   UserPassword: ""
    // });
    setButtonName("Logging in...")
    setUserEmail('')
    setUserPassword('')
    await props.setLoginButtonText("Logging in...");
    await props.setGoogleLogin(true);
    await props.setUserApproachAction({ type: "login" });
    // await props.setAnonymousUser({ "value": false, "token": "" });
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const auth = getAuth();
    await signInWithPopup(auth, provider);

    // getNewFeedList()

    await appolloClient
      .query({
        query: UserLoginMutation,
        variables: {
          id: "Account:" + "CurrentUser"
        },
        fetchPolicy: "no-cache"
      }).then(async res => {

        let userfollow = await appolloClient
          .query({
            query: UserFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.users_following.edges
          })

        let topicfollow = await appolloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.topics_following.edges
          })
        let clikfollow = await appolloClient
          .query({
            query: ClikFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.cliks_following.edges
          })
        let externalfollow = await appolloClient
          .query({
            query: ExternalFeedFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.external_feeds_following.edges
          })
        let userData = {
          data: res.data.node,
          externalfollow: externalfollow,
          clikfollow: clikfollow,
          topicfollow: topicfollow,
          userfollow: userfollow

        }
        await props.saveLoginUser(userData);
        setTimeout(() => {
          setButtonName("Login")
          // setState({
          //   buttonName: "Login"
          // });
          props.setLoginButtonText("Login");
        }, 2000);

        props.userId({
          username: res.data.node.user.username,
          type: "feed",
        });
        await AsyncStorage.setItem(
          "userLoginId",
          res.data.node.user.id
        );
        await AsyncStorage.setItem(
          "MyUserUserId",
          res.data.node.user.id
        );
        await AsyncStorage.setItem(
          "userIdTokenWeclikd",
          ""
        );
        await AsyncStorage.setItem(
          "UserId",
          res.data.node.user.id
        );
        await props.changeLoginStatus(1);
        props.onClose()
      })

  };

  const loginWithGoogleMobile = async () => {
    // try {
    //   const result = await Google.logInAsync({
    //     androidClientId: apiUrl.ANDROIDCLIENT_ID,
    //     iosClientId: apiUrl.IOSCLIENT_ID,
    //     iosStandaloneAppClientId: apiUrl.IOSCLIENT_ID,
    //     androidStandaloneAppClientId: apiUrl.ANDROIDCLIENT_ID,
    //     scopes: ["profile", "email"]
    //   });
    //   console.log('pass');
    //   if (result.type === "success") {
    //     console.log('success');
    //     await props.setAnonymousUser({ "value": false, "token": "" }),
    //       await onSignIn(result);
    //   } else {
    //     return { cancelled: true };
    //   }
    // } catch (e) {
    //   return { error: true };
    // }
  };

  // const onSignIn = async (idToken, access_token) => {
  //   alert(access_token,'acessss')
  //   // onClose();
  //   // // const auth = getAuth()
  //   // let unsubscribe = await firebase.auth().onAuthStateChanged(async (firebaseUser) => {
  //   // console.log(firebaseUser, 'firebaseUser');
  //   try {
  //     // let options = {
  //     //   method:"POST",
  //     //   headers:{
  //     //     "Authorization":`Bearer ${access_token}`
  //     //   }
  //     // }

  //     // alert('begin cred');
  //     var credential = firebase.auth.GoogleAuthProvider.credential(access_token);
  //     alert(JSON.stringify(credential), 'creddd');
  //     console.log(credential,'credential');
  //     // axios.post(`https://weclikd-prod.firebaseio.com/users/ada/name.json?access_token=%${access_token}`,options).then(e=>{
  //     //   alert(e,'apiieee');
  //     // }).catch(e=>{
  //     //   alert(e)
  //     // })
  //     // "https://<DATABASE_NAME>.firebaseio.com/users/ada/name.json?access_token=<ACCESS_TOKEN>"
  //   // firebase.auth()
  //     // .signInWithCredential(credential)
  //     //   .then(async function (result) {
  //     //     alert(JSON.stringify(result));
  //         // if ((await result.UserCredentialImpl.user.isAnonymous) == true) {
  //         //   try {
  //         //     return await appolloClient
  //         //       .query({
  //         //         query: UserLoginMutation,
  //         //         variables: {
  //         //           id: "Account:" + "CurrentUser"
  //         //         },
  //         //         fetchPolicy: "no-cache"
  //         //       })
  //         //   } catch (e) {
  //         //     props.setUsernameModalStatus(true);  
  //         //   }
  //         // } else {
  //         // await firebase.auth()
  //         //   .currentUser.getIdToken(true)
  //         //   .then(async function (idToken) {
  //         //     alert(idToken);
  //         //     // Alert(idToken, 'token hit')
  //         //     await AsyncStorage.setItem("userIdTokenFirebase", idToken);
  //         //     await setLocalStorage(
  //         //       "admin",
  //         //       jwt_decode(idToken)?.claims?.admin ? "true" : "false"
  //         //     );

  //         //     await props.changeAdminStatus(
  //         //       jwt_decode(idToken)?.claims?.admin
  //         //         ? jwt_decode(idToken)?.claims?.admin
  //         //         : false
  //         //     );
  //         //   })
  //         //   .then(async () => {
  //             alert('login mutation')
  //             await appolloClient
  //               .query({
  //                 query: UserLoginMutation,
  //                 variables: {
  //                   id: "Account:" + "CurrentUser"
  //                 },
  //                 fetchPolicy: "no-cache"
  //               }).then(async res => {
  //                 console.log(res, 'ressssss');
  //                 if (res.data.node.user == null) {
  //                   props.setUsernameModalStatus(true);
  //                 } else {
  //                   let userfollow = await appolloClient
  //                     .query({
  //                       query: UserFollowingMutation,
  //                       variables: {
  //                         id: "User:CurrentUser",
  //                         first: 20
  //                       },
  //                       fetchPolicy: "no-cache"
  //                     }).then(e => {
  //                       return e?.data?.node?.users_following?.edges
  //                     })

  //                   let topicfollow = await appolloClient
  //                     .query({
  //                       query: TopicFollowingMutation,
  //                       variables: {
  //                         id: "User:CurrentUser",
  //                         first: 20
  //                       },
  //                       fetchPolicy: "no-cache"
  //                     }).then(e => {
  //                       return e?.data?.node?.topics_following?.edges
  //                     })
  //                   let clikfollow = await appolloClient
  //                     .query({
  //                       query: ClikFollowingMutation,
  //                       variables: {
  //                         id: "User:CurrentUser",
  //                         first: 20
  //                       },
  //                       fetchPolicy: "no-cache"
  //                     }).then(e => {
  //                       return e?.data?.node?.cliks_following?.edges
  //                     })
  //                   let externalfollow = await appolloClient
  //                     .query({
  //                       query: ExternalFeedFollowingMutation,
  //                       variables: {
  //                         id: "User:CurrentUser",
  //                         first: 20
  //                       },
  //                       fetchPolicy: "no-cache"
  //                     }).then(e => {
  //                       return e?.data?.node?.external_feeds_following?.edges
  //                     })
  //                   let userData = {
  //                     data: res.data.node,
  //                     externalfollow: externalfollow,
  //                     clikfollow: clikfollow,
  //                     topicfollow: topicfollow,
  //                     userfollow: userfollow

  //                   }
  //                   await props.saveLoginUser(userData);
  //                   await props.changeLoginStatus(1);


  //                   await AsyncStorage.setItem(
  //                     "userLoginId",
  //                     res.data.node.user.id
  //                   );
  //                   await AsyncStorage.setItem(
  //                     "MyUserUserId",
  //                     res.data.node.user.id
  //                   );
  //                   await AsyncStorage.setItem(
  //                     "userIdTokenWeclikd",
  //                     // state.access_key
  //                     ""
  //                   );
  //                   await AsyncStorage.setItem(
  //                     "UserId",
  //                     res.data.node.user.id
  //                   );

  //                   onClose();
  //                   NavigationService.navigate("home");
  //                 }



  //               }).catch(error => {
  //                 console.log(error.message, 'error==========');
  //                 alert("Invalid email or password");
  //                 // setState({ errorMsg: "Invalid email or password" });
  //                 setErrorMsg('Invalid email or password')
  //                 return false;
  //               });
  //           // })

  //         // }
  //       // })

  //   } catch (e) {
  //     alert(e)
  //   }

  //   // });
  //   // unsubscribe();
  // };

  const enterPressed = e => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      userLogin();
    }
  };


  const userResetModal = async () => {
    props.setLoginModalStatus(false);
    props.setResetPasswordModalStatus(true);
  };

  const onModalClose = async () => {

    // setState({ buttonName: "Logged In!" });
    setButtonName("Logged In!")
    // await props.setLoginButtonText("Logged In!");
    // props.setLoginModalStatus(false);
    setTimeout(() => {
      props.setLoginModalStatus(false);


      // props.setLoginButtonText("Login");

    }, 500);
  };

  const onClose = async () => {
    props.setLoginModalStatus(false);
    // props.setLoginButtonText("Login");
    setButtonName("Login")
  };

  const getTrendingFeedList = async () => {
    appolloClient
      .query({
        query: HomeFeedMutation,
        variables: {
          first: 24,
          after: null,
          sort: "TRENDING",
          type: 'Post'
        },
        fetchPolicy: "no-cache",
      })
      .then(response => {
        // props.setTrendingHomeFeed(response.data.node_list.edges);
      })
      .catch(e => {
        console.log(e.message);
        // props.setTrendingHomeFeed([]);
      });
  };

  const getNewFeedList = async () => {
    // const { page, pageInfo } = state;


    appolloClient
      .query({
        query: HomeFeedMutation,
        variables: {
          first: 12,
          after: pageInfo ? pageInfo.endCursor : null,
          sort: "NEW",
          type: 'Post'
        },
        fetchPolicy: "no-cache",
      })
      .then(response => {
        // props.setNewHomeFeed(response.data.node_list.edges);
        // props.setUpdatedPostId(response.data.node_list.edges[0].node.id)

        // props.setPostCommentReset({
        //   payload: [],
        //   postId: '',
        //   title: '',
        //   loading: true
        // });
        // props.setPostCommentDetails({
        //   id: "Post:" + response.data.node_list.edges[0].node.id.replace("Post:", ""),
        //   title: response.data.node_list.edges[0].node.title,
        //   loading: false

        // });



      })
      .catch(e => {
        console.log(e);
        // props.setNewHomeFeed([]);
      });
  };

  const getDiscussedFeedList = () => {
    appolloClient
      .query({
        query: HomeFeedMutation,
        variables: {
          first: 24,
          after: null,
          sort: "DISCUSSING",
          type: 'Post'
        },
        fetchPolicy: "no-cache",
      })
      .then(response => {
        // props.setDiscussionHomeFeed(response.data.node_list.edges);
      })
      .catch(e => {
        console.log(e);
        // props.setDiscussionHomeFeed([]);
      });
  };

  const userLogin = async () => {

    let __self = this;
    setButtonName("Logging in...")

    // await props.setLoginButtonText("Logging in...");

    // const { UserEmail, UserPassword } = state;
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, UserEmail, UserPassword)
      .then(async res => {

        return res.user.getIdToken(true).then(function (idToken) {
          console.log(idToken, 'lets us check');

          // setLocalStorage('uid', jwt_decode(idToken).user_id)
          // setLocalStorage(
          //   "admin",
          //   jwt_decode(idToken)?.claims?.admin ? true : false:
          // );

          AsyncStorage.setItem('uid', jwt_decode(idToken).user_id)
          // AsyncStorage.setItem(
          //   "admin",
          //   jwt_decode(idToken)?.claims?.admin ? true : false
          // );
          props.changeAdminStatus(
            jwt_decode(idToken)?.claims?.admin ? jwt_decode(idToken)?.claims?.admin : false
          );


          if (Platform.OS == "web") {
            // __self.setUserNameInExtension = 
            setLoginTokenInExtension(
              idToken
            );
          }

          AsyncStorage.setItem("userIdTokenFirebase", idToken);
          return idToken

          // return setLocalStorage("userIdTokenFirebase", idToken);
        });

      })
      .then(async res => {
        console.log(res, 'it is mutation now');
        await appolloClient
          .query({
            query: UserLoginMutation,
            variables: {
              id: "Account:CurrentUser"
            },
            fetchPolicy: "no-cache"
          }).then(async res => {
            // console.log(res,'loginnnn data');
            let userfollow = await appolloClient
              .query({
                query: UserFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {

                return e.data.node.users_following.edges
              })

            let topicfollow = await appolloClient
              .query({
                query: TopicFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.topics_following.edges
              })
            let clikfollow = await appolloClient
              .query({
                query: ClikFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.cliks_following.edges
              })
            let externalfollow = await appolloClient
              .query({
                query: ExternalFeedFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.external_feeds_following.edges
              })
            let userData = {
              data: res.data.node,
              externalfollow: externalfollow,
              clikfollow: clikfollow,
              topicfollow: topicfollow,
              userfollow: userfollow

            }
            await props.saveLoginUser(userData);
            AsyncStorage.setItem("skipCredentials", "true");
            setButtonName("Login")

            // setState({
            //   buttonName: "Login"
            // });
            props.setLoginButtonText("Login");
            await props.setGoogleLogin(false);
            await props.changeLoginStatus(1);

            // await props.setAnonymousUser({ "value": false, "token": "" }),
            await getTrendingFeedList();
            await getNewFeedList();
            await getDiscussedFeedList();
            // await props.getHomefeed({
            //   currentPage: AppHelper.PAGE_LIMIT
            // });
            // await props.getTrendingUsers({
            //   currentPage: AppHelper.PAGE_LIMIT
            // });
            // await props.getTrendingClicks({
            //   currentPage: AppHelper.PAGE_LIMIT
            // });
            // await props.getTrendingTopics({
            //   currentPage: AppHelper.PAGE_LIMIT
            // });
            // await props.getTrendingExternalFeeds({
            //   currentPage: AppHelper.PAGE_LIMIT
            // });
            if (res?.data?.node?.user?.id) {


              await AsyncStorage.setItem(
                "userLoginId",
                res.data.node.user.id
              );
              await AsyncStorage.setItem(
                "MyUserUserId",
                res.data.node.user.id
              );
              await AsyncStorage.setItem(
                "userIdTokenWeclikd",
                ""
              );
              await AsyncStorage.setItem(
                "UserId",
                res.data.node.user.id
              );
            }
            if (res?.data?.node?.user?.username) {


              await AsyncStorage.setItem(
                "UserName",
                res.data.node.user.username
              );
            }
            await AsyncStorage.setItem(
              "profile",
              res.data.node?.user?.profile_pic ? res.data.node?.user?.profile_pic : ""
            );


            if (Platform.OS == "web") {
              await setLoginTokenInExtension(
                res.data.node.user.username
              );
            }
            if (Platform.OS != "web") {
              this.props.navigation.navigate("home")
            }

            // await props.leftPanelModalFunc(false)

            await onModalClose()




          }).catch(e => console.log(e))



      })

      .catch(error => {
        // setState({
        //   loading: false
        // });
        setloading(false)
        console.log(error, 'login eror');
        alert(JSON.stringify(error))
        if (error.code == "auth/wrong-password") {
          // setState({
          //   errorMsg: "Invalid email or password",
          //   buttonName: "Login"
          // });
          setErrorMsg("Invalid email or password")
          setButtonName("Login")
          props.setLoginButtonText("Login");
        } else {
          setErrorMsg("User not found")
          setButtonName("Login")
          // setState({ errorMsg: "User not found", buttonName: "Login" });
          props.setLoginButtonText("Login");
        }
        return false;
      });






  };



  const handleSignButton = async () => {
    await props.setLoginModalStatus(false);
    await props.setInviteUserDetail({
      clikName: "",
      inviteKey: "",
      userName: ""
    });
    await props.setUsernameModalStatus(true);

  };

  //--------------------------- Set Firebase Token in Extension -----------------------------
  const setLoginTokenInExtension = idToken => UserName => {
    try {
      window.parent.postMessage(
        { type: "wecklid_login", userIdTokenFirebase: idToken, UserName },
        "*"
      );
    } catch (e) {
      console.log("extension login Error ", e);
    }
  };
  //------------------------------------------------------------------------------------------

  const anonymousUserLogin = async () => {
    // let __self = this;

    setButtonName("Logging in...")
    await props.setLoginButtonText("Logging in...");
    await setLocalStorage("userIdTokenFirebase", props.getAnonymousToken);
    try {

      await appolloClient
        .query({
          query: UserLoginMutation,
          variables: {
            id: "Account:" + "CurrentUser"
          },
          fetchPolicy: "no-cache"
        }).then(async res => {

          let userfollow = await appolloClient
            .query({
              query: UserFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.users_following.edges
            })

          let topicfollow = await appolloClient
            .query({
              query: TopicFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.topics_following.edges
            })
          let clikfollow = await appolloClient
            .query({
              query: ClikFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.cliks_following.edges
            })
          let externalfollow = await appolloClient
            .query({
              query: ExternalFeedFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.external_feeds_following.edges
            })
          let userData = {
            data: res.data.node,
            externalfollow: externalfollow,
            clikfollow: clikfollow,
            topicfollow: topicfollow,
            userfollow: userfollow

          }
          await props.saveLoginUser(userData);
          await props.changeLoginStatus(1);
          props.setLoginButtonText("Login");
          // await props.setAnonymousUser({ "value": false, "token": "" }),
          await getTrendingFeedList();
          await getNewFeedList();
          await getDiscussedFeedList();
          await props.getHomefeed({
            currentPage: AppHelper.PAGE_LIMIT
          });
          await props.getTrendingUsers({
            currentPage: AppHelper.PAGE_LIMIT
          });
          await props.getTrendingClicks({
            currentPage: AppHelper.PAGE_LIMIT
          });
          await props.getTrendingTopics({
            currentPage: AppHelper.PAGE_LIMIT
          });
          await props.getTrendingExternalFeeds({
            currentPage: AppHelper.PAGE_LIMIT
          });
          await AsyncStorage.setItem(
            "userLoginId",
            res.data.node.user.id
          );
          await AsyncStorage.setItem(
            "MyUserUserId",
            res.data.node.user.id
          );
          await AsyncStorage.setItem(
            "userIdTokenWeclikd",
            ""
          );
          await AsyncStorage.setItem(
            "UserId",
            res.data.node.user.id
          );
          await AsyncStorage.setItem(
            "UserName",
            res.data.node.user.username
          );
          if (Platform.OS == "web") {
            await setUserNameInExtension(
              res.data.node.user.username
            );
          }
          await props.leftPanelModalFunc(false)
          await onModalClose();
        })

    } catch (error) {
      // setState({
      //   loading: false
      // });
      setloading(false)
      console.log(error);
      // props.setAnonymousUser({ "value": false, "token": "" });
      if (error.code == "auth/wrong-password") {
        setErrorMsg("Invalid email or password")
        setButtonName("Login")
        props.setLoginButtonText("Login");
      } else {
        setErrorMsg("User not found")
        setButtonName("Login")
        // setState({ errorMsg: "User not found", buttonName: "Login" });
        props.setLoginButtonText("Login");
      }
      return false;
    }
  }

  return (
    // <Button
    //   disabled={!request}
    //   title="Login"
    //   onPress={() => {
    //     promptAsync();
    //   }}
    // />


    <View
      style={{
        backgroundColor: "#f4f4f4",
        borderColor: "#c5c5c5",
        borderRadius: 6,
        width: Dimensions.get('window').width >= 750 ? 450 : '100%',
        height: Dimensions.get('window').width >= 750 ? 480 : 500,

      }}
    >
      <View >
        <Hoverable>
          {isHovered => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                flex: 1,
                position: "absolute",
                zIndex: 999999,
                left: 0,
                top: 0
              }}
              onPress={onClose}
            >
              <Icon
                color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                iconStyle={{
                  color: "#fff",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                reverse
                name="close"
                type="antdesign"
                size={16}
              />
            </TouchableOpacity>
          )}
        </Hoverable>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#000",
            alignItems: "center",
            height: 50,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            width: "100%"
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Image
              source={require("../assets/image/logolastOne.png")}
              style={{
                height: 35,
                width: 35,
                marginRight: 5,
                // borderRadius: 8
              }}
              resizeMode={"contain"}
            />
            {/* <Text
              style={[ButtonStyle.profileTitleStyle, {
                fontSize: 20,
                textAlign: "center",
                color: "white",
                fontWeight: 'bold'
              }]}
            >
              weclikd
            </Text> */}
          </View>
        </View>
      </View>

      <View
        style={{
          alignItems: "center",
          flex: 1,
          paddingVertical: 20,
          backgroundColor: "#fff",
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
          borderColor: "#c5c5c5",
          height: Platform.OS != 'web' ? 600 : 450,
          width: '100%'
        }}
      >

        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            marginTop: 10
          }}
        >

        </View>
        <View
          style={{ width: '90%' }}
        >
          <View
            style={{
              ...Platform.select({
                web: {
                  marginTop: 15
                }
              })
            }}
          >

            <Button
              buttonStyle={[
                styles.GbuttonStyle,
                { borderColor: "#c5c5c5", marginTop: 0 }
              ]}
              title="Login with Google"
              titleStyle={styles.Gbuttontextstyle}
              onPress={() => {
                if (Platform.OS == "web") {
                  loginWithGoogle()
                } else {
                  promptAsync()
                }
              }
              }
              icon={
                <Image
                  source={require("../assets/image/gLogin.png")}
                  style={styles.Gicon}
                />
              }
            />

            <View
              style={{
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                height: 50,
                marginVertical: 10
              }}
            >
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#e1e1e1",
                  width: "43%",
                  justifyContent: "flex-start",
                  height: 1,
                  borderRadius: 6,
                  backgroundColor: "#e1e1e1"
                }}
              ></View>
              <Text
                style={{
                  fontSize: 16,
                  color: "#49525D",
                  textAlign: "center",
                  marginHorizontal: "4%",
                  marginVertical: "10%",
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                or
              </Text>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#e1e1e1",
                  width: "43%",
                  justifyContent: "flex-end",
                  height: 1,
                  borderRadius: 6,
                  backgroundColor: "#e1e1e1"
                }}
              ></View>
            </View>

            <TextInput
              value={UserEmail}
              placeholder="Email"
              placeholderTextColor="#A9A9A9"
              onChangeText={UserEmail => {
                setUserEmail(UserEmail)
                setErrorMsg('')
                // setState({ UserEmail, errorMsg: "" })
              }
              }
              underlineColorAndroid="transparent"
              style={[
                styles.TextInputStyleClass, ButtonStyle.textAreaShadowStyle,
                { borderColor: "#d7d7d7" }
              ]}

              testID="userEmail"
            />
            <TextInput
              value={UserPassword}
              placeholder="Password"
              placeholderTextColor="#A9A9A9"
              onChangeText={UserPassword => {
                setUserPassword(UserPassword)
                setErrorMsg('')
                // setState({ UserPassword, errorMsg: "" })
              }
              }
              onKeyPress={enterPressed}
              underlineColorAndroid="transparent"
              style={[
                styles.TextInputStyleClass, ButtonStyle.textAreaShadowStyle,
                { borderColor: "#d7d7d7" }
              ]}
              secureTextEntry={true}
              testID="UserPassword"
            />
            <View
              style={{
                flexDirection: Dimensions.get('window').width > 1200 ? "row" : "column",
                justifyContent: "space-between"
              }}
            >
              <TouchableOpacity onPress={userResetModal}>
                <Text style={[styles.forgotpasswordStyle, { textAlign: Dimensions.get('window').width > 1200 ? 'right' : 'left', paddingHorizontal: 2 }]}>
                  Forgot Password?
                </Text>
              </TouchableOpacity>

              <Text
                style={{
                  fontSize: 12,
                  color: "#49525D",
                  textAlign: "left",
                  fontFamily: ConstantFontFamily.defaultFont,
                  marginTop: 10,
                  paddingHorizontal: 2
                }}
              >
                Don't have an account? &nbsp;
                <Text
                  style={{
                    textDecorationLine: "underline",
                    color: "#49525D",
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                  onPress={handleSignButton}
                >
                  Sign Up
                </Text>
              </Text>
            </View>
            {''.loading == true && <LoaderComponent />}

            <Text
              style={{
                color: "#E26A64",
                textAlign: "left",
                fontSize: 13,
                textAlign: "center",
                marginTop: 10,
                height: 10,
                fontFamily: ConstantFontFamily.defaultFont
              }}
            >
              {errorMsg}
            </Text>
            <View
              style={{
                marginVertical: 10,
                alignContent: 'center'
              }}
            >
              {props.getGoogleLogin == true ? (
                <Button
                  title={props.getLoginButtonText}
                  testID="UserLogin1"
                  titleStyle={[
                    ButtonStyle.allButtonTextStyle,
                    // {
                    //   color:
                    //     props.getLoginButtonText == "Logged In!" ||
                    //       props.getLoginButtonText == "Logging in..."
                    //       ? "#fff"
                    //       : "#000"
                    // }
                  ]}
                  buttonStyle={[
                    ButtonStyle.allButtonbackgroundStyle,
                    // {
                    //   backgroundColor:
                    //     props.getLoginButtonText == "Logged In!" ||
                    //       props.getLoginButtonText == "Logging in..."
                    //       ? "#009B1A"
                    //       : "#fff"
                    // }
                    {
                      backgroundColor: loginBtnColor, borderColor: props.getLoginButtonText == "Logged In!" ||
                        props.getLoginButtonText == "Logging in..." ? Colors.blueColor : '#d3d3d3'
                    }
                  ]}
                  containerStyle={ButtonStyle.containerStyle}
                  onPress={userLogin}
                  id="btn1"

                />
              ) : (
                <Button
                  title={props.getLoginButtonText}
                  testID="UserLogin"
                  titleStyle={
                    // props.getLoginButtonText == "Logged In!" ||
                    //   props.getLoginButtonText == "Logging in..."
                    //   ? ButtonStyle.allButtonTextStyle: null
                    [ButtonStyle.allButtonTextStyle, {
                      color: UserEmail == "" ||
                        UserPassword == "" ? '#d3d3d3' : Colors.blueColor
                    }]
                  }
                  buttonStyle={[
                    // props.getLoginButtonText == "Logged In!" ||
                    //   props.getLoginButtonText == "Logging in..."
                    //   ? ButtonStyle.gbackgroundStyle
                    ButtonStyle.allButtonbackgroundStyle
                    , {
                      backgroundColor: loginBtnColor, borderColor: UserEmail == "" ||
                        UserPassword == "" ? '#d3d3d3' : Colors.blueColor
                    }]}
                  onMouseEnter={() => setloginBtnColor(Colors.mouseEnterColor)}
                  onMouseLeave={() => setloginBtnColor(Colors.buttonbackground)}
                  containerStyle={ButtonStyle.containerStyle}
                  disabled={
                    UserEmail == "" ||
                      UserPassword == ""
                      ? true
                      : false
                  }
                  onPress={userLogin}
                  id="btn2"
                />
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

// class LoginModal extends Component {
//   constructor(props) {
//     super(props);
//     state = {
//       UserEmail: "",
//       UserPassword: "",
//       loading: false,
//       rememberMe: false,
//       rememberMeData: null,
//       access_key: "",
//       buttonName: "Login",
//       errorMsg: "",
//       pageInfo: null,
//       page: 1

//     };
//     _isMounted = false;
//   }

//   async componentDidMount() {
//     _isMounted = true;
//     let __self = this;
//     let res = JSON.parse(await AsyncStorage.getItem("rememberMe"));
//     if (res) {
//       setState({
//         rememberMe: true,
//         UserEmail: res.UserEmail,
//         UserPassword: res.UserPassword,
//         buttonName: "Login"
//       });
//     } else {
//       setState({
//         rememberMe: false,
//         buttonName: "Login"
//       });
//     }
//   }

//   // componentDidUpdate(){
//   //   setState({
//   //     // rememberMe: false,
//   //     buttonName: "Login"
//   //   });
//   // }
//   componentWillUnmount() {
//     _isMounted = false;
//   }

//   render() {
//     return (
//       <View
//         style={{
//           backgroundColor: "#f4f4f4",
//           borderColor: "#c5c5c5",
//           borderRadius: 6,
//           width: Dimensions.get('window').width >= 750 ? 450 : '100%',
//           height: Dimensions.get('window').width >= 750 ? 480 : 500,

//         }}
//       >
//         <View >
//           <Hoverable>
//             {isHovered => (
//               <TouchableOpacity
//                 style={{
//                   flexDirection: "row",
//                   justifyContent: "flex-start",
//                   flex: 1,
//                   position: "absolute",
//                   zIndex: 999999,
//                   left: 0,
//                   top: 0
//                 }}
//                 onPress={onClose}
//               >
//                 <Icon
//                   color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
//                   iconStyle={{
//                     color: "#fff",
//                     justifyContent: "center",
//                     alignItems: "center"
//                   }}
//                   reverse
//                   name="close"
//                   type="antdesign"
//                   size={16}
//                 />
//               </TouchableOpacity>
//             )}
//           </Hoverable>

//           <View
//             style={{
//               flexDirection: "row",
//               justifyContent: "center",
//               backgroundColor: "#000",
//               alignItems: "center",
//               height: 50,
//               borderTopLeftRadius: 6,
//               borderTopRightRadius: 6,
//               width: "100%"
//             }}
//           >
//             <View
//               style={{
//                 alignItems: "center",
//                 justifyContent: "center",
//                 flexDirection: "row",
//               }}
//             >
//               <Image
//                 source={require("../assets/image/logolastOne.png")}
//                 style={{
//                   height: 35,
//                   width: 35,
//                   marginRight: 5,
//                   borderRadius: 8
//                 }}
//                 resizeMode={"contain"}
//               />
//               <Text
//                 style={[ButtonStyle.profileTitleStyle, {
//                   fontSize: 20,
//                   textAlign: "center",
//                   color: "white",
//                   fontWeight: 'bold'
//                 }]}
//               >
//                 weclikd
//               </Text>
//             </View>
//           </View>
//         </View>

//         <View
//           style={{
//             alignItems: "center",
//             flex: 1,
//             paddingVertical: 20,
//             backgroundColor: "#fff",
//             borderBottomLeftRadius: 6,
//             borderBottomRightRadius: 6,
//             borderColor: "#c5c5c5",
//             height: Platform.OS != 'web' ? 600 : 450,
//             width: '100%'
//           }}
//         >

//           <View
//             style={{
//               alignItems: "center",
//               justifyContent: "center",
//               flexDirection: "row",
//               marginTop: 10
//             }}
//           >

//           </View>
//           <View
//             style={{ width: '90%' }}
//           >
//             <View
//               style={{
//                 ...Platform.select({
//                   web: {
//                     marginTop: 15
//                   }
//                 })
//               }}
//             >

//               <Button
//                 buttonStyle={[
//                   styles.GbuttonStyle,
//                   { borderColor: "#c5c5c5", marginTop: 0 }
//                 ]}
//                 title="Login with Google"
//                 titleStyle={styles.Gbuttontextstyle}
//                 onPress={loginWithGoogle}
//                 icon={
//                   <Image
//                     source={require("../assets/image/gLogin.png")}
//                     style={styles.Gicon}
//                   />
//                 }
//               />

//               <View
//                 style={{
//                   flexDirection: "row",
//                   width: "100%",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   height: 50,
//                   marginVertical: 10
//                 }}
//               >
//                 <View
//                   style={{
//                     borderWidth: 1,
//                     borderColor: "#e1e1e1",
//                     width: "43%",
//                     justifyContent: "flex-start",
//                     height: 1,
//                     borderRadius: 6,
//                     backgroundColor: "#e1e1e1"
//                   }}
//                 ></View>
//                 <Text
//                   style={{
//                     fontSize: 16,
//                     color: "#49525D",
//                     textAlign: "center",
//                     marginHorizontal: "4%",
//                     marginVertical: "10%",
//                     fontFamily: ConstantFontFamily.defaultFont
//                   }}
//                 >
//                   or
//                 </Text>
//                 <View
//                   style={{
//                     borderWidth: 1,
//                     borderColor: "#e1e1e1",
//                     width: "43%",
//                     justifyContent: "flex-end",
//                     height: 1,
//                     borderRadius: 6,
//                     backgroundColor: "#e1e1e1"
//                   }}
//                 ></View>
//               </View>

//               <TextInput
//                 value={UserEmail}
//                 placeholder="Email"
//                 placeholderTextColor="#A9A9A9"
//                 onChangeText={UserEmail =>
//                   setState({ UserEmail, errorMsg: "" })
//                 }
//                 underlineColorAndroid="transparent"
//                 style={[
//                   styles.TextInputStyleClass, ButtonStyle.textAreaShadowStyle,
//                   { borderColor: "#d7d7d7" }
//                 ]}

//                 testID="userEmail"
//               />
//               <TextInput
//                 value={UserPassword}
//                 placeholder="Password"
//                 placeholderTextColor="#A9A9A9"
//                 onChangeText={UserPassword =>
//                   setState({ UserPassword, errorMsg: "" })
//                 }
//                 onKeyPress={enterPressed}
//                 underlineColorAndroid="transparent"
//                 style={[
//                   styles.TextInputStyleClass, ButtonStyle.textAreaShadowStyle,
//                   { borderColor: "#d7d7d7" }
//                 ]}
//                 secureTextEntry={true}
//                 testID="UserPassword"
//               />
//               <View
//                 style={{
//                   flexDirection: Dimensions.get('window').width > 1200 ? "row" : "column",
//                   justifyContent: "space-between"
//                 }}
//               >
//                 <TouchableOpacity onPress={userResetModal}>
//                   <Text style={[styles.forgotpasswordStyle, { textAlign: Dimensions.get('window').width > 1200 ? 'right' : 'left', paddingHorizontal: 2 }]}>
//                     Forgot Password?
//                   </Text>
//                 </TouchableOpacity>

//                 <Text
//                   style={{
//                     fontSize: 12,
//                     color: "#49525D",
//                     textAlign: "left",
//                     fontFamily: ConstantFontFamily.defaultFont,
//                     marginTop: 10,
//                     paddingHorizontal: 2
//                   }}
//                 >
//                   Don't have an account? &nbsp;
//                   <Text
//                     style={{
//                       textDecorationLine: "underline",
//                       color: "#49525D",
//                       fontFamily: ConstantFontFamily.defaultFont,
//                     }}
//                     onPress={handleSignButton}
//                   >
//                     Sign Up
//                   </Text>
//                 </Text>
//               </View>
//               {loading == true && <LoaderComponent />}

//               <Text
//                 style={{
//                   color: "#E26A64",
//                   textAlign: "left",
//                   fontSize: 13,
//                   textAlign: "center",
//                   marginTop: 10,
//                   height: 10,
//                   fontFamily: ConstantFontFamily.defaultFont
//                 }}
//               >
//                 {errorMsg}
//               </Text>
//               <View
//                 style={{
//                   marginVertical: 10,
//                   alignContent: 'center'
//                 }}
//               >
//                 {props.getGoogleLogin == true ? (
//                   <Button
//                     title={props.getLoginButtonText}
//                     testID="UserLogin1"
//                     titleStyle={[
//                       ButtonStyle.titleStyle,
//                       {
//                         color:
//                           props.getLoginButtonText == "Logged In!" ||
//                             props.getLoginButtonText == "Logging in..."
//                             ? "#fff"
//                             : "#000"
//                       }
//                     ]}
//                     buttonStyle={[
//                       ButtonStyle.backgroundStyle,
//                       {
//                         backgroundColor:
//                           props.getLoginButtonText == "Logged In!" ||
//                             props.getLoginButtonText == "Logging in..."
//                             ? "#009B1A"
//                             : "#fff"
//                       }
//                     ]}
//                     containerStyle={ButtonStyle.containerStyle}
//                     onPress={userLogin}
//                     id="btn1"

//                   />
//                 ) : (
//                   <Button
//                     title={props.getLoginButtonText}
//                     testID="UserLogin"
//                     titleStyle={
//                       props.getLoginButtonText == "Logged In!" ||
//                         props.getLoginButtonText == "Logging in..."
//                         ? ButtonStyle.wtitleStyle
//                         : ButtonStyle.titleStyle
//                     }
//                     buttonStyle={
//                       props.getLoginButtonText == "Logged In!" ||
//                         props.getLoginButtonText == "Logging in..."
//                         ? ButtonStyle.gbackgroundStyle
//                         : ButtonStyle.backgroundStyle
//                     }
//                     containerStyle={ButtonStyle.containerStyle}
//                     disabled={
//                       UserEmail == "" ||
//                         UserPassword == ""
//                         ? true
//                         : false
//                     }
//                     onPress={userLogin}
//                     id="btn2"
//                   />
//                 )}
//               </View>
//             </View>
//           </View>
//         </View>
//       </View>
//     );
//   }
// }



const mapStateToProps = state => ({




  getLoginButtonText: state.AdminTrueFalseReducer.loginButtonText,
  getGoogleLogin: state.AdminTrueFalseReducer.googleLogin,

  getAnonymousToken: state.AdminTrueFalseReducer.anonymousToken,

});

const mapDispatchToProps = dispatch => ({
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload)),
  setResetPasswordModalStatus: payload =>
    dispatch(setRESETPASSWORDMODALACTION(payload)),
  setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  setUserApproachAction: payload => dispatch(setUserApproachAction(payload)),


  changeAdminStatus: payload => dispatch(setAdminStatus(payload)),

  getHomefeed: payload => dispatch(getHomefeedList(payload)),
  getTrendingUsers: payload => dispatch(getTrendingUsers(payload)),
  getTrendingTopics: payload => dispatch(getTrendingTopics(payload)),
  getTrendingClicks: payload => dispatch(getTrendingClicks(payload)),
  getTrendingExternalFeeds: payload =>
    dispatch(getTrendingExternalFeeds(payload)),
  setTrendingHomeFeed: payload =>
    dispatch({ type: "SET_TRENDING_HOME_FEED", payload }),
  setNewHomeFeed: payload => dispatch({ type: "SET_NEW_HOME_FEED", payload }),
  setDiscussionHomeFeed: payload =>
    dispatch({ type: "SET_DISCUSSION_HOME_FEED", payload }),
  setLoginButtonText: payload =>
    dispatch({ type: "SET_LOGIN_BUTTON_TEXT", payload }),
  setInviteUserDetail: payload =>
    dispatch({ type: "SET_INVITE_USER_DETAIL", payload }),
  setGoogleLogin: payload => dispatch(setGoogleLogin(payload)),
  // setAnonymousUser: payload =>
  //   dispatch({ type: "ANONYMOUS_USER", payload }),
  leftPanelModalFunc: payload => dispatch({ type: 'LEFT_PANEL_OPEN', payload }),
  setUpdatedPostId: payload => dispatch({ type: "SET_UPDATED_POSTID", payload }),
  setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),

  setPostCommentReset: payload =>
    dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),



});

const LoginModalContainerWrapper = graphql(UserLoginMutation, {
  name: "Login",
  options: {
    variables: {
      id: "Account:" + "CurrentUser"
    },
    fetchPolicy: "no-cache"
  }
})(LoginModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginModalContainerWrapper);

export const styles = {
  TextInputStyleClass: {
    marginBottom: 10,
    height: 45,
    paddingLeft: 10,
    borderWidth: 0,
    color: "#000",
    backgroundColor: "#fff",
    fontFamily: ConstantFontFamily.defaultFont,
    fontWeight: "bold"
  },

  forgotpasswordStyle: {
    fontSize: 12,
    color: "#49525D",
    textAlign: "right",
    fontFamily: ConstantFontFamily.defaultFont,
    marginTop: 10
  },


  buttonStyle: {
    marginVertical: 25,
    backgroundColor: "#000",
    borderRadius: 6,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    width: "20%",
    alignSelf: "center"
  },
  GbuttonStyle: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    height: 40,
    alignItems: "center",
    padding: 5,
    borderWidth: 2,
    borderColor: "#000",
    marginTop: 10
  },

  Gbuttontextstyle: {
    fontSize: 16,
    color: "#000",
    fontFamily: ConstantFontFamily.defaultFont,
    marginLeft: 5
  },
  Gicon: {
    height: 25,
    width: 25
  }
};